import { api } from "../api";

export const ENDPOINTS = {
  GET_MEMBER_PROFILE: "v1/user/team-member/userId/profile",
  LANG_UPDATE: "/v1/user/language"
};

async function getMemberProfile(id: string) {
  const url = ENDPOINTS.GET_MEMBER_PROFILE.replace("userId", id);
  return api.get(url).then((res) => res.data);
}

async function updateLang(lang: string) {
  return api.post(ENDPOINTS.LANG_UPDATE, {
    "language": lang
  }).then((res) => res.data);
}

export { getMemberProfile, updateLang };
