import { useState } from "react";
import iconSearch from "../../assets/images/iconSearch.svg";

export default function SearchBar({
  doSearch,
  placeHolder
}: {
  doSearch: (query: string) => void;
  placeHolder: string
}) {
  const [query, setQuery] = useState("");

  const searchData = (e: any) => {
    e.preventDefault();
    doSearch(query);
  };
  const setSearchData = (value: string) => {
    if (!value) {
      doSearch(value);
    }
    setQuery(value);
  };
  return (
    <div className="search-bar">
      <form className="">
        <input
          className="form-control"
          type="text"
          placeholder={placeHolder}
          aria-label="Search"
          onChange={(e) => setSearchData(e.target.value)}
        />
        <button className="_search" onClick={searchData}>
          <img src={iconSearch} alt="searchIcon" />
        </button>
      </form>
    </div>
  );
}
