import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form } from "react-final-form";
import Button from "react-bootstrap/Button";
import back from "../../assets/images/iconBack.svg";
import contactImg1 from "../../assets/images/contact-left-1.png";
import contactImg2 from "../../assets/images/contact-left-2.png";
import contactImgRight from "../../assets/images/contact-right-img.png";
import IconEmailBlue from "../../assets/images/iconEmailBlue.svg";
import IconUserBlue from "../../assets/images/iconUserBlue.svg";
import {
  composeValidators,
  maxlength,
  required,
  validEmail,
  requiredWithMessage
} from "../../validations";
import { FormControl } from "../../components";
import { VALIDATIONS } from "../../constants";
import { support } from "../../api";
import { useMessageModal } from "../../hooks/useMessage";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { MobilePhone } from "../../components/FormElements/MobilePhone";
import { useTranslation } from "react-i18next";

function ContactUsForm() {
  const { showMessage } = useMessageModal();
  const navigate = useNavigate();
  const { t } = useTranslation();
  

  const [initData, setInitData] = useState({
    name: "",
    email: "",
    countryCode: "",
    mobileNumber: "",
    message: "",
    
  });


  const onSubmit = (values: any) => {
    const input = {
      countryCode: "+" +values.mobileNumber.countryCode,
      mobileNo: values.mobileNumber.mobileNo,
    };
  
    const data = {
      ...values,
      ...input
    }

    delete data.mobileNumber;
    support
      .contactUs(data)
      .then((res) => {
        showMessage({
          heading: t('Thanksforyourthoughts!'),
          body: <p>{t('contactUsMsg')}</p>,
          type: "success",
          callback: () => {
            navigate(ROUTE_NAVIGATION_PATH.FAQ, { replace: true });
          },
        });
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{t(error.response?.data?.errorMessage as string)}</p>,
          type: "error",
        });
      });
  };

  return (
    <>
      <Form
        initialValues={initData}
        onSubmit={onSubmit}
        
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <FormControl
              label={t("name") as string}
              name="name"
              type="Name"
              placeholder={t("name")}
              icon={IconUserBlue}
              validate={composeValidators(
                required,
                maxlength(VALIDATIONS.MAX_NAME)
              )}
            />
            <FormControl
              label={t("email") as string}
              name="email"
              type="email"
              placeholder={t("email")}
              validate={composeValidators(required, validEmail)}
              icon={IconEmailBlue}
            />

            {/* <label className="form-label">Mobile Number</label> */}
            <MobilePhone
              label={t("mobileNumber") as string}
              name="mobileNumber"
              country={"us"}
              validate={composeValidators(requiredWithMessage(t("mobileNumberRequired")))}
              // onChange={handleOnChange}
            />
           
            <FormControl
              label={t("shareYourThoughts") as string}
              name="message"
              type="textarea"
              validate={composeValidators(
                required,
                maxlength(VALIDATIONS.MAX_DESCRIPTION)
              )}
              placeholder={t("placeForYourThoughts")}
            />
            <Button variant="primary" type="submit">{t('send')}</Button>
          </form>
        )}
      />
    </>
  );
}
export default function ContactUs() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleClick() {
    navigate(ROUTE_NAVIGATION_PATH.FAQ);
  }

  return (
    <>
      <Row>
        <section className="main-container shrink-width">
          <div className="heading-common">
            <div className="back-icon" onClick={handleClick}>
              <img src={back} alt="back" />
            </div>
            <h1>
              {t('Contactus')}
            </h1>
            <p>{t('contactusSubTitle')}</p>
          </div>
          <div className="contactus-wrapper">
            <Col md={12} lg={4} className="left-image">
              <img src={contactImg2} alt="contact" />
              <img src={contactImg1} alt="contact" />
            </Col>
            <Col md={10} lg={8} xl={5} className="contactus-form">
              <ContactUsForm />
            </Col>

            <Col md={12} lg={3} xl={3} className="right-image">
              <img src={contactImgRight} alt="contact" />
            </Col>
          </div>
        </section>
      </Row>
    </>
  );
}
