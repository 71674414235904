import { useNavigate } from "react-router-dom";
import { ENDPOINTS } from "../../api/team";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import UploadFile from "../FormElements/UploadFile";
import { useAuth } from "../../hooks/useAuth";

export default function UploadTeamMembers() {
  const navigate = useNavigate();
  const { user, updateUserData } = useAuth();
  const onComplete = () => {
    updateUserData({ taskCount: user.teamLeaderCount + 1 });
    navigate(ROUTE_NAVIGATION_PATH.MEMBER_LIST);
  };
  return (
    <UploadFile
      onComplete={onComplete}
      sampleFileUrl={ENDPOINTS.GET_SAMPLE_DATA}
      uploadFileApiUrl={ENDPOINTS.UPLOAD_BULK_DATA}
    />
  );
}
