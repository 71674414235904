import { createBrowserRouter, Outlet } from "react-router-dom";
import { RequireAuth } from "../components";
import {
  SignIn,
  SignUp,
  CreateTeam,
  Payment,
  ChoosePlan,
  Main,
  MemberProfile,
  ChooseSkills,
  MembersList,
  SkillInfo,
  Stepper,
  PreCreationScreen,
  Questionnaire,
  TasksList,
  RequirementForTaskInfo,
  TeamCreationInfo,
  TeamTask,
  ContactPage,
  ContactPageEdit,
  LeaderProfile,
  LeaderDashBoard,
  TaskCreationInfo,
  ForgetPassword,
  ResetPassword,
  CreateTask,
  VerifyEmail,
  FutureSkillsInfo,
  Faq,
  ContactUs,
  ManageTeam,
  PaymentHistory,
  MemberDashboard,
  AllTaskList,
  NotStartedTaskList,
  LeaderSetting,
  MyTaskList,
  ManageLeader,
  ManageInvoice
} from "../pages";
import MemberSetting from "../pages/Member/Settings";
import UserSubscription from "../pages/SignIn/Subscription";
import { ROUTE_PATH } from "./routes";

export const router = createBrowserRouter([
  {
    path: ROUTE_PATH.ROOT,
    element: <Main />,
    children: [
      {
        path: ROUTE_PATH.QUESTIONNAIRE,
        element: <Questionnaire />,
      },
    ]
  },
  {
    path: ROUTE_PATH.LEADER,
    element: <RequireAuth />,
    children: [
      {
        path: ROUTE_PATH.TASK_CREATION_INFO,
        element: <TaskCreationInfo />,
      },
      {
        path: ROUTE_PATH.SUBSCRIPTION,
        element: <UserSubscription />
      },
      {
        path: ROUTE_PATH.TEAM_CREATION_INFO,
        element: <TeamCreationInfo />,
      },
      { path: ROUTE_PATH.CHOOSE_PLAN, element: <ChoosePlan /> },
      {
        path: ROUTE_PATH.PAYMENT_PLAN,
        element: <Payment />,
      },
      {
        path: ROUTE_PATH.CREATE_TEAM,
        element: <CreateTeam />,
      },
      {
        path: ROUTE_PATH.MEMBER_LIST,
        element: <MembersList />,
      },
      {
        path: ROUTE_PATH.CHOOSE_PLAN,
        element: <ChoosePlan />,
      },
      {
        path: ROUTE_PATH.PAYMENT_PLAN,
        element: <Payment />,
      },
      {
        path: ROUTE_PATH.MY_PROFILE,
        element: <LeaderProfile />,
      },
      {
        path: ROUTE_PATH.STEPPER,
        element: <Stepper />,
      },
      {
        path: ROUTE_PATH.PRE_CREATION,
        element: <PreCreationScreen />,
      },
      {
        path: ROUTE_PATH.TEAM_PRE_CREATION,
        element: <PreCreationScreen />,
      },
      {
        path: ROUTE_PATH.TASK_LIST,
        element: <TasksList />,
      },
      {
        path: ROUTE_PATH.CREATE_TASK,
        element: <CreateTask />,
      },
      {
        path: ROUTE_PATH.REQUIREMENT_FOR_TASK_INFO + "/:taskId/:taskName",
        element: <RequirementForTaskInfo />,
      },
      {
        path: ROUTE_PATH.DASHBOARD,
        element: <LeaderDashBoard />,
      },
      {
        path: ROUTE_PATH.ALL_TASK,
        element: <AllTaskList />,

      },
      {
        path: ROUTE_PATH.NOT_STARTED_TASK,
        element: <NotStartedTaskList />
      },
      {
        path: ROUTE_PATH.TEAM_TASK,
        element: <TeamTask />,
      },
      {
        path: ROUTE_PATH.UPDATE_CONTACT_PAGE_SUGGESTION + "/:taskId",
        element: <ContactPage />,
      },
      {
        path: ROUTE_PATH.TEAM_TASK,
        element: <TeamTask />,
        
      },
      {
        path: ROUTE_PATH.UPDATE_CONTACT_PAGE + "/:taskId/:status",
        element: <ContactPageEdit />,

      },
      {
        path: ROUTE_PATH.MY_PROFILE,
        element: <LeaderProfile />,

      },
      {
        path: ROUTE_PATH.MANAGE_TEAM,
        element: <ManageTeam />,

      },
      {
        path: ROUTE_PATH.PAYMENT_HISTORY,
        element: <PaymentHistory />,
      },
      {

        path: ROUTE_PATH.QUESTIONNAIRE + "/:taskId/:taskName",
        element: <Questionnaire />,
      },
      {
        path: ROUTE_PATH.ACTIVE_MEMBER_LIST + '/:status',
        element: <MembersList />,
      },
      {
        path: ROUTE_PATH.SETTING,
        element: <LeaderSetting/>
      },
      {
        path: ROUTE_PATH.MEMBER_PROFILE + '/:userId',
        element: <MemberProfile />
      },
      {
        path: ROUTE_PATH.MEMBER_DASHBOARD + "/:userId",
        element: <MemberDashboard/>,
      },
      
    ],
  },
  {
    path: ROUTE_PATH.SUPPORT,
    element: <RequireAuth />,
    children: [
      {
        path: ROUTE_PATH.FAQ,
        element: <Faq />,
      },
      {
        path: ROUTE_PATH.CONTACT_US,
        element: <ContactUs />,
      },
      
    ]
  },
  {
    path: ROUTE_PATH.MEMBER,
    element: <RequireAuth />,
    children: [
      {
        path: ROUTE_PATH.MY_PROFILE,
        element: <MemberProfile />,
      },
      {
        path: ROUTE_PATH.CHOOSE_SKILLS,
        element: <ChooseSkills />,
      },
      {
        path: ROUTE_PATH.SKILL_INFO,
        element: <SkillInfo />,
      },
      {
        path: ROUTE_PATH.FUTURE_SKILL_INFO,
        element: <FutureSkillsInfo />,
      },
      {
        path: ROUTE_PATH.QUESTIONNAIRE,
        element: <Questionnaire />,
      },
      {
        path: ROUTE_PATH.TASK_LIST,
        element: <MyTaskList/>,
      },
      {
        path: ROUTE_PATH.MEMBER_DASHBOARD,
        element: <MemberDashboard/>,
      },
      {
        path: ROUTE_PATH.SETTING,
        element: <MemberSetting/>
      }
    ],
  },{
    path: ROUTE_PATH.ADMIN,
    element: <RequireAuth />,
    children: [
      {
        path: ROUTE_PATH.MANAGE_LEADER,
        element: <ManageLeader />,
      },
      {
        path: ROUTE_PATH.MANAGE_INVOICE + '/:invoiceId' + '/:teamLeaderId',
        element: <ManageInvoice />,
      }
    ]
  },

  {
    path: ROUTE_PATH.AUTH,
    element: <Outlet />,
    children: [
      {
        element: <SignIn />,
        index: true,
      },
      {
        path: ROUTE_PATH.SIGN_UP,
        element: <SignUp />,
      },
      {
        path: ROUTE_PATH.FORGOT_PASSWORD,
        element: <ForgetPassword />,
      },
      {
        path: ROUTE_PATH.RESET_PASSWORD + "/:token",
        element: <ResetPassword />,
      },
      {
        path: ROUTE_PATH.VERIFY_EMAIL + "/:token",
        element: <VerifyEmail />,
      },
      {
        path: ROUTE_PATH.INVITE + "/:token",
        element: <SignUp />,
      }
    ],
  },
]);
