import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { authetication } from "../../../api";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";


export default function UserSubscription() {
  const { signout } = useAuth();
  const navigate = useNavigate();


  function handleClick() {
      signout(() => {
        localStorage.clear();
        navigate(ROUTE_NAVIGATION_PATH.SIGN_IN);
      });
  }
  return (
        <Row>
          <Col className="d-flex justify-content-center align-items-center p-0 login-signup-page-card h-100vh">
              <div className="login-form verify">
                  <div className="w-100">
                      <h2>
                          Subscription
                      </h2>
                      <div className="email-verification-message">
                          Please connect with the Prop support for your subscription plan
                      </div>
                      <Button onClick={() => handleClick()} variant="link">
                        SignOut
                      </Button>
                  </div>
              </div>
          </Col>
        </Row>
  );
}
