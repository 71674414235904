import { TASK_TYPE_LIST, TASK_STATUS } from "../../../constants";
import { task } from "../../../api";
import { useMessageModal } from "../../../hooks/useMessage";
import { AxiosError } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import profileIcon from "../../../assets/images/iconprofile.png";
import acceptIcon from "../../../assets/images/accept-icon.svg";
import { IconDelete, IconEdit } from "../..";
import { useTranslation } from "react-i18next";

function StatusTag({
  customClass,
  title,
  assigneeName,
  assigneeImage,
  noImage
}: {
  customClass: string;
  title?: string | null;
  assigneeName?: string;
  assigneeImage?: string;
  noImage?: boolean;
}) {
  return (
    <div className={customClass} title={assigneeName}>
      {noImage ? null:<div className="_imgnew">
        <img
          src={assigneeImage ? assigneeImage : profileIcon}
          onError={(e: any) => {
            e.target.src = profileIcon;
          }}
          alt={assigneeName}
          title={assigneeName}
        />
      </div>}

      {title}
    </div>
  );
}
export function TeamTaskTile({
  taskDetails,
  editTask,
  deleteTask,
  type,
  taskStatus,
}: {
  taskDetails: any;
  editTask?: VoidFunction;
  deleteTask?: VoidFunction;
  type?: string;
  taskStatus?: string;
}) {
  const { showMessage } = useMessageModal();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const suggestionsDetails =
    taskDetails.suggestions && taskDetails.suggestions.length > 0
      ? taskDetails.suggestions[0]
      : [];
  const acceptSuggestion = (e: any) => {
    e.stopPropagation();
    task
      .acceptSuggestion(
        taskDetails._id,
        taskDetails.suggestions[0].teamMemberId._id
      )
      .then((res) => {
        showMessage({
          heading: t("Accepted!"),
          body: <p>{res.message}</p>,
          type: "success",
          callback: () => {
            navigate(0);
          },
        });
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("Error"),
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };

  const truncateText = (text: String) => {
    return text && text?.length > 40 ? text.slice(0, 40) + "..." : text;
  };

  const handleNoSuggestion = () => {
    showMessage({
      heading: t("noMatch"),
      body: <p>{t('noMatchDes')}</p>,
      type: "warning"
    });
  }

  return (
    <>
      <div className="member-profile">
        <div className="name">{truncateText(taskDetails.name)}</div>
        <div className="tag-info">{TASK_TYPE_LIST[taskDetails.scope - 1]}</div>
      </div>
      <div className="assign-status">
        {taskDetails.assignedTo.length > 0 ? (
          <>
            {taskDetails.assignedTo[0].taskStatus === TASK_STATUS.DONE ? (
              <Link
                className="status"
                to={`${ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE}/${taskDetails._id}/${taskStatus}`}
              >
                <StatusTag
                  customClass="done-tag"
                  title={t("Done")}
                  assigneeImage={taskDetails?.assignedTo[0]?.memberId?.image}
                  assigneeName={
                    taskDetails?.assignedTo[0]?.memberId.name +
                    taskDetails?.assignedTo[0]?.memberId.surname
                  }
                />
              </Link>
            ) : (
              <StatusTag
                customClass="assigned-tag"
                title={`${t("Assigned")} ${
                  !type || type === "member_type" ? "" : type
                }`}
                assigneeImage={taskDetails?.assignedTo[0]?.memberId?.image}
                assigneeName={
                  taskDetails?.assignedTo[0]?.memberId.name +
                  " " +
                  taskDetails?.assignedTo[0]?.memberId.surname
                }
              />
            )}
          </>
        ) : (
          <>
            {taskDetails.suggestions.length ? (
              <>
                <StatusTag
                  customClass="suggestion-tag"
                  title={`${t("suggestion")}
                ${
                  taskDetails.suggestions.length
                    ? suggestionsDetails?.suggestionPercent?.toFixed(1)
                    : 0
                }
                %`}
                  assigneeImage={
                    taskDetails?.suggestions[0]?.teamMemberId?.image
                  }
                  assigneeName={
                    taskDetails?.suggestions[0]?.teamMemberId.name +
                    taskDetails?.suggestions[0]?.teamMemberId.surname
                  }
                />

                <div className="accept-tag" onClick={acceptSuggestion}>
                  <span className="accept-icon" title={t("Accept") as string}>
                    <img src={acceptIcon} alt="Accept" />
                  </span>
                </div>
              </>
            ) : (
              <div onClick={handleNoSuggestion}>
                <StatusTag
                  customClass="assigned-tag pe-cursor"
                  title={t("noMatch")}
                  assigneeImage=""
                  assigneeName=""
                  noImage={true}
                />
              </div>
            )}
          </>
        )}
      </div>

      <div className="status-with-action">
        {taskDetails.assignedTo.length > 0 ? (
          taskDetails.assignedTo[0].taskStatus === TASK_STATUS.DONE ? (
            <div className="status"></div>
          ) : type !== "member_type" ? (
            <Link
              className="status"
              to={`${ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE}/${taskDetails._id}/${taskStatus}`}
            >
              <div>{t("Re-assign")}</div>
            </Link>
          ) : (
            <div className="status"></div>
          )
        ) : taskDetails.suggestions.length ? (
          <Link
            className="status"
            to={`${ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE_SUGGESTION}/${taskDetails?._id}`}
          >
            <div>{t("Suggestions")}</div>
          </Link>
        ): <div className="status"></div>}
        {editTask ? (
          <div className="action-btn">
            <div className="edit" onClick={editTask}>
              <IconEdit />
            </div>
            <div className="delete" onClick={deleteTask}>
              <IconDelete />
            </div>
          </div>
        ) : // <div className="action-btn">
        //   <Link
        //     to={`${ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE}/${taskDetails._id}/${taskStatus}`}
        //   >
        //     <div className="edit">
        //       <IconEdit />
        //     </div>
        //   </Link>
        //   <Link
        //     to={`${ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE}/${taskDetails._id}/${taskStatus}`}
        //   >
        //     <div className="delete">
        //       <IconDelete />
        //     </div>
        //   </Link>
        // </div>
        null}
      </div>
    </>
  );
}

export default function AllTeamTaskTile({
  taskDetails,
  editTask,
  deleteTask,
  type,
  className = "",
}: {
  taskDetails: any;
  editTask?: VoidFunction;
  deleteTask?: VoidFunction;
  type?: string;
  className?: string;
}) {
  // const navigate = useNavigate();
  const taskStatus =
    taskDetails.assignedTo.length > 0
      ? taskDetails.assignedTo[0].taskStatus
      : TASK_STATUS.SUGGESTION;

  // const onNavigate =(e:any) => {
  //   e.stopPropagation();
  //   taskStatus !== TASK_STATUS.SUGGESTION ? navigate(`${ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE}/${taskDetails._id}/${taskStatus}`)
  //     : navigate(`${ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE_SUGGESTION}/${taskDetails?._id}`)
  // }
  return (
    <>
      {type && type === "member_type" ? (
        <Link
          className={className}
          to={`${ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE}/${taskDetails._id}/${taskStatus}`}
        >
          <TeamTaskTile
            taskDetails={taskDetails}
            editTask={editTask}
            deleteTask={deleteTask}
            type={type}
            taskStatus={taskStatus}
          />
        </Link>
      ) : (
        <div
          className={`${className}`}
        >
          <TeamTaskTile
            taskDetails={taskDetails}
            editTask={editTask}
            deleteTask={deleteTask}
            type={type}
            taskStatus={taskStatus}
          />
        </div>
      )}
    </>
  );
}
