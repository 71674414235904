import axios, { AxiosRequestHeaders } from "axios";
import { BASE_URL } from "../constants";
import { useMessageModal } from "../hooks/useMessage";

const API_BAD_REQUEST = 401;
const SUBSCRIPTION_EXPIRED = 560;


export const api = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // const { showMessage } = useMessageModal();

    if (error.response.status === API_BAD_REQUEST && !window.location.pathname.includes('auth')) {
      // logout
      localStorage.clear();
      window.location.href = '/'
    }
    if(error.response.status === SUBSCRIPTION_EXPIRED && !window.location.pathname.includes('auth')) {
      //logout
      // localStorage.clear();
      // window.location.href = '/'
      // showMessage({
      //   heading: "Error",
      //   body: error.response?.errorMessage,
      //   type: "error",
      // });
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = config.headers
        ? config.headers
        : ({} as AxiosRequestHeaders);
      config.headers.Token = localStorage.getItem("token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
