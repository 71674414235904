import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-final-form";

import { task } from "../../api";
import { AxiosError } from "axios";
import { useMessageModal } from "../../hooks/useMessage";
import { AddEditTask } from "..";
import { Scope, Task } from "../../api/models/Task";
import { TASK_TYPE, TASK_TYPE_LIST } from "../../constants";
import { useTranslation } from "react-i18next";

export default function EditTask({
  detail,
  handleClose,
  updateListItem
}: {
  detail: Task;
  handleClose: VoidFunction;
  updateListItem: (member: Task) => void
}) {
  const { showMessage } = useMessageModal();
  const { t } = useTranslation();
  const onSubmit = (values: Task) => {
    const data = {
      name: values.name,
      description: values.description,
      scope: TASK_TYPE[values.scope as Scope],
      _id: undefined
    };
    task
      .editTask(data, detail?._id as string)
      .then((res: any) => {
        updateListItem(data);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("Error"),
          body: <p>{error.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };

  return (
    <Modal
      show={!!detail}
      onHide={handleClose}
      dialogClassName={"model-common"}
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{t('taskDetails')}</Modal.Title>
        <p>{t('taskeditInfo')}</p>
      </Modal.Header>
      <Form
        initialValues={{...detail, scope: TASK_TYPE_LIST[(detail?.scope as number) -1]}}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <AddEditTask />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary full" type="submit">
                {t('save')}
              </Button>
              <Button variant="cancel full" onClick={handleClose}>
                {t('cancel')}
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
}
