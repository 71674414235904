import { Info } from "../../../components";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import requirementForTask from "../../../assets/images/requirementForTask.svg";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RequirementForTaskInfo() {
  const { taskId } = useParams();
  const { taskName } = useParams()
  const { t } = useTranslation();
  return (
    <Info
      title={t("What")}
      spantitle="requirements"
      nextTitle="are needed for the task?"
      subTitle="Fill out the questionnaire to describe the task in more details"
      image={requirementForTask}
      description1="In the first phase, a list of tasks has already been entered into the system. Now the next step is to define the requirements for each individual task. This way we can make sure that the right employee is selected for each task."
      description2="Please note that the following statements refer to the task you have selected from your daily work routine. We ask you to describe the task as best you can using the statements in order to create a precise requirements profile for the task."
      buttonText="LetsGo"
      nextUrl={`${ROUTE_NAVIGATION_PATH.TASK_QUESTIONNAIRE}/${taskId}/${taskName}`}
    />
  );
}
