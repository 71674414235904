import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-final-form";

import { task } from "../../api";
import { AxiosError } from "axios";
import { useMessageModal } from "../../hooks/useMessage";
import { Slider } from 'rsuite';
import { FormControl } from "../FormElements/FormControl";
import { useState } from "react";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { ROLE } from "../../constants";
import { useTranslation } from "react-i18next";


export default function TaskFeedBack({
    heading,
    show,
    detail,
    handleClose,
}: {
    heading: any;
    show: boolean
    detail: any;
    handleClose: VoidFunction;
}) {
  const { showMessage } = useMessageModal();
  const [rating, setRatingData] = useState(0);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();

  const onSubmit = (values: any) => {
    const data = {
        rating: rating,
        description: values.description,
    };
    task
      .reviewTask(data, detail as string)
      .then((res: any) => {
          handleClose();
          showMessage({
              heading: t("feddbackHeading"),
              body: <p>{t("feedbackDes")}</p>,
              type: "success",
              callback: () => {
                user.role === ROLE.LEADER ? navigate(ROUTE_NAVIGATION_PATH.TEAM_TASK) : navigate(ROUTE_NAVIGATION_PATH.MEMBER_TASK_LIST);
              },
          });
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("Error"),
          body: <p>{error.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };

  function updateFields(value:number) {
    setRatingData(value);
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      // backdrop="static"
      dialogClassName={"model-common"}
      keyboard={false}
    >
      <Modal.Header>
        <div className="donetag-dialog">{t("Done")}</div>
        <Modal.Title className="mt-5">{heading}</Modal.Title>
        <p style={{'margin': '0'}}>{t("Please, estimate the task")}</p>
      </Modal.Header>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body className="ovr-visible">
                <Slider
                    defaultValue={0}
                    min={0}
                    step={1}
                    max={10}
                    graduated
                    progress
                    renderMark={mark => {
                        return mark;
                    }}
                    onChange={(value: number) => updateFields(value)}
                />
                <FormControl
                    label="Task Feedback"
                    name="description"
                    type="textarea"
                    placeholder={ t("placeHolderForFeedback")}
                    className="spacing-equal"
                />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                {t("ESTIMATE")}
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
}
