import { AxiosError } from "axios";
import { api } from "../api";

export const ENDPOINTS = {
    GET_LEADER_LIST: "v1/admin/users/list",
    INVOICES: 'v1/admin/invoices/'
};

async function leaderList(data: Record<string, any>) {
    if (data.sortBy) {
        data.sortBy = JSON.stringify(data.sortBy);
    }
    return api.get(ENDPOINTS.GET_LEADER_LIST, { params: data }).then((res) => {
        const rData = res.data;
        return {
          data: rData.data.users,
          hasNextPage: rData.data.totalUsersCount > data.page * data.count,
        };
    });
}

async function getLeaderInvoices(data: Record<string, any>) {
    if (data.sortBy) {
        data.sortBy = JSON.stringify(data.sortBy);
    }
    return api.get(ENDPOINTS.INVOICES + data.id + '/get', { params: data }).then((res) => {
        const rData = res.data;
        return {
          data: rData.data.invoices,
          hasNextPage: rData.data.count > data.page * data.count,
        };
    });
}

async function createInvoice(data: any) {
    return api.post(ENDPOINTS.INVOICES + 'add', data).then((res) => res.data);
}

async function updateInvoice(data: any, invoiceId:any) {
    return api.patch(ENDPOINTS.INVOICES + invoiceId + '/update', data).then((res) => res.data);
}

export { leaderList, getLeaderInvoices, createInvoice, updateInvoice };
