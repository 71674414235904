import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Form } from "react-final-form";
import { AxiosError } from "axios";
import {
  composeValidators,
  required,
  validPassword,
  confirmPasswordMatchValidation,
} from "../../validations";

import { FormControl, PasswordField } from "../../components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { useMessageModal } from "../../hooks/useMessage";
import { authetication } from "../../api";
import { useParams } from "react-router";
import { encryptPassword } from "../../utils";
import { useTranslation } from "react-i18next";

function ResetForm() {
  const { showMessage } = useMessageModal();
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();

  const onSubmit = (values: any) => {
    values.resetPasswordToken = params.token;

    values.password = encryptPassword(values.password);
    delete values.confirmPassword;
    authetication
      .doRestPassword(values)
      .then((res) => {
        navigate(ROUTE_NAVIGATION_PATH.SIGN_IN);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{error.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };
  return (
    <>
      <h2>
        {t('Reset')} <span>{t('Password')}</span>
      </h2>
      <Card.Text>
        {t('resetPasswordInfo')}
      </Card.Text>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            {/* <FormControl
              label="Password"
              name="password"
              type="password"
              placeholder="*******"
              validate={composeValidators(required, validPassword)}
            /> */}
            <PasswordField
              validations={composeValidators(required, validPassword)}
            />
            {/* <span className="info">
              Password should consist at least 6 symbols
            </span> */}
            <PasswordField
              validations={composeValidators(
                required,
                confirmPasswordMatchValidation(values.password)
              )}
              label="confirmPassword"
              name="confirmPassword"
            />

            <Button variant="primary" type="submit">
              {t('ResetPassword')}
            </Button>
            <Button href="/auth" variant="link">
              {t('backtoSignIn')}
            </Button>
          </form>
        )}
      />
    </>
  );
}
export default function ResetPassword() {
  return (
    <Row>
      <Col className="d-flex justify-content-center align-items-center p-0 login-signup-page-card  h-100vh">
        <div className="login-form">
          <div className="w-100">
            <ResetForm />
          </div>
        </div>
      </Col>
    </Row>
  );
}
