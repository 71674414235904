import { AxiosError } from "axios";
import * as React from "react";
import { USER_STATUS } from "../constants";

const DEFAULT_OFFSET = 12;

interface Response {
  data: any[];
  hasNextPage: boolean;
  error?: AxiosError;
}

interface LoadItems {
  page: number;
  count: number;
  status: number;
  search?: string;
  searchByMember?: string;
  taskFilter?: string;
  sortBy?: Record<string, 1 | -1>;
  id?: string;
}

export function useLoadItems({
  loadItems,
  page,
  search = "",
  searchByMember = "",
  status = USER_STATUS.ALL,
  taskFilter = "",
  sortBy,
  id =""
}: {
  loadItems: (data: LoadItems) => Promise<Response>;
  page: number;
  status?: number;
  search?: string;
  searchByMember?: string;
  taskFilter?: string;
  sortBy?: Record<string, 1 | -1>;
  id?: string,
}) {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<any[]>([]);
  const [hasNextPage, setHasNextPage] = React.useState<boolean>(true);
  const [error, setError] = React.useState<Error>();

  async function loadMore() {
    setLoading(true);
    try {
      const { data, hasNextPage } = taskFilter
        ? await loadItems({
            page,
            count: DEFAULT_OFFSET,
            status,
            search,
            taskFilter,
            searchByMember,
            sortBy,
          })
        : id ? await loadItems({
              page,
              count: DEFAULT_OFFSET,
              status,   
              sortBy,
              id
            }) 
          : await loadItems({
            page,
            count: DEFAULT_OFFSET,
            status,
            search,
            searchByMember,
            sortBy,
          });
      setItems((current) => (page === 1 ? data : [...current, ...data]));
      console.log(items);
      setHasNextPage(hasNextPage);
    } catch (err) {
      setError(err as Error);
      setItems((current) => current);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  function updateItem(itemToUpdate: any, uniqueIdName = "_id") {
    setItems((prev) => {
      const index = prev.findIndex(
        (item) => item[uniqueIdName] === itemToUpdate[uniqueIdName]
      );
      prev[index] = { ...prev[index], ...itemToUpdate };
      return [...prev];
    });
  }

  function deleteItem(deleteId: any, uniqueIdName = "_id") {
    setItems((prev) => {
      const index = prev.findIndex((item) => item[uniqueIdName] === deleteId);
      prev.splice(index, 1);
      return prev;
    });
  }

  React.useEffect(() => {
    page > 0 && loadMore();
  }, [page, search, searchByMember, taskFilter, sortBy, id]);

  return {
    loading,
    items,
    hasNextPage,
    error,
    loadMore,
    updateItem,
    deleteItem,
  };
}
