import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { AddTeamMember, UploadTeamMembers } from "../../../components";

export default function CreateTeam() {
  const { t } = useTranslation()
  return (
    <>
      <Row>
        <section className="main-container transparent-bg shrink-width">
          <div className="heading-common">
            <h1>
              {t('lets')}<span> {t('create')}</span>!
            </h1>
            <p>{t('teamCreationSubTitile')}</p>
          </div>
          <div className="create-team-wrapper createteampage">
            <Col md={11} lg={5} className="create-team-grid">
              <h2>{t('createAMember')}</h2>
              <AddTeamMember />
            </Col>
            <Col md={11} lg={5} className="create-team-grid">
              <h2>{t('uploadAfile')}</h2>
              <UploadTeamMembers />
            </Col>
          </div>
        </section>
      </Row>
    </>
  );
}
