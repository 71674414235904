export const ROUTE_PATH = {
  ROOT: "/",
  LEADER: "/leader",
  MEMBER: "/member",
  SUPPORT: "/support",
  ADMIN: "/admin",

  AUTH: "/auth",
  SIGN_IN: "sign-in",
  SIGN_UP: "sign-up",
  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "reset-password",
  VERIFY_EMAIL: "verify-email",
  MY_PROFILE: "my-profile",
  DASHBOARD: "home",
  QUESTIONNAIRE: "questionnaire",
  //Leader
  CREATE_TEAM: "create-team",
  CHOOSE_PLAN: "choose-plan",
  PAYMENT_PLAN: "payment",
  MEMBER_LIST: "member-list",
  ACTIVE_MEMBER_LIST: "active-member-list",
  STEPPER: "stepper",
  PRE_CREATION: "pre-creation",
  TEAM_PRE_CREATION: "team-pre-creation",
  CREATE_TASK: "create-task",
  TASK_LIST: "tasks-list",
  REQUIREMENT_FOR_TASK_INFO: "requirement-for-task-info",
  TEAM_CREATION_INFO: "team-creation-info",
  TASK_CREATION_INFO: "task-creation-info",
  TEAM_TASK: "team-tasks",
  UPDATE_CONTACT_PAGE_SUGGESTION: "team-contact",
  UPDATE_CONTACT_PAGE: "team-contact-edit",
  MEMBER_DASHBOARD: "member-dashboard",
  SUBSCRIPTION: "subscription",
  MEMBER_PROFILE: "member-profile",

  TEAM_DASHBOARD: "team-dashboard",
  TASK_DETAILS: "task-details",
  MANAGE_TEAM: "manage-team",
  ALL_TASK: "all-task",
  NOT_STARTED_TASK: "not-started_tasks",
  TASK_DASHBOARD: "task-dashboard",
  TASK_INFORMATION: "task-information",
  SETTING: "setting",
  //Member
  INVITE: "invite",
  SKILL_INFO: "skills-info",
  FUTURE_SKILL_INFO: "future-skills-info",
  CHOOSE_SKILLS: "choose-skills",
  FAQ: "faq",
  CONTACT_US: "contact-us",
  TASK_MY_PROFILE: "task-my-profile",
  TASK_MANAGE_TEAM: "task-manage-team",
  PAYMENT_HISTORY: "payment-history",
  TEAM_TASK_INFORMATION: "team-task-information",

  MANAGE_LEADER: "manage-team-leaders",
  MANAGE_INVOICE: "manage-invoices",
};

export const ROUTE_NAVIGATION_PATH = {
  // Auth
  SIGN_IN: ROUTE_PATH.AUTH,
  SIGN_UP: ROUTE_PATH.AUTH + ROUTE_PATH.ROOT + ROUTE_PATH.SIGN_UP,
  FORGOT_PASSWORD: ROUTE_PATH.AUTH + ROUTE_PATH.ROOT + ROUTE_PATH.FORGOT_PASSWORD,
  RESET_PASSWORD: ROUTE_PATH.AUTH + ROUTE_PATH.ROOT + ROUTE_PATH.RESET_PASSWORD,
  VERIFY_EMAIL: ROUTE_PATH.AUTH + ROUTE_PATH.ROOT + ROUTE_PATH.VERIFY_EMAIL,

  // Leader
  CREATE_TEAM: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.CREATE_TEAM,
  MEMBER_LIST: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.MEMBER_LIST,
  ACTIVE_MEMBER_LIST: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.ACTIVE_MEMBER_LIST,

  CHOOSE_PLAN: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.CHOOSE_PLAN,
  PAYMENT_PLAN: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.PAYMENT_PLAN,
  PAYMENT_HISTORY: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.PAYMENT_HISTORY,


  STEPPER: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.STEPPER,
  PRE_CREATION: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.PRE_CREATION,
  TEAM_PRE_CREATION: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.TEAM_PRE_CREATION,

  LEADER_DASHBOARD: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.DASHBOARD,

  TASK_LIST: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.TASK_LIST,
  TASK_QUESTIONNAIRE:
    ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.QUESTIONNAIRE,

  CREATE_TASK: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.CREATE_TASK,
  REQUIREMENT_FOR_TASK_INFO:
    ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.REQUIREMENT_FOR_TASK_INFO,
  TEAM_CREATION_INFO:
    ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.TEAM_CREATION_INFO,
  TASK_CREATION_INFO:
    ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.TASK_CREATION_INFO,
  LEADER_MY_PROFILE:
    ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.MY_PROFILE,

  TASK_MANAGE_TEAM:
    ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.TASK_MANAGE_TEAM,
  MANAGE_TEAM_MEMBERS: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.MANAGE_TEAM,
  TEAM_TASK: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.TEAM_TASK,
  UPDATE_CONTACT_PAGE: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.UPDATE_CONTACT_PAGE,
  UPDATE_CONTACT_PAGE_SUGGESTION: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.UPDATE_CONTACT_PAGE_SUGGESTION,
  ALL_TASK: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.ALL_TASK,
  NOT_STARTED_TASK: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.NOT_STARTED_TASK,
  LEADER_SETTING: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.SETTING,
  SUBSCRIPTION: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.SUBSCRIPTION,
  MEMBER_PROFILE: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.MEMBER_PROFILE,
  DASHBOARD: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.MEMBER_DASHBOARD,

  //member
  MEMBER_QUESTIONNAIRE: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.QUESTIONNAIRE,
  INVITE: ROUTE_PATH.AUTH + ROUTE_PATH.ROOT + ROUTE_PATH.INVITE,
  CHOOSE_SKILLS: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.CHOOSE_SKILLS,
  SKILL_INFO: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.SKILL_INFO,
  


  MEMBER_DASHBOARD: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.MEMBER_DASHBOARD,
  MEMBER_TASK_LIST: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.TASK_LIST,
  MEMBER_MY_PROFILE:
    ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.MY_PROFILE,
  TASK_INFORMATION:
    ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.TASK_INFORMATION,
  TASK_MY_PROFILE: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.TASK_MY_PROFILE,
  TEAM_TASK_INFORMATION:
  ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.TEAM_TASK_INFORMATION,
  FUTURE_SKILL_INFO: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.FUTURE_SKILL_INFO,
  MEMBER_SETTING: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.SETTING,
  
  // support 
  FAQ: ROUTE_PATH.SUPPORT + ROUTE_PATH.ROOT + ROUTE_PATH.FAQ,
  CONTACT_US: ROUTE_PATH.SUPPORT + ROUTE_PATH.ROOT + ROUTE_PATH.CONTACT_US,

  //admin
  MANAGE_LEADER: ROUTE_PATH.ADMIN + ROUTE_PATH.ROOT + ROUTE_PATH.MANAGE_LEADER,
  MANAGE_INVOICE: ROUTE_PATH.ADMIN + ROUTE_PATH.ROOT + ROUTE_PATH.MANAGE_INVOICE
  
};
