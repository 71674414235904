import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { authetication } from "../../api";
import React from "react";
import { useParams } from "react-router-dom";

function useFetchData() {
  const params = useParams();
  const [data, setMessage] = React.useState([]);

  React.useEffect(() => {
    const token = params.token;
    const values = {
      token: token
    };

    authetication
      .doVerifyEmail(values)
      .then((res) => {
        setMessage(res.message);
      })
      .catch((error) => {
        setMessage(error.response?.data?.errorMessage);
      });
  }, []);
  return { data };
}

function App() {
  const { data } = useFetchData();

  return (
    <Row>
      <Col className="d-flex justify-content-center align-items-center p-0 login-signup-page-card h-100vh">
        <div className="login-form verify">
          <div className="w-100">
            <h2>
              Email <span>Verification</span>
            </h2>
            <div className="email-verification-message">
              {data}
            </div>
              <Button href="/auth" variant="link">
              Back to Sign In
            </Button>
            
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default function VerifyEmail() {
  return <App />;
}
