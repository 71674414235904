import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dimensions } from "../../api/models/Dimensions";
import { getTeamSkillDimensions } from "../../api/team";
import SkillsMetric from "./SkillsMetric";

export default function TeamSkills() {
  const [dimensions , setDimensions] = useState<Dimensions>([])
  const { t } = useTranslation();
  useEffect(() => {
    getTeamSkillDimensions().then(res => {
      setDimensions(res.data);
    })
  }, [])
  return (
    <div className="team-skills-wrapper">
      <div className="heading">
        <h3>{t("teamskills")}</h3>
      </div>
      <SkillsMetric dimensions={dimensions}/>
    </div>
  );
}
