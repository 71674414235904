import { useState } from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { ROLE } from "../../constants";
import { useAuth } from "../../hooks/useAuth";


export default function FilterMenu({
  doFilter,
  allTaskFilter = true,
  showSpan = false
}: {
    doFilter: (query: string) => void;
    allTaskFilter? :boolean,
    showSpan?: boolean
}) {
    const { user } = useAuth();
    const {t} = useTranslation();

    const searchData = (value: any) => {
    
      var elems = document.querySelectorAll(".filtering");

      [].forEach.call(elems, function(el:any) {
          el.classList.remove("active");
    });

    if(value == "") {
      document.getElementsByClassName("showAll")[0]?.classList.add("active");
    } else if(allTaskFilter) { 
      value == '0' ? document.getElementsByClassName("suggestion")[0]?.classList.add("active") : (
        value == '1' ? document.getElementsByClassName("assigned")[0]?.classList.add("active") : 
          document.getElementsByClassName("done")[0]?.classList.add("active")
      );
    } else {
      value == '3' ? document.getElementsByClassName("notAssigned")[0]?.classList.add("active") : 
          document.getElementsByClassName("assigned")[0]?.classList.add("active")
    }
    doFilter(value);
  };

  return (
    <>
        { allTaskFilter ? 
            <div className={`${showSpan ? "fitler-wrapper" :"filter-tags"}`}>
              <Button variant={`${showSpan ?  "filter-btn active" :"showTask-btn active"} showAll filtering`} onClick={() => searchData("")}>
                  {t("Show All tasks")}
              </Button>
              { user.role == ROLE.LEADER ? 
                <Button variant={`${showSpan ?  "filter-btn" : "suggestion-btn"} suggestion filtering`} onClick={() => searchData("0")}>
                  {
                    showSpan ? 
                      <span className="icon-color" style={{ 'backgroundColor' : '#40BBBD'}}></span> 
                    : 
                      null
                  }
                  {t('Suggestion')}
                </Button>
                : 
                null
              }
              <Button variant={`${showSpan ?  "filter-btn" :"assigned-btn"} assigned filtering `} onClick={() => searchData("1")}>
                  {
                    showSpan ?
                      <span className="icon-color" style={{ 'backgroundColor' : '#EFBF62'}}></span> 
                    :
                      null
                  }
                  {t("Assigned")}
              </Button>
              <Button variant={`${showSpan ?  "filter-btn" :"done-btn"} done filtering`} onClick={() => searchData("2")}>
                  {
                    showSpan ?
                      <span className="icon-color" style={{ 'backgroundColor' : '#71C49C' }}></span> 
                    :
                      null
                  }
                  {t("Done")}
              </Button>
            </div>
          :
          <div className={"fitler-wrapper"}>
            <Button variant="filter-btn active showAll filtering" onClick={() => searchData("")}>
                {t("Show All tasks")}
            </Button>
            <Button variant="filter-btn assigned filtering" onClick={() => searchData("4")}>
                {
                  showSpan ?
                    <span className="icon-color" style={{ 'backgroundColor' : '#EFBF62'}}></span> 
                  :
                    null
                }
                {t("Assigned")} ({t("pending")})
            </Button>
            <Button variant="filter-btn notAssigned filtering" onClick={() => searchData("3")}>
                {
                  showSpan ?
                    <span className="icon-color" style={{ 'backgroundColor' : '#B1B7C0' }}></span> 
                  :
                  null
                }
                {t("Not Assigned")}
            </Button>
          </div>
        }
      
    </>
  );
}
