import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import back from "../../../assets/images/iconBack.svg";
import { BackIcon } from "../../../components";
export default function DoPayment() {
  return (
    <>
      <Row>
        <section className="main-container shrink-width">
          <div className="heading-common">
          <BackIcon />
            <h1>Payment information</h1>
            <p>Here is your selected plan</p>
          </div>
          <div className="payment-information">
            <Col xs={12} lg={4} xl={3} className="choosen-plan">
              <div className="grid-choosen-plan">
                <div className="grid-choosen-plan-content">
                  <div className="choosen-top">Choosen Plan</div>
                  <div className="intro">
                    <h2>Base</h2>
                    <div className="save">Save $80</div>
                  </div>
                  <p>Perfect to get started</p>
                  <div className="price">
                    <span>$</span>123 <span className="cm">/m</span>
                  </div>
                  <ul>
                    <li>Upload Video up to 720p Resolution</li>
                    <li>Attachment & Post Scheduling</li>
                    <li>Set your rates</li>
                    <li>Exclusive Deals</li>
                    <li>Advanced Statistics</li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={8} xl={9} className="payment-mode-info">
              <h2>Choose from your saved cards</h2>
            </Col>
          </div>
        </section>
      </Row>
    </>
  );
}
