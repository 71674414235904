import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { AxiosError } from "axios";
import { useMessageModal } from "../../../hooks/useMessage";
import chooseSkill from "../../../assets/images/choose-skill-img-1.png";
import chooseSkillRight from "../../../assets/images/choose-skill-img-2.png";
import { FUTURE_SKILLS } from "../../../constants";
import { FutureSkill } from "../../../api/models/Questionnaire";
import {
  getFutureSkills,
  submitFutureSkills,
  getMemberAnsForFutureSkill,
} from "../../../api/questionnaire";
import { useNavigate } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const SKILL_SELCTION_LIMIT = 5;
export default function ChooseSkills() {
  const navigate = useNavigate();

  const [futureSkills, setFutureSkills] = useState<FutureSkill[]>(
    FUTURE_SKILLS.data
  );
  const [selectedFutureSkills, setSelectedFutureSkills] = useState<string[]>(
    []
  );
  const [selectedSkillCount, setSelectedSkillCount] = useState<number>(0);
  const { showMessage } = useMessageModal();
  const { updateUserData } = useAuth();
  const { t } = useTranslation();

  const handleSelection = (id: string) => {
    const index = selectedFutureSkills.indexOf(id);
    const skills = selectedFutureSkills;
    if (index > -1) {
      skills.splice(index, 1);
      setSelectedFutureSkills([ ...skills ]);
      setSelectedSkillCount(selectedSkillCount - 1);
    } else if (selectedSkillCount < SKILL_SELCTION_LIMIT) {
      skills.push(id);
      setSelectedFutureSkills([ ...skills ]);
      setSelectedSkillCount(selectedSkillCount + 1);
    }
  };

  const handleSubmit = () => {
    if (
      !(selectedSkillCount === 0 || selectedSkillCount > SKILL_SELCTION_LIMIT)
    ) {
      submitFutureSkills({ skillId: selectedFutureSkills })
        .then((res) => {
          updateUserData({isFutureSkillFilled: true})
          showMessage({
            buttonMain: "Go to profile",
            heading: t("The Questionnaire is filled!"),
            body: (
              <p>
                {t('futureSkillQuesMessgae')}
              </p>
            ),
            type: "success",
            callback: () => {
              navigate(ROUTE_NAVIGATION_PATH.MEMBER_MY_PROFILE);
            },
          });
        })
        .catch(
          (error: AxiosError<{ errorMessage: string; status: number }>) => {
            showMessage({
              heading: t("Error"),
              body: <p>{error.response?.data?.errorMessage}</p>,
              type: "error",
            });
          }
        );
    }
  };

  useEffect(() => {
    getFutureSkills().then((res) => {
      setFutureSkills(res.data.futureSkills);
    });

    getMemberAnsForFutureSkill().then((res) => {
      const data = res.data.skillIds;
      setSelectedFutureSkills(data);
      setSelectedSkillCount(data.length)
    });
  }, []);

  return (
    <Row>
      <section className="main-container shrink-width">
        <div className="heading-common">
          <h1>
            {t("Choose")} <span>{t("skills")}</span> {t("you want")} <span>{t("to improve")}</span>
          </h1>
          <p>{t("skillSelectionInfo")}</p>
        </div>
        <div className="choose-skills-wrapper">
          <Col md={11} lg={3} className="skills-grid">
            <div className="_image">
              <img src={chooseSkill} alt="Choose Skill" />
            </div>
          </Col>
          <Col md={11} lg={5} className="skills-grid">
            <ul>
              {futureSkills.map((skill) => (
                <li
                  className={
                    selectedFutureSkills.includes(skill._id)
                      ? "active"
                      : selectedSkillCount === SKILL_SELCTION_LIMIT
                      ? "opacity-50"
                      : ""
                  }
                  onClick={() => handleSelection(skill._id)}
                  key={skill._id}
                >
                  {skill.skill}
                </li>
              ))}
            </ul>
            <div className="btn-wrapper">
              <Button
                variant="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={selectedSkillCount === 0}
              >
                {t("Confirm")}
              </Button>
            </div>
          </Col>
          <Col md={11} lg={4} className="skills-grid">
            <div className="_image justify-content-end">
              <img src={chooseSkillRight} alt="Choose Skill" />
            </div>
          </Col>
        </div>
        <div className="top-right-bg"></div>
        <div className="bottom-left-bg"></div>
      </section>
    </Row>
  );
}
