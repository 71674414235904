import Button from "react-bootstrap/Button";
import profileIcon from "../../../assets/images/iconprofile.png";
import { ROLE, TASK_CAPACITY, TASK_STATUS, TASK_TYPE } from "../../../constants";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import badgeImg from "../../../assets/images/Badge.svg";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";


export default function LeftConatctMenu({
  taskDetail,
  status,
  doneTask,
}: {
  taskDetail: any;
  status: any;
  doneTask: VoidFunction;
}) {
    const { user } = useAuth();
    const { t } = useTranslation();
    return (
       <>
        <Col md={12} lg={4} className="contact-left-panel ">
        {taskDetail?.assignedTo.length > 0 || status == '4' ?
            <div>
                <h3>{t("Assignee")}</h3>
                <div className="user-profile-desc mb-5">
                    <div className="user-profile-panel _without-bg">
                        {status == '4' ?
                            <div className="profile-desc">
                                <div className="user-profile">
                                    <img src={taskDetail?.pendingSuggestions[0]?.teamMemberId?.image ? taskDetail?.pendingSuggestions[0]?.teamMemberId?.image : profileIcon}
                                        onError={(e: any) => { e.target.src = profileIcon}} alt="ProfileImg" />
                                    <img src={badgeImg} alt="badge" className="badges" />
                                    </div>
                                    <div className="user-info">
                                    <h4>{taskDetail?.pendingSuggestions[0]?.teamMemberId?.name} {taskDetail?.pendingSuggestions[0]?.teamMemberId?.surname}</h4>
                                    <p>{taskDetail?.pendingSuggestions[0]?.teamMemberId?.position}</p>
                                </div>
                            </div>
                            : 
                            <div className="profile-desc">
                                <div className="user-profile">
                                    <img src={taskDetail?.assignedTo[0]?.memberId?.image ? taskDetail?.assignedTo[0]?.memberId.image : profileIcon}
                                        onError={(e: any) => { e.target.src = profileIcon}} alt="ProfileImg" />
                                    <img src={badgeImg} alt="badge" className="badges" />
                                    </div>
                                    <div className="user-info">
                                    <h4>{taskDetail?.assignedTo[0]?.memberId?.name} {taskDetail?.assignedTo[0]?.memberId?.surname}</h4>
                                    <p>{taskDetail?.assignedTo[0]?.memberId?.position}</p>
                                </div>
                            </div>
                        }

                        <div className="task-capacity-n">
                        { status == '4' ?  
                            <div className="task-capacity">
                                <div className="tsk-capacity-c-bt _capacity-n">{t("Capacity")}:</div>
                                <div className="tsk-capacity-c-bt _small-n">
                                    S { Object.keys(taskDetail?.pendingSuggestions[0]?.teamMemberTaskCount).length === 0 ? 0 : (taskDetail?.pendingSuggestions[0]?.teamMemberTaskCount[TASK_TYPE.S] === undefined ? 0 : taskDetail?.pendingSuggestions[0]?.teamMemberTaskCount[TASK_TYPE.S]) }/{TASK_CAPACITY.SMALL}
                                </div>
                                <div className="tsk-capacity-c-bt _medium-n">
                                    M { Object.keys(taskDetail?.pendingSuggestions[0]?.teamMemberTaskCount).length === 0 ? 0 : (taskDetail?.pendingSuggestions[0]?.teamMemberTaskCount[TASK_TYPE.M] === undefined ? 0 : taskDetail?.pendingSuggestions[0]?.teamMemberTaskCount[TASK_TYPE.M]) }/{TASK_CAPACITY.MEDIUM}
                                </div>
                                <div className="tsk-capacity-c-bt _large-n">
                                    L { Object.keys(taskDetail?.pendingSuggestions[0]?.teamMemberTaskCount).length === 0 ? 0 : (taskDetail?.pendingSuggestions[0]?.teamMemberTaskCount[TASK_TYPE.L] === undefined ? 0 : taskDetail?.pendingSuggestions[0]?.teamMemberTaskCount[TASK_TYPE.L]) }/{TASK_CAPACITY.LARGE}
                                </div>
                            </div>
                            :
                            <div className="task-capacity">
                                
                                <div className="tsk-capacity-c-bt _capacity-n">{t("Capacity")}:</div>
                                <div className="tsk-capacity-c-bt _small-n">
                                    S { Object.keys(taskDetail?.assignedTo[0]?.teamMemberTaskCount).length === 0 ? 0 : (taskDetail?.assignedTo[0]?.teamMemberTaskCount[TASK_TYPE.S] === undefined ? 0 : taskDetail?.assignedTo[0]?.teamMemberTaskCount[TASK_TYPE.S]) }/{TASK_CAPACITY.SMALL}
                                </div>
                                <div className="tsk-capacity-c-bt _medium-n">
                                    M { Object.keys(taskDetail?.assignedTo[0]?.teamMemberTaskCount).length === 0 ? 0 : (taskDetail?.assignedTo[0]?.teamMemberTaskCount[TASK_TYPE.M] === undefined ? 0 : taskDetail?.assignedTo[0]?.teamMemberTaskCount[TASK_TYPE.M]) }/{TASK_CAPACITY.MEDIUM}
                                </div>
                                <div className="tsk-capacity-c-bt _large-n">
                                    L { Object.keys(taskDetail?.assignedTo[0]?.teamMemberTaskCount).length === 0 ? 0 : (taskDetail?.assignedTo[0]?.teamMemberTaskCount[TASK_TYPE.L] === undefined ? 0 : taskDetail?.assignedTo[0]?.teamMemberTaskCount[TASK_TYPE.L]) }/{TASK_CAPACITY.LARGE}
                                </div>
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </div>
            : 
            null
        }
        <h3>{t("Description")}</h3>
        <div className="user-profile-desc mt-3">
            <div className="user-profile-panel _without-bg">
                <div className="desc-text">
                    { taskDetail?.description}
                </div>
            </div>
        </div>
        <h3 className="mt-5">{t("Status")}</h3>
        <div className="user-profile-desc mt-3 mb-5">
            <div className="user-profile-panel _without-bg">
                <div className="status">
                    {taskDetail?.assignedTo.length > 0  || status == '4' ?
                        <div className={status == 2 ? 'done-tag': 'assigned-tag'}>
                           <div className="_imgnew">
                            <img src={taskDetail?.assignedTo[0]?.memberId?.image ? taskDetail?.assignedTo[0]?.memberId.image : profileIcon}
                                onError={(e: any) => { e.target.src = profileIcon}} alt="ProfileImg" />
                            </div>
                               {status == 2 ? t('Done') : (status == 4 ? `${t('Assigned')} + ${(t("pending"))}` : t('Assigned'))}
                        </div>
                    : 
                        null
                    } {
                        user.role == ROLE.LEADER ? 
                            (taskDetail?.assignedTo.length > 0 && taskDetail?.assignedTo[0].taskStatus == TASK_STATUS.DONE ?
                                <div className="next-match greyed-out">{t("next best match")}</div>
                            :
                            <Link to={`${ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE_SUGGESTION}/${taskDetail?._id}`} className="next-match">
                                {t("next best match")}
                            </Link>)
                        :
                        null
                    }
                </div>
            </div>
        </div>
        {taskDetail?.assignedTo.length > 0 ?
            ( taskDetail?.assignedTo[0].taskStatus == TASK_STATUS.DONE ?
                <Button as="a" variant="primary _green greyed-out">
                    {t("Done")}
                </Button>
                //null
            : 
                ( taskDetail?.assignedTo[0].taskStatus == TASK_STATUS.STARTED || taskDetail?.assignedTo[0].taskStatus == TASK_STATUS.INPROGRESS ? 
                    <Button as="a" variant="primary _green" onClick={doneTask}>
                        {t("Mark as done")}
                    </Button> 
                    : 
                    <Button as="a" variant="primary _green greyed-out">
                       {t("Mark as done")}
                    </Button> 
                )
            )
            :
            <Button as="a" variant="primary _green greyed-out">
               {t("Mark as done")}
            </Button>
        }
        </Col>
       </>
    );
}
