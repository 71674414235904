import { api } from "../api";

export const ENDPOINTS = {
  GET_MEMBER_DIMENSIONS: "v1/user/team-member/{teamMemberId}/skill-dimensions",
};

async function getMemberDimensions(id: string) {
  const url = ENDPOINTS.GET_MEMBER_DIMENSIONS.replace("{teamMemberId}", id);
  return api.get(url).then((res) => res.data);
}

export { getMemberDimensions };
