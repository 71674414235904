import React, { useCallback, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { useMessageModal } from "../../../hooks/useMessage";
import { MyTeamTasks } from "../../../components";
import { useTranslation } from "react-i18next";
import { ROLE } from "../../../constants";

export default function TeamTask() {
  const { showMessage } = useMessageModal();
  const { t } = useTranslation()
  const { user } = useAuth();
  const showError = useCallback(
    (errorMessage: string | undefined) => {
      showMessage({
        heading: t("Error"),
        body: <p>{errorMessage}</p>,
        type: "error",
      });
    },
    [showMessage]
  );

  useEffect(() => {
    
  }, [showError]);

  return (
    <>
      <Row>
        <section className="main-container transparent-bg main-container-2 shrink-width">
          <div className="heading-common">
            <h1>
            {t('Team')}<span> {t('Task')}</span>
            </h1>
            <p>{t('manageProjectMsg')}</p>
            { user.role === ROLE.LEADER ? 
              <Link to={ROUTE_NAVIGATION_PATH.CREATE_TASK}>
                <div className="add-btn absolute">{t("Add a task")}</div>
              </Link>
              : null
            }
          </div>
          <div className="team-task-list-wrapper">
            <div className="task-list-content">
              <MyTeamTasks />
            </div>
          </div>
        </section>
      </Row>

    
    </>
  );
}
