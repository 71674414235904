import { useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { notifications } from "../../api";
import Spinner from "react-bootstrap/Spinner";
import NTitle from "./NTile";
import { useLoadItems } from "../../hooks/useLoadItems";
import { useTranslation } from "react-i18next";

export default function NList() {
    const [page, setPage] = useState(0);
    const { t }= useTranslation();
    const { loading, items, hasNextPage, error } =
    useLoadItems({
      loadItems: notifications.getNotificationList,
      page
    });

    const [sentryRef, { rootRef }] = useInfiniteScroll({
      loading,
      hasNextPage,
      onLoadMore: () => setPage(page + 1),
      disabled: !!error,
      rootMargin: "0px 0px 400px 0px",
    });

    return (
      <div className="notification-drop-down" ref={rootRef}>
          <h3>{t("Notifications")}</h3>
          <div className="notification-scrollbar scrollbar">  
              {!hasNextPage && items.length == 0  ? 
                <div className="dashboard-no-data">
                  <p>{t("NoResultFound")}</p>
                </div> : 

                <div className="notification-by-day" >
                    {items.map((detail:any, key:any) => (
                        <NTitle
                            detail={detail}
                            state={key}
                        />
                    ))}
                </div>  
              }
              {hasNextPage && (
                  <div ref={sentryRef} className="spinner-wrap">
                    <div className="">
                      <Spinner />
                    </div>
                  </div>
              )}
          </div>
      </div>
    );
}
