// @ts-nocheck
import { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { SpiderGraphData } from "../../api/models/Dimensions";
import { TASK_THERSHOLD_VALUE } from "../../constants";
import ProgressBar from "react-bootstrap/ProgressBar";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip
  // Legend
);

function getLabelIndex(x: number, y: number, pointLables: any[]) {
  let index = -1;
  for (let i = 0; i < pointLables.length; i++) {
    const { top, left, right, bottom } = pointLables[i];
    if (x >= left && x <= right && y >= top && y <= bottom) {
      index = i;
      break;
    }
  }
  return index;
}
export const options = {
  plugins: {
    // tooltips: {
    //   trigger: "hover",
    //   // This will show the tooltip on the point label instead of the point itself
    //   position: "label",
    // },
  },
  onHover: ({ x, y }, activeHover, chart) => {
    // console.log(x, y, activeHover, chart.scales, chart.canvas);
    const { canvas } = chart;
    let oldIndex = -1;
    const index = getLabelIndex(x, y, chart.scales.r._pointLabelItems);
    // if (oldIndex !== index) {
      oldIndex = index;
      // which index number I am hovering on
      const label = chart.scales.r._pointLabelItems[index];
      const labelData = chart.scales.r._pointLabels[index];

      const skillPopup = document.getElementById("skillPopup");
      if (skillPopup) {
        if (index === -1) {
          canvas.style.cursor = "default";
          if (skillPopup?.style.opacity === "1") {
            skillPopup?.style.setProperty("opacity", 0);
            skillPopup?.style.removeProperty("top");
            skillPopup?.style.removeProperty("right");
            skillPopup?.style.removeProperty("left");
          }
        } else {
          canvas.style.cursor = "pointer";
          skillPopup?.style.setProperty("opacity", 1);
          if (label?.textAlign === "right") {
            skillPopup?.style.setProperty(
              "top",
              `${label?.top + (labelData.length > 1 ? 40 : 20)}px`
            );
            skillPopup?.style.setProperty("left", `${label?.x + 60}px`);
          } else if (label?.textAlign === "left") {
            skillPopup?.style.setProperty(
              "top",
              `${label?.top + (labelData.length > 1 ? 40 : 20)}px`
            );
            skillPopup?.style.setProperty("left", `${label?.right + 80}px`);
          } else {
            skillPopup?.style.setProperty(
              "top",
              `${label?.top + (labelData.length > 1 ? 40 : 20)}px`
            );
          }
          localStorage.setItem("activeIndex", index);
          window.dispatchEvent(new Event("showPopup"));
        }
      }
    // }
  },
  scales: {
    r: {
      maintainAspectRatio: false,
      responsive: true,
      beginAtZero: true,
      min: 10,
      max: 100,
      angleLines: {
        color: "#B1B7C0",
      },
      ticks: {
        stepSize: TASK_THERSHOLD_VALUE,
        callback: (value: any, tick: any, values: any) => {
          return "";
        },
        showLabelBackdrop: () => false,
      },
      grid: {
        color: "#4D60A4",
      },
      pointLabels: {
        color: "#373E45",
        font: {
          size: 20,
          weight: "500",
        },
        callback: (point) => {
          return point.split(" ");
        },
        // padding:10
      },
    },
  },
};

export default function SpiderGraph({
  data = {} as SpiderGraphData,
  fontSize = 16,
  customClass = "",
  characteristics = [],
}: {
  data: SpiderGraphData;
  fontSize?: number;
  customClass?: string;
  characteristics?: any[];
}) {
  options.scales.r.pointLabels.font.size = fontSize;
  const [activeDataforPopup, setActiveDataforPopup] = useState([]);
  let eventAdded = useRef(false);
  useEffect(() => {
    const storageEventHandler = () => {
      const activeIndex = localStorage.getItem("activeIndex");
      if (activeIndex) {
        setActiveDataforPopup(characteristics[+activeIndex]);
      }
    };
    if (characteristics.length && !eventAdded.current) {
      eventAdded.current = true;
      console.log("evebt added");
      window.addEventListener("showPopup", storageEventHandler);
    }
    return () => {
      eventAdded.current = false;
      window.removeEventListener("showPopup", storageEventHandler);
    };
  }, [characteristics]);
  return (
    <>
      <div className={`chart-container ${customClass}`}>
        <Radar
          data={data}
          options={options}
          // onMouseEnter={() => alert('dddd')}
        />
      </div>
      {characteristics.length > 0 ? (
        <div className="skill-dimension-drop-down" id="skillPopup">
          <ul>
            {activeDataforPopup.map((dimension, key) => (
              <li key={dimension.characteristic + key}>
                <p className="text-capitalize">{dimension.characteristic}</p>
                <div className="progress-bg-text">
                  <ProgressBar
                    now={+dimension?.value?.toFixed(1)}
                    label={`${dimension?.value?.toFixed(1)}%`}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
}
