import React from "react";
import { FormControl } from "../FormElements/FormControl";
import { composeValidators, minDateCompare, required } from "../../validations";
import { DatePickerControl } from "../FormElements/DatePicker";

export default function AddEditInvoice({values}: any) {
  return (
    <>
      <DatePickerControl
        label="Invoice Date"
        name="invoiceDate"
        validate={composeValidators(required)}
      />

      <DatePickerControl
        label="Plan Start Date"
        name="planStartDate"
        validate={composeValidators(required)}
      />

      <DatePickerControl
        label="Plan End Date"
        name="planEndDate"
        validate={composeValidators(required, minDateCompare(values.planStartDate))}
        minDate={values.planStartDate}
      />

      <FormControl
        label="Permiited Members*"
        name="permittedMembers"
        type="number"
        validate={composeValidators(required)}
        className="spacing-equal"
      />
      <FormControl
        label="Invoice Amount*"
        name="invoiceAmount"
        type="number"
        placeholder=""
        validate={composeValidators(required)}
        className="spacing-equal"
      />
    </>
  );
}
