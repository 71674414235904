import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import leaderTranslationsEN from "./en/leader.json";
import memberTranslationsEN from "./en/member.json";
import commonTranslationsEN from "./en/common.json";
import leaderTranslationsDE from "./de_DE/leader.json";
import memberTranslationsDE from "./de_DE/member.json";
import commonTranslationsDE from "./de_DE/common.json";
export const defaultNS = "leader";
export const COUNTRIES_NAME = {
  US: "US",
  DE: "DE",
};
export const LANG_CODE = {
  [COUNTRIES_NAME.US]: "en",
  [COUNTRIES_NAME.DE]: "de_DE",
};
export const BE_API_CODE = {
  [COUNTRIES_NAME.US]: "en",
  [COUNTRIES_NAME.DE]: "de",
};

export const BE_LANG_CODE = {
  "en": "en",
  "de": "de_DE",
};
export const resources = {
  en: {
    leader: {
      ...leaderTranslationsEN,
      ...commonTranslationsEN,
    },
    member: {
      ...memberTranslationsEN,
      ...commonTranslationsEN,
    },
  },
  de_DE: {
    leader: {
      ...leaderTranslationsDE,
      ...commonTranslationsDE,
    },
    member: {
      ...memberTranslationsDE,
      ...commonTranslationsDE,
    },
  },
};

i18next.use(initReactI18next).init({
  lng: localStorage.getItem("lang") ? localStorage.getItem("lang") as string: LANG_CODE.US, // if you're using a language detector, do not define the lng option
  debug: true,
  resources,
  defaultNS,
});

export default i18next;
