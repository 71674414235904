import { Field, FieldProps } from "react-final-form";
// import { DatePicker } from "rsuite";
import DatePicker from "react-datepicker";
import { useState } from "react";

interface FormControlProps extends FieldProps<any, any, any, any> {
  label?: string;
  minDate?: Date;
  //   icon?: string;
}

export function DatePickerControl({
  label,
  minDate = new Date(),
  ...rest
}: FormControlProps) {
  return (
    <Field
      {...rest}
      render={({ input, meta }) => (
        <div className="text-start form-field">
          <label className="form-label">{label}</label>
          <div className="input-group2">
            <DatePicker
              selected={input.value ? input.value : null}
              onChange={(date) => {
                console.log(date);
                input.onChange(date);
              }}
              minDate={minDate}
              dateFormat="dd-MM-yyyy"
              className={"form-control spacing-equal"}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>

          {meta.touched && meta.error && (
            <span className="error">{meta.error}</span>
          )}
        </div>
      )}
    />
  );
}
