import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import {
  Questions,
  AnsRecord,
  LikertScaleOptions,
  AnsObj
} from "../../api/models/Questionnaire";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export default function LikertScaleQTable({
  questions,
  ansOptions,
  setAnswer,
  selectedAns
}: {
  questions: Questions;
  ansOptions: Record<LikertScaleOptions, AnsObj>;
  setAnswer: (ans: AnsRecord) => void;
  selectedAns: AnsRecord
}) {
  const itemsRef = useRef<(HTMLTableRowElement | null)[]>([]);
  const ansOptionsKeys = Object.keys(ansOptions) as LikertScaleOptions[];
  const { t } = useTranslation();

  function moveTonextQ(nextIndex: number) {
    const nextQId = questions[nextIndex]?._id;
    if (nextQId) {
      const element = itemsRef.current[nextIndex];
      element?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th></th>
          <th></th>
          {ansOptionsKeys.map((option: LikertScaleOptions) => (
            <th key={option}>{t(ansOptions[option].title)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* <Form> */}
        {questions.map((question, index) => (
          <tr
            key={question._id}
            id={question._id + index}
            ref={(el) => (itemsRef.current[index] = el)}
          >
            <td className="big-font">{index + 1}</td>
            <td className="big-font">{question.testItem}</td>
            {ansOptionsKeys.map((option) => (
              <td key={option}>
                <Form.Check
                  type="radio"
                  name={question._id + index}
                  value={ansOptions[option].id}
                  onChange={(e: any) => {
                    moveTonextQ(index + 1);
                    setAnswer({ [question?._id]: {
                      option: e.target.value,
                      ansOptionKey: question.ansOptionKey,
                      taskGroupId: question.groupID
                    } });
                  }}
                  checked={+selectedAns[question?._id]?.option === ansOptions[option].id}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
