import { Member } from "../../api/models/User";
import profileImg from "../../assets/images/icon-profile.png";
import IconEdit from "../Icon/IconEdit";
import IconDelete from "../Icon/IconDelete";
import { TASK_CAPACITY, TASK_TYPE } from "../../constants";
import { Link } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useTranslation } from "react-i18next";

export default function TeamMemberTile({
  user,
  status,
  editMember,
  deleteMember,
}: {
  user: Member;
  status: any;
  editMember: VoidFunction;
  deleteMember: VoidFunction;
}) {
  const {t} = useTranslation();
  const  truncateText = (text: String) => {
    return text && text?.length > 24 ? text.slice(0, 24) + '...' : text;
  } 
  return (
    <div className="list-grid ">
        <div className="member-profile">
          <Link to={`${ROUTE_NAVIGATION_PATH.DASHBOARD}/${user._id}`}>
          <div className="_image">
            <img
              src={user?.image ? user?.image : profileImg}
              onError={(e: any) => {
                e.target.src = profileImg;
              }}
              alt="ProfileImg"
            />
          </div>
          <div className="name">
            {/* {truncateText(user?.name)} {user?.surname} */}
            {truncateText(user.name + ' ' +  user.surname)}
          </div>
         </Link>
        </div>
      <div className="action-btn">
        <div className="edit" onClick={editMember}>
          <IconEdit />
        </div>
        <div className="delete" onClick={deleteMember}>
          <IconDelete />
        </div>
      </div>

      <Link to={`${ROUTE_NAVIGATION_PATH.DASHBOARD}/${user._id}`}>
        <div className="position">
          {/* {(user?.position ? "(" + user?.position + ")" : null)} */}
          {user?.position ? truncateText("(" + user?.position + ")") : null}
        </div>

        <div className="email">
          <a href={"mailto:" + user?.email}>{user?.email}</a>
        </div>
        { status == '0' ?
          <div className="slot-free-wrapper">
            <div className="task-capacity-n smll-grid">
                <div className="tsk-capacity-c-bt _capacity-n">{t("Capacity")}:</div>
                <div className="tsk-capacity-c-bt _small-n">
                  S { Object.keys(user.teamMemberTaskCount).length == 0 ? 0 : (user.teamMemberTaskCount[TASK_TYPE.S] == undefined ? 0 : user.teamMemberTaskCount[TASK_TYPE.S]) }/{TASK_CAPACITY.SMALL}
                </div>
                <div className="tsk-capacity-c-bt _medium-n">
                  M { Object.keys(user.teamMemberTaskCount).length == 0 ? 0 : (user.teamMemberTaskCount[TASK_TYPE.M] == undefined ? 0 : user.teamMemberTaskCount[TASK_TYPE.M]) }/{TASK_CAPACITY.MEDIUM}
                </div>
                <div className="tsk-capacity-c-bt _large-n">
                  L { Object.keys(user.teamMemberTaskCount).length == 0 ? 0 : (user.teamMemberTaskCount[TASK_TYPE.L] == undefined ? 0 : user.teamMemberTaskCount[TASK_TYPE.L]) }/{TASK_CAPACITY.LARGE}
                </div>
            </div>
          </div>
          :
          null
        }
      </Link>
    </div>
  );
}
