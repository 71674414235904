import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Form } from "react-final-form";
import vectorInfo from "../../../assets/images/Vector.svg";
import { useMessageModal } from "../../../hooks/useMessage";
import { AddEditTask, UploadTasks } from "../../../components";
import { task } from "../../../api";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { TASK_TYPE } from "../../../constants";
import { Scope, Task } from "../../../api/models/Task";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

export default function CreateTask() {
  const { showMessage } = useMessageModal();
  const navigate = useNavigate();
  const { user, updateUserData } = useAuth();
  const { t } = useTranslation();

  const handleShowInfo = () => {
    showMessage({
      heading: t("What tasks can be added"),
      body: <p>{t("taskAddInfo")}</p>,
      type: "info",
    });
  };
  const onSubmit = (values: Task) => {
    const data = { ...values, scope: TASK_TYPE[values.scope as Scope] };
    task
      .createTask(data)
      .then((res) => {
        updateUserData({ taskCount: user.taskCount + 1 });
        user.isSuggestion
          ? navigate(ROUTE_NAVIGATION_PATH.TEAM_TASK)
          : navigate(ROUTE_NAVIGATION_PATH.TASK_LIST);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("Error"),
          body: <p>{error.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };
  return (
    <>
      <Row>
        <section className="main-container transparent-bg shrink-width">
          <div className="heading-common task-heading">
            <h1>
              {t("addNew")}
              <span> {t("TaskNew")}</span>!
            </h1>
            <p>{t("taskCreationSubTitile")}</p>
          </div>
          <div className="create-team-wrapper task-wrapper">
            <Col md={11} lg={5} className="create-team-grid">
              <h2>
                <span>{t("createATask")}</span>
                <sup className="info-action" onClick={handleShowInfo}>
                  <img src={vectorInfo} alt="info" />
                </sup>
              </h2>
              <div className="task-form">
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <AddEditTask />
                      <Button variant="primary" type="submit">
                        {t("createATask")}
                      </Button>
                    </form>
                  )}
                />
              </div>
            </Col>
            <Col md={11} lg={5} className="create-team-grid">
              <h2>{t("uploadAfile")}</h2>
              {<UploadTasks />}
            </Col>
          </div>
        </section>
      </Row>
    </>
  );
}
