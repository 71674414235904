import deleteIcon from "../../assets/images/delete-red.png";
import deleteIconWhite from "../../assets/images/delete-white.png";

export default function IconDelete() {
  return (
    <>
     <img src={deleteIcon} alt="Delete" className="_delete-icon" />
    <img src={deleteIconWhite} alt="" className="_delete-icon-hover" />

    </>
   
  );
}
