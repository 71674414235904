import React from "react";
import "./scss/custom.scss";
import { RouterProvider } from "react-router-dom";

import Container from "react-bootstrap/Container";

import { router } from "./routes";
import { AuthProvider } from "./hooks/useAuth";
import { MessageModalProvider } from "./hooks/useMessage";
import './i18n/config';

function App() {
  return (
    <MessageModalProvider>
      <AuthProvider>
        <Container fluid>
          <RouterProvider router={router} />
        </Container>
      </AuthProvider>
    </MessageModalProvider>
  );
}

export default App;
