import { ReactNode, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LikertScaleQTable from "./QTable";
import {
  AnsOptions,
  AnsRecord,
  Questions,
} from "../../api/models/Questionnaire";
import SelectionScaleQTable from "./SelectionQues";
import back from "../../assets/images/iconBack.svg";
import ConfirmationModal from "../Modal/Confirmation";


export default function QList({
  subHeading,
  progressbarHeading,
  likertScaleQuestions,
  selectionQuestons,
  ansOptions,
  onSubmitQ,
  selectedAns = {},
}: {
  subHeading: string;
  progressbarHeading: string | ReactNode;
  likertScaleQuestions: Questions;
  selectionQuestons: Questions;
  ansOptions: AnsOptions;
  onSubmitQ: (answers: AnsRecord) => void;
  selectedAns?: AnsRecord;
}) {
  const { t } = useTranslation();
  const [answers, setAnswer] = useState<AnsRecord>(selectedAns);
  const [progressNow, setProgressNow] = useState(0);
  const [olderProgress, setOlderProgress] = useState(0);
  const [enableNext, setEnableNext] = useState(false);
  const [step, setStep] = useState(0);
  const [showBackConfirm, showConfirmation] = useState<boolean>(false)
  const navigate = useNavigate();
  const isNext =
    selectionQuestons.length > 0 && step < selectionQuestons.length;

  function doSubmition() {
    onSubmitQ(answers);
  }

  function enableNextHandle(updatedAns: AnsRecord, enableNext = false) {
    const totalgivenAns = Object.keys(updatedAns).length;
    const enbaleNextcal =
      step === 0 ? totalgivenAns === likertScaleQuestions.length : enableNext;
    setEnableNext(enbaleNextcal);
  }

  const updateAns = (ans: AnsRecord) => {
    const updatedAns = { ...answers, ...ans };
    setAnswer(updatedAns);
    const totalgivenAns = Object.keys(updatedAns).length;
    setProgressNow(
      Math.floor(
        (totalgivenAns /
          (likertScaleQuestions.length + selectionQuestons.length)) *
          100
      )
    );
    enableNextHandle(
      updatedAns,
      !!ans[selectionQuestons[step - 1]?._id]?.option
    );
  };
  const onNext = () => {
    if (isNext) {
      setStep(step + 1);
    } else {
      doSubmition();
    }
  };

  const onBack = () => {
    setStep(step - 1);
    setEnableNext(true);
  };

  useEffect(() => {
    step > 0 &&
      enableNextHandle(
        answers,
        !!answers[selectionQuestons[step - 1]?._id]?.option
      );
  }, [step]);

  useEffect(() => {
    if (Object.keys(selectedAns).length) {
      setProgressNow(100);
      setAnswer(selectedAns);
      setEnableNext(true);
      setOlderProgress(100);
    }
  }, [selectedAns]);

  const onBackIcon = () => {
    if(progressNow === olderProgress) {
      navigate(-1)
    } else {
      showConfirmation(true);
    }
  }

  return (
    <Row>
      <section className="main-container shrink-width">
        <div className="heading-common">
          <div className="back-icon" onClick={onBackIcon}>
            <img src={back} alt="back" />
          </div>
          <h1>{t("Questionnaire")}</h1>
          <p>{t(subHeading)}</p>
          <div className="progressbar-with-heading">
            <h3>{progressbarHeading}</h3>
            <ProgressBar now={progressNow} label={`${progressNow}%`} />
          </div>
        </div>
        <div className="requirement-task-wrapper scrollbar">
          {likertScaleQuestions.length > 0 && step === 0 ? (
            <LikertScaleQTable
              questions={likertScaleQuestions}
              ansOptions={ansOptions?.likertScale}
              setAnswer={updateAns}
              selectedAns={answers}
            />
          ) : null}
          {selectionQuestons.length > 0 && step > 0 ? (
            <SelectionScaleQTable
              question={selectionQuestons[step - 1]}
              ansOptions={ansOptions}
              setAnswer={updateAns}
              selectedAns={answers}
            />
          ) : null}
          <div className="flex">
            {step > 0 ? (
              <Button onClick={onBack} variant="link">
                {t("Back")}
              </Button>
            ) : null}
            <Button className="add-btn" onClick={onNext} disabled={!enableNext}>
              {isNext ? t("Next") : t("Finish")}
            </Button>
          </div>
        </div>
      </section>
      <ConfirmationModal
        heading={ t("Warning")}
        body={<p>{t("backwarning")}</p>}
        show={showBackConfirm}
        handleClose={() => showConfirmation(false)}
        type="warning"
        callback={(isOk: boolean) => {
          isOk ? showConfirmation(false):navigate(-1)
        }}
        buttonMain="Cancel"
        buttonSecondary="Continue"
      />
    </Row>
  );
}
