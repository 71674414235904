import { useEffect, useState } from "react";
import { getMemberDimensions } from "../../api/member";
import { Dimensions, SpiderGraphData } from "../../api/models/Dimensions";
import { MEMBER_GPAPH_COLOR } from "../../constants";
import { useAuth } from "../../hooks/useAuth";
import SpiderGraph from "../SpiderGraph";
import SkillsMetric from "./SkillsMetric";

export default function MemeberSkillMetric(
  { 
    userId,
  }: { 
    userId: any,
  }
) {
  const [dimensions, setDimensions] = useState<Dimensions>([]);
  const [graphData, setGraphData] = useState<SpiderGraphData>({
    labels: [],
    datasets: [],
  } as SpiderGraphData);
  const [characteristics, setCharacteristics] = useState<any[]>([]);

  const auth = useAuth();
  const user = userId ? userId : auth.user.id;
  function formatGraphdata(dimensions: Dimensions) {
    const labels: string[] = [],
      data: number[] = [],
      answers: any[] =[]

    dimensions.forEach((dimension) => {
      labels.push(dimension.taskDimensionName);
      data.push(dimension.average);
      answers.push(dimension.answers);
    });
    setGraphData({
      labels,
      datasets: [
        {
          data,
          ...MEMBER_GPAPH_COLOR,
        },
      ],
    });
    setCharacteristics(answers);
  }
  useEffect(() => {
    if (user) {
      getMemberDimensions(user as string).then((res) => {
        setDimensions(res.data);
        formatGraphdata(res.data);
      });
    }
  }, [auth.user]);
  return (
    <>
      {" "}
      <SpiderGraph data={graphData} fontSize={12} characteristics={characteristics}/>
      <div className="px-5">
        <SkillsMetric dimensions={dimensions} />
      </div>
    </>
  );
}
