import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Link, useParams } from "react-router-dom";
import { team } from "../../../api";
import {
  TeamMemberTile,
  SearchBar,
  EditTeamMember,
  BackIcon,
  ConfirmationModal,
  NoData,
} from "../../../components";
import { useLoadItems } from "../../../hooks/useLoadItems";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { useMessageModal } from "../../../hooks/useMessage";
import { AxiosError } from "axios";
import { Member } from "../../../api/models/User";
import { USER_STATUS } from "../../../constants";
import { useTranslation } from "react-i18next";

export default function MembersList() {
  const { showMessage } = useMessageModal();
  const [detailsToShow, setDetailsToShow] = useState<Member | null>(null);
  const [memberToDelete, setMemberToDelete] = useState("");
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const params = useParams();
  const status = params.status ? USER_STATUS.ACTIVE : USER_STATUS.ALL;
  const url = params.status
    ? team.getAllTeamMembersByStatus
    : team.getTeamMembers;
  const { loading, items, hasNextPage, error, deleteItem, updateItem } =
    useLoadItems({
      loadItems: url,
      page,
      search,
      status,
    });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  const doSearch = (keyword: string) => {
    if (keyword !== search) {
      setPage(1);
      setSearch(keyword);
    }
  };

  const deleteMember = (isOk: boolean) => {
    if (isOk) {
      team
        .deleteMember(memberToDelete)
        .then((res) => {
          deleteItem(memberToDelete);
          setMemberToDelete("");
        })
        .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
          setMemberToDelete("");
          showMessage({
            heading: t("Error"),
            body: <p>{err.response?.data?.errorMessage}</p>,
            type: "error",
          });
        });
    }
  };

  const updateListItem = (updatedData: Member) => {
    updatedData._id = detailsToShow?._id as string;
    updateItem(updatedData);
    setDetailsToShow(null);
  };

  return (
    <>
      <Row>
        <section className="main-container shrink-width" ref={rootRef}>
          <div className="heading-common">
            {params ? <BackIcon /> : null}
            <h1>
              {t("Your")}
              <span> {t("Team")}</span>!
            </h1>
            <p>{t("manageTeamMsg")}</p>
            <Link to={ROUTE_NAVIGATION_PATH.CREATE_TEAM}>
              <div className="add-btn absolute">{t("addATeamMember")}</div>
            </Link>
          </div>
          <div className="member-list-wrapper">
            <SearchBar
              doSearch={doSearch}
              placeHolder={t("Search by name, email or postition")}
            />
            <div className="member-list-content scrollbar">
              {!items.length && !loading ? <NoData /> : null}
              {items.map((member) => (
                <TeamMemberTile
                  key={member._id}
                  user={member}
                  status={status}
                  editMember={() => setDetailsToShow(member)}
                  deleteMember={() => setMemberToDelete(member._id)}
                />
              ))}
              {/* <div className="bottom-graidient" ></div> */}
              {hasNextPage && (
                <div ref={sentryRef} className="spinner-wrap">
                  <div className="">
                    <Spinner />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </Row>
      <EditTeamMember
        detail={detailsToShow}
        handleClose={() => setDetailsToShow(null)}
        updateListItem={updateListItem}
      />
      <ConfirmationModal
        heading={t("removeTeamMeberHeading")}
        body={<p>{t("removeTeamMemberDescription")}</p>}
        show={!!memberToDelete}
        handleClose={() => setMemberToDelete("")}
        type="warning"
        callback={deleteMember}
        buttonMain='Remove'
        buttonSecondary="cancel"
      />
    </>
  );
}
