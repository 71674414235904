import { ROUTE_NAVIGATION_PATH } from "../routes/routes";
import iconDashboard from "../assets/images/dashboard-nav.svg";
import iconSupport from "../assets/images/support-nav.svg";
import iconTask from "../assets/images/task-nav.svg";
import iconTeam from "../assets/images/team-nav.svg";

export const LEADER_MENU = {
  DASHBOARD: {
    url: ROUTE_NAVIGATION_PATH.LEADER_DASHBOARD,
    title: "Dashboard",
    icon: iconDashboard,
    activeOn: [ROUTE_NAVIGATION_PATH.LEADER_DASHBOARD, ROUTE_NAVIGATION_PATH.STEPPER]
  },
  TEAM: {
    url: ROUTE_NAVIGATION_PATH.MEMBER_LIST,
    title: "Team",
    icon: iconTeam,
    activeOn: [
      ROUTE_NAVIGATION_PATH.MEMBER_LIST,
      ROUTE_NAVIGATION_PATH.PRE_CREATION,
      ROUTE_NAVIGATION_PATH.ACTIVE_MEMBER_LIST,
      ROUTE_NAVIGATION_PATH.CREATE_TEAM,
      ROUTE_NAVIGATION_PATH.TEAM_CREATION_INFO,
      ROUTE_NAVIGATION_PATH.DASHBOARD,
      ROUTE_NAVIGATION_PATH.MEMBER_PROFILE

    ]
  },
  TASK: {
    url: ROUTE_NAVIGATION_PATH.TASK_LIST,
    title: "Tasks",
    icon: iconTask,
    activeOn: [ROUTE_NAVIGATION_PATH.TASK_LIST,
    ROUTE_NAVIGATION_PATH.TEAM_TASK,
    ROUTE_NAVIGATION_PATH.REQUIREMENT_FOR_TASK_INFO,
    ROUTE_NAVIGATION_PATH.TASK_QUESTIONNAIRE,
    ROUTE_NAVIGATION_PATH.CREATE_TASK,
    ROUTE_NAVIGATION_PATH.ALL_TASK,
    ROUTE_NAVIGATION_PATH.NOT_STARTED_TASK,
    ROUTE_NAVIGATION_PATH.TEAM_TASK,
    ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE_SUGGESTION,
    ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE
    ]
  },
  SUPPORT: {
    url: ROUTE_NAVIGATION_PATH.FAQ,
    title: "Support",
    icon: iconSupport,
    activeOn: [
      ROUTE_NAVIGATION_PATH.FAQ,
      ROUTE_NAVIGATION_PATH.CONTACT_US
    ]
  },
};

export const LEADER_MENUS = [
  LEADER_MENU.DASHBOARD,
  LEADER_MENU.TEAM,
  LEADER_MENU.TASK,
  LEADER_MENU.SUPPORT,
];

export const MEMBER_MENU = {
  DASHBOARD: {
    url: ROUTE_NAVIGATION_PATH.MEMBER_DASHBOARD,
    title: "Dashboard",
    icon: iconDashboard,
    activeOn: [
      ROUTE_NAVIGATION_PATH.MEMBER_DASHBOARD,      
      ROUTE_NAVIGATION_PATH.CHOOSE_SKILLS, 
      ROUTE_NAVIGATION_PATH.SKILL_INFO,
      ROUTE_NAVIGATION_PATH.FUTURE_SKILL_INFO,
      ROUTE_NAVIGATION_PATH.MEMBER_QUESTIONNAIRE
    ]
  },
  TASK: {
    url: ROUTE_NAVIGATION_PATH.MEMBER_TASK_LIST,
    title: "Tasks",
    icon: iconTask,
    activeOn: [
      ROUTE_NAVIGATION_PATH.MEMBER_TASK_LIST, 
    ]
  },
  SUPPORT: {
    url: ROUTE_NAVIGATION_PATH.FAQ,
    title: "Support",
    icon: iconSupport,
    activeOn: [ROUTE_NAVIGATION_PATH.FAQ, ROUTE_NAVIGATION_PATH.CONTACT_US]
  },
};

export const MEMBER_MENUS = [
  MEMBER_MENU.DASHBOARD,
  MEMBER_MENU.TASK,
  MEMBER_MENU.SUPPORT,
];

export const ADMIN_MENU = {
  LEADER: {
    url: ROUTE_NAVIGATION_PATH.MANAGE_LEADER,
    title: "Dashboard",
    icon: iconDashboard,
    activeOn: [ROUTE_NAVIGATION_PATH.MANAGE_LEADER]
  }
};

export const ADMIN_MENUS = [
  ADMIN_MENU.LEADER
];
