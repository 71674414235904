import { Link } from "react-router-dom";
import { TASK_TYPE_LIST } from "../../constants";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import IconDelete from "../Icon/IconDelete";
import IconEdit from "../Icon/IconEdit";
import iconCompleted from "../../assets/images/iconApproved.svg";
import { useTranslation } from "react-i18next";

export default function TaskTile({
  task,
  editTask,
  deleteTask,
  shouldShowQuesInfoScreen,
}: {
  task: any;
  editTask: VoidFunction;
  deleteTask: VoidFunction;
  shouldShowQuesInfoScreen: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div className="list-grid tasks-grid">
      <div className="member-profile">
        <div className="name">{task.name}</div>
        <div className="tag-info">{TASK_TYPE_LIST[task.scope - 1]}</div>
      </div>
      <div className="assign-status">
        <div className="assign-tag">{t("Not Assigned")}</div>
      </div>
      <div className="status-with-action">
        {task.questionnaireStatus ? (
          <div className="completed-tag">
            <img src={iconCompleted} alt="completed" />
            {t("Completed")}
          </div>
        ) : (
          <div className="status">
            <Link
              to={
                shouldShowQuesInfoScreen
                  ? `${ROUTE_NAVIGATION_PATH.REQUIREMENT_FOR_TASK_INFO}/${task._id}/${task.name}`
                  : `${ROUTE_NAVIGATION_PATH.TASK_QUESTIONNAIRE}/${task._id}/${task.name}`
              }
            >
              {t("Plan This Task")}
            </Link>
          </div>
        )}

        <div className="action-btn">
          <div className="edit" onClick={editTask}>
            <IconEdit />
          </div>
          <div className="delete" onClick={deleteTask}>
            <IconDelete />
          </div>
        </div>
      </div>
    </div>
  );
}
