import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import {
  AppSettings,
} from "../../../components";
import { useTranslation } from "react-i18next";

export default function MemberSetting() {

  const {t} = useTranslation();

  return (
    <>
      <Row>
        <section className="main-container shrink-width">
          <div className="heading-common">
            <h1>
              {t("My")}<span> {t("Setting")}</span>
            </h1>
            <p>{t("updateSettingMsg")}</p>
          </div>
            <div className="setting-container">
              <Col md={12} xl={5} className="app-setting-content">
                <AppSettings />
              </Col>
            </div>
        </section>
      </Row>
    </>
  );
}
