import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import iconTask from "../../../assets/images/iconTask.svg";
import iconMember from "../../../assets/images/iconMember.svg";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { t } from "i18next";
import { useAuth } from "../../../hooks/useAuth";

export default function PreCreationScreen() {
  const { user } = useAuth();
  return (
    <>
      <Row>
        <section className="main-container transparent-bg shrink-width">
          <div className="heading-common">
            <h1>
              {t("lets")}
              <span> {t("create")}</span>!
            </h1>
            <p>{t("preCreationSubtitle")}</p>
          </div>
          <div className="pre-creation-wrapper">
            <Col md={10} lg={5} className="creation-grid">
            <h2>{t('Step')} 1</h2>
              <div className="icon-img">
                <img src={iconMember} alt="back" />
              </div>
              <h2>{t("createAMember")}</h2>
              <p>{t("createAmemberInfo")}</p>
              <Link to={ROUTE_NAVIGATION_PATH.TEAM_CREATION_INFO}>
                <Button variant="primary">{t("createAMember")}</Button>
              </Link>
            </Col>
            <Col md={10} lg={5} className={`creation-grid ${user.teamLeaderCount === 0 ? 'fade': ''}`} >
              <h2>{t('Step')} 2</h2>
              <div className="icon-img">
                <img src={iconTask} alt="back" />
              </div>
              <h2>{t("createATask")}</h2>
              <p>{t("createATaskInfo")}</p>
              <Link
                to={ROUTE_NAVIGATION_PATH.TASK_CREATION_INFO}
                className={user.teamLeaderCount === 0 ? "pointer-none" : ""}
              >
                <Button variant="primary" disabled={user.teamLeaderCount === 0}>
                  {t("createATask")}
                </Button>
              </Link>
            </Col>
          </div>
        </section>
      </Row>
    </>
  );
}
