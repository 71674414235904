import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import {
  AnsRecord,
  Question,
  AnsOptions,
} from "../../api/models/Questionnaire";

export default function SelectionScaleQTable({
  question,
  ansOptions,
  setAnswer,
  selectedAns,
}: {
  question: Question;
  ansOptions: AnsOptions;
  setAnswer: (ans: AnsRecord) => void;
  selectedAns: AnsRecord;
}) {
  const [ans, setAns] = useState<string[]>([]);
  const isSelectionRange = question.ansOptionKey === 'selectionRange'; // sonam,
  const { t } = useTranslation();
  const handleCheckBoxClick = (e: any) => {
    e.target.checked
      ? ans.push(e.target.value)
      : ans.splice(ans.indexOf(e.target.value), 1);
    setAns(ans);
    setAnswer({
      [question._id]: {
        option: isSelectionRange ? e.target.value : ans.join(","), // sonam
        ansOptionKey: question.ansOptionKey,
        taskGroupId: question.groupID
      },
    });
  };
  useEffect(() => {
    setAns(selectedAns?.[question._id]?.option?.split(',') || []);
  }, [selectedAns, question])
  
  return (
    <div className="questionlist">
      <p className="big-font">{question.testItem}</p>
      <ul>
        {Object.values(ansOptions[question.ansOptionKey]).map((ansOption) => (
          <li key={ansOption.id}>
            <Form.Check
              type={isSelectionRange ? "radio" : "checkbox"} // sonam
              name={isSelectionRange ? question._id :ansOption.title} //sonam
              value={ansOption.id}
              onChange={handleCheckBoxClick}
              label={<>{t(ansOption.title)}</>}
              checked={!!selectedAns[question._id]?.option?.includes(ansOption.id.toString())}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
