import { Field, FieldProps } from "react-final-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface FormControlProps extends FieldProps<any, any, any, any> {
  label?: string;
//   icon?: string;
}

export function MobilePhone({
  label,
  ...rest
}: FormControlProps) {
  return (
    <Field
      {...rest}
      render={({ input, meta }) =>
       
          <div className="text-start form-field">
            <label className="form-label">{label}</label>
            <div className="input-group2">
             
            <PhoneInput
              country={"us"}
              onChange = {(e, data:any) => {
                const mobile = {
                  countryCode:data.dialCode,
                  mobileNo: e.replace(data.dialCode,""),
                };
                input.onChange(mobile)
              }}

            />
            </div>

            {meta.touched && meta.error && (
              <span className="error">{meta.error}</span>
            )}
          </div>
      }
    />
  );
}
