import { useState } from "react";
import Button from "react-bootstrap/Button";
import { task } from "../../../api";
import Spinner from "react-bootstrap/Spinner";
import iconFilter from "../../../assets/images/iconFilter.svg";
import iconFilterWhite from "../../../assets/images/iconFilterWhite.png";

import Col from "react-bootstrap/Col";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  AllTeamTaskTile,
  NonActiveTaskTile,
  NoData,
  ConfirmationModal,
} from "../../";
import { useLoadItems } from "../../../hooks/useLoadItems";
import SearchBar from "../../SearchBar";
import { useMessageModal } from "../../../hooks/useMessage";
import { AxiosError } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import FilterMenu from "../../FilterMenu";
import EditTask from "../EditTask";
import { Task } from "../../../api/models/Task";
import { useTranslation } from "react-i18next";

export function NonActiveTask() {
  const [page, setPage] = useState(0);
  const { showMessage } = useMessageModal();
  const [detailsToShow, setDetailsToShow] = useState<any>(null);
  const [taskToDelete, setTaskToDelete] = useState("");
  const { t } = useTranslation();
  const { loading, items, hasNextPage, error, updateItem, deleteItem } =
    useLoadItems({
      loadItems: task.notActiveTask,
      page,
    });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 100px 0px",
  });

  const updateListItem = (updatedData: Task) => {
    updatedData._id = detailsToShow?._id as string;
    updateItem(updatedData);
    setDetailsToShow(null);
  };

  const deleteTask = (isOk: boolean) => {
    if (isOk) {
      task
        .deleteTask(taskToDelete)
        .then((res) => {
          deleteItem(taskToDelete);
          setTaskToDelete("");
        })
        .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
          setTaskToDelete("");
          showMessage({
            heading: t("Error"),
            body: <p>{err.response?.data?.errorMessage}</p>,
            type: "error",
          });
        });
    }
  };

  return (
    <>
      <div className="common-right-panel" ref={rootRef}>
        <div className="team-heading">
          <h2>{t("Not started tasks")}</h2>
          <Link to={ROUTE_NAVIGATION_PATH.NOT_STARTED_TASK}>
            <Button variant="seeAll">{t("seeall")}</Button>
          </Link>
        </div>

        <div className="grid-scroll scrollbar">
          <div className="grid-wrapper">
            {items.map((data: any) => (
              <NonActiveTaskTile
                task={data}
                key={data._id}
                editMyTask={() => setDetailsToShow(data)}
                deleteTask={() => setTaskToDelete(data._id)}
              />
            ))}
            {hasNextPage && (
              <div ref={sentryRef} className="spinner-wrap">
                <div className="">
                  <Spinner />
                </div>
              </div>
            )}
            {!items.length && !loading ? <NoData /> : null}
          </div>
        </div>
      </div>
      <EditTask
        detail={detailsToShow}
        handleClose={() => setDetailsToShow(null)}
        updateListItem={updateListItem}
      />
      <ConfirmationModal
        heading={t("deleteTaskHeading")}
        body={<p>{t("deleteTaskDes")}</p>}
        show={!!taskToDelete}
        handleClose={() => setTaskToDelete("")}
        type="warning"
        callback={deleteTask}
        buttonMain="Remove"
        buttonSecondary="Cancel"
      />
    </>
  );
}

export function AllTeamTask() {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [searchByMember, setSearchByMember] = useState("");
  const [taskFilter, setTaskFilter] = useState("");
  const { showMessage } = useMessageModal();
  const navigate = useNavigate();
  const [expendMenu, setExpendMenu] = useState(false);
  const [detailsToShow, setDetailsToShow] = useState<any>(null);
  const [taskToDelete, setTaskToDelete] = useState("");
  const { t } = useTranslation();
  const { loading, items, hasNextPage, error, updateItem, deleteItem } =
    useLoadItems({
      loadItems: task.teamTask,
      page,
      search,
      searchByMember,
      taskFilter,
    });

  const doSearch = (keyword: string) => {
    if (keyword !== search) {
      setPage(1);
      setSearch(keyword);
    }
  };

  const doSearchByMember = (keyword: string) => {
    if (keyword !== searchByMember) {
      setPage(1);
      setSearchByMember(keyword);
    }
  };

  const doFilter = (keyword: string) => {
    if (keyword !== taskFilter) {
      setPage(1);
      setTaskFilter(keyword);
    }
  };

  const toggleFilter = () => {
    setExpendMenu(!expendMenu);
    setTaskFilter("");
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 100px 0px",
  });

  const accpetAllSuggestions = () => {
    task
      .accpetAllSuggestions()
      .then((res: any) => {
        showMessage({
          heading: t("Accepted!"),
          body: <p>{res.message}</p>,
          type: "success",
          callback: () => {
            navigate(0);
          },
        });
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("Error"),
          body: <p>{error.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };

  const updateListItem = (updatedData: Task) => {
    updatedData._id = detailsToShow?._id as string;
    updateItem(updatedData);
    setDetailsToShow(null);
  };

  const deleteTask = (isOk: boolean) => {
    if (isOk) {
      task
        .deleteTask(taskToDelete)
        .then((res) => {
          deleteItem(taskToDelete);
          setTaskToDelete("");
        })
        .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
          setTaskToDelete("");
          showMessage({
            heading: t("Error"),
            body: <p>{err.response?.data?.errorMessage}</p>,
            type: "error",
          });
        });
    }
  };

  return (
    <>
      <div className="common-left-panel" ref={rootRef}>
        <div className="team-heading">
          <h2>{t("All tasks")}</h2>
          <Link to={ROUTE_NAVIGATION_PATH.ALL_TASK}>
            <Button variant="seeAll">{t("seeall")}</Button>
          </Link>
        </div>

        <div className="search-with-filter">
          <Col sm={8} className="d-flex">
            <SearchBar doSearch={doSearch} placeHolder={t("Search by title or description")} />
            <div
              className={`${expendMenu ? "selected-filter-btn" : "filter-btn"}`}
              onClick={toggleFilter}
            >
              {t("filters")}
              <img src={iconFilter} alt="filter" className="_blue-icon" />
              <img src={iconFilterWhite} alt="filter" className="_white-icon" />
            </div>
          </Col>
          <Col sm={4} className="btn-content">
            <Button variant="suggestions" onClick={accpetAllSuggestions}>
              {t("Accept all suggestions")}
            </Button>
          </Col>
        </div>
        {expendMenu ? (
          <div className="filter-open-wrapper">
            <div className="filter-by-search">
              <h3>{t("Filter by member")}</h3>
              <SearchBar
                doSearch={doSearchByMember}
                placeHolder={t("Type member name...")}
              />
            </div>
            <div className="filter-by-tag">
              <h3>{t("Filter by status")}</h3>
              <div className="filter-tags">
                <FilterMenu doFilter={doFilter} />
              </div>
            </div>
          </div>
        ) : null}

        {/* <div className="filter-search-tags-wrapper">
          <ul>
                  
            <li>Jenny Willson 
            <img src={iconCross} alt="cross" />
            </li>
          </ul>
        </div> */}

        <div className="grid-scroll scrollbar">
          <div className="grid-wrapper">
            {items.map((data: any) => (
                <AllTeamTaskTile
                  taskDetails={data}
                  editTask={() => setDetailsToShow(data)}
                  deleteTask={() => setTaskToDelete(data._id)}
                  className="task-list-grid tasks-grid"
                  key={data._id}
                />
            ))}
            {hasNextPage && (
              <div ref={sentryRef} className="spinner-wrap">
                <div className="">
                  <Spinner />
                </div>
              </div>
            )}
            {!items.length && !loading ? <NoData /> : null}
          </div>
        </div>
      </div>
      <EditTask
        detail={detailsToShow}
        handleClose={() => setDetailsToShow(null)}
        updateListItem={updateListItem}
      />
      <ConfirmationModal
        heading={t("deleteTaskHeading")}
        body={<p>{t("deleteTaskDes")}</p>}
        show={!!taskToDelete}
        handleClose={() => setTaskToDelete("")}
        type="warning"
        callback={deleteTask}
        buttonMain="Remove"
        buttonSecondary="Cancel"
      />
    </>
  );
}

export default function MyTeamTasks() {
  return (
    <>
      <AllTeamTask />
      <NonActiveTask />
    </>
  );
}
