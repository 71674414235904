import { useNavigate } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import UploadFile from "../FormElements/UploadFile";
import { ENDPOINTS } from '../../api/task';
import { useAuth } from "../../hooks/useAuth";

export default function UploadTasks() {
  const navigate = useNavigate();
  const { user, updateUserData } = useAuth();

  const onComplete = () => {
    updateUserData({ taskCount: user.taskCount + 1 });
    navigate(ROUTE_NAVIGATION_PATH.TASK_LIST);
  };
  return (
    <UploadFile
      onComplete={onComplete}
      sampleFileUrl={ENDPOINTS.GET_SAMPLE_FILE}
      uploadFileApiUrl={ENDPOINTS.UPLOAD_BULK_UPLOAD}
    />
  );
}
