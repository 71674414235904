import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useMessageModal } from "../../../hooks/useMessage";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";

export default function Stepper() {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { showMessage } = useMessageModal();

  useEffect(()=> {
    if (window.screen.width < 768) {
      showMessage({
        heading: "Error",
        body: <p>{t("Mobile Screen Info")}</p>,
        type: "error",
        callback: () => {
          localStorage.clear();
          window.location.href = '/';
        },
      });
    }
  })
  return (
    <>
      <Row>
        <section className="main-container shrink-width">
          <div className="heading-common">
            <h1>
              Hi<span> {user?.name}</span>!
            </h1>
            <p>
              {t('stepperSubTitle1')}
              <br />
              {t('stepperSubTitle2')}
            </p>
          </div>
          <div className="stepper-wrapper">
            <div className="profile-stepper">
              <div className="stepper-grid step-1">
                <div className="number">1</div>
                <div className="subheading">
                  {t('createTeam')}
                  <br />
                  {t('Team')}
                </div>
              </div>
              <div className="stepper-grid step-2">
                <div className="number">2</div>
                <div className="subheading">
                  {t('createNew')}
                  <br />
                  {t('Tasks')}
                </div>
              </div>
              <div className="stepper-grid step-3">
                <div className="number">3</div>
                <div className="subheading">
                {t('completeQuestionnaire')}
                </div>
              </div>
            </div>
            <div className="btn-wrapper">
              <Link to={ROUTE_NAVIGATION_PATH.PRE_CREATION}>
                {" "}
                <Button variant="primary">{t('LetsGo')}</Button>
              </Link>

              <p>{t('stepperDes')}</p>
            </div>
          </div>
          <div className="top-right-bg"></div>
          <div className="bottom-left-bg"></div>
        </section>
      </Row>
    </>
  );
}
