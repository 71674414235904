import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { useAuth } from "../../../hooks/useAuth";
import { useMessageModal } from "../../../hooks/useMessage";
import { team } from "../../../api";
import { Member } from "../../../api/models/User";
import { TEAM_LEADER_POSITION } from "../../../constants";
import { MyProfileManageTeam, LeftProfileMenu, EditTeamMember, UploadPhotoTeamMember } from "../../../components";
import { useTranslation } from "react-i18next";
import profileIcon from "../../../assets/images/iconprofile.png";


export default function LeaderProfile() {
  const { showMessage } = useMessageModal();
  const [detailsToShow, setDetailsToShow] = useState<Member | null>(null);
  const [details, setUpdatedDetails] = useState<Member | null>(null);
  const [photoDetails, setPhotoDetails] = useState<Member | null>(null);
  
  const {t} = useTranslation();

  useEffect(() => {
    team
      .getLeaderProfile()
      .then((res) => {
        res.data.userInfo["position"] = TEAM_LEADER_POSITION;
        setDetailsToShow(res.data.userInfo);
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: "Error",
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  }, []);

  const updateListItem = (updatedData: Member) => {
    updatedData._id = detailsToShow?._id as string;
    setDetailsToShow(updatedData);
    setPhotoDetails(null);
    setUpdatedDetails(null);
  };

  return (
    <>
      <Row>
        <section className="main-container shrink-width">
          <div className="heading-common">
            <h1>
              {t("My")}<span> {t("Profile")}</span>
            </h1>
            <p>{t("myProfileDes")}</p>
            {/* <div className="logout" onClick={handleClick}>
              <p className="header">Logout</p>
            </div> */}
          </div>
          {detailsToShow ? (
            <div className="task-myprofile-wrapper">
              <Col md={12} xl={4} className="profile-left-content">
                <LeftProfileMenu
                  user={detailsToShow}
                  editMember={() => setUpdatedDetails(detailsToShow)}
                  uploadPhoto={() => setPhotoDetails(detailsToShow)}
                />
              </Col>
              <Col md={12} xl={8} className="profile-right-content">
              <MyProfileManageTeam />
              </Col>
            </div>
          ) : (
            <div className="task-myprofile-wrapper">
              <div className="spinner-wrap">
                <div className="">
                  <Spinner />
                </div>
              </div>
            </div>
          )}
        </section>
      </Row>
      <EditTeamMember
        detail={details}
        handleClose={() => setUpdatedDetails(null)}
        updateListItem={updateListItem}
        isSurnameNotEditable={true}
      />
      <UploadPhotoTeamMember
        detail={photoDetails}
        show={!!photoDetails}
        handleClose={() => setPhotoDetails(null)}
        updateListItem={updateListItem}
      />
    </>
  );
}
