import React from "react";
import { FormControl } from "../FormElements/FormControl";
import { RadioControl } from "../FormElements/RadioControl";
import { composeValidators, required, maxlength } from "../../validations";
import { VALIDATIONS } from "../../constants";
import { useTranslation } from "react-i18next";
import TaskCapInfo from "./TaskCapInfo";

export default function AddEditTask() {
  const { t } = useTranslation();
  return (
    <>
      <FormControl
        label="Task title*"
        name="name"
        type="text"
        placeholder=""
        validate={composeValidators(required, maxlength(VALIDATIONS.MAX_TITLE))}
        className="spacing-equal"
      />
      <FormControl
        label="Task Description (optional)"
        name="description"
        type="textarea"
        validate={composeValidators(maxlength(VALIDATIONS.MAX_DESCRIPTION))}
        className="spacing-equal"
      />

      <label htmlFor="" className="form-label">
        {t("Task scope")}
        <TaskCapInfo />
      </label>
      <div className="redio-wrap">
        <RadioControl label="L" name="scope" value="L" className="custom" />
        <RadioControl label="M" name="scope" value="M" className="custom" />
        <RadioControl label="S" name="scope" value="S" className="custom" />
      </div>
    </>
  );
}
