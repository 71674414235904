import profileIcon from "../../../assets/images/iconprofile.png";
import badges from "../../../assets/images/Badge.svg";
import { Member } from "../../../api/models/User";
import { TASK_CAPACITY, TASK_TYPE } from "../../../constants";
import { useTranslation } from "react-i18next";

export default function ActiveTeamMemberTile({ user }: { user: Member }) {
  const {t} = useTranslation();
  return (
    <div className="active-list-grid">
      <div className="_profile-img">
        <img src={badges} alt="Badges" className="badges" />
        <img
          src={user?.image ? user?.image : profileIcon}
          onError={(e: any) => {
            e.target.src = profileIcon;
          }}
          alt="ProfileImg"
        />
      </div>
      <div className="name">
        {user.name} {user.surname}
      </div>
      <div className="designation">{user.position}</div>
      <div className="free-slot">
        <div className="text">{t("Capacity")}:</div>
        <div className="task-capacity-n smll-grid">
          <div className="tsk-capacity-c-bt _small-n">
            S { Object.keys(user.teamMemberTaskCount).length == 0 ? 0 : (user.teamMemberTaskCount[TASK_TYPE.S] == undefined ? 0 : user.teamMemberTaskCount[TASK_TYPE.S]) }/{TASK_CAPACITY.SMALL}
          </div>
          <div className="tsk-capacity-c-bt _medium-n">
            M { Object.keys(user.teamMemberTaskCount).length == 0 ? 0 : (user.teamMemberTaskCount[TASK_TYPE.M] == undefined ? 0 : user.teamMemberTaskCount[TASK_TYPE.M]) }/{TASK_CAPACITY.MEDIUM}
          </div>
          <div className="tsk-capacity-c-bt _large-n">
            L { Object.keys(user.teamMemberTaskCount).length == 0 ? 0 : (user.teamMemberTaskCount[TASK_TYPE.L] == undefined ? 0 : user.teamMemberTaskCount[TASK_TYPE.L]) }/{TASK_CAPACITY.LARGE}
          </div>
        </div>
      </div>
    </div>
  );
}
