import Button from "react-bootstrap/Button";
import profileIcon from "../../../assets/images/iconprofile.png";
import { Member } from "../../../api/models/User";
import { useTranslation } from "react-i18next";

export default function LeftProfileMenu({
  user,
  editMember,
  uploadPhoto
}: {
  user: Member;
  editMember: VoidFunction;
  uploadPhoto: VoidFunction;
}) {
  const { t } = useTranslation();
  return (
    <div className="personal-profile-info">
      <div className="left-content">
        <h2>{t("Personal Details")}</h2>
      </div>
      <div className="right-content mt-2">
        <Button as="a" variant="seeAll" onClick={editMember}>
          {t("Edit")}
        </Button>
      </div>
      <div className="personal-info-details">
        <div className="profile-added-content">
        <div className="_profile-img">
          <img
            src={user?.image ? user?.image : profileIcon}
            onError={(e: any) => {
              e.target.src = profileIcon;
            }}
            alt="ProfileImg"
          />
        </div>
        <Button as="a" variant=" btn-secondary uploadimage" onClick={uploadPhoto}>
          {t("upload image")}
        </Button>
        </div>
        <div className="personaltext">
          <div className="hd-info mb-2">{t("Name")}</div>
          <div className="white-bx">
            <p>{user.name}</p>
          </div>
        </div>
        {/* <div className="personaltext">
          <div className="hd-info mb-2">{t("Surname")}</div>
          <div className="white-bx">
            <p>{user.surname}</p>
          </div>
        </div> */}
        <div className="personaltext">
          <div className="hd-info mb-2">{t("Email")}</div>
          <div className="white-bx">
            <p>{user.email}</p>
          </div>
        </div>
        {user.position ? (
          <div className="personaltext">
            <div className="hd-info mb-2">{t("Position")}</div>
            <div className="white-bx">
              <p>{user.position}</p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
    
  );
}
