import TeamMembersRow from "../TeamMember/ManageTeam/TeamMembersRow";
import i18next, { COUNTRIES_NAME, LANG_CODE, BE_LANG_CODE, BE_API_CODE } from "../../i18n/config";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import { useState } from "react";
import { updateLang } from "../../api/myprofile";

const COUNTRIES = [COUNTRIES_NAME.US, COUNTRIES_NAME.DE];
const LANG = {
  [COUNTRIES_NAME.US]: "English",
  [COUNTRIES_NAME.DE]: "German",
};

const BE_LANG_CODE_REVERSE = {
  en: "US",
  de_DE: "DE",
};

export default function LangSetting() {
  const [lang, setLang] = useState(
    BE_LANG_CODE_REVERSE[localStorage.getItem("lang") as keyof typeof BE_LANG_CODE_REVERSE] ||
      COUNTRIES_NAME.US
  );
  const handleLangOnChange = (e: string) => {
    i18next.changeLanguage(LANG_CODE[e]);
    localStorage.setItem("lang", LANG_CODE[e]);
    setLang(e);
    updateLang(BE_API_CODE[e]);
  };

  const { t } = useTranslation();
  return (
    <TeamMembersRow
      className="more-password-info"
      heading={t("App Settings")}
      leftButton={<></>}
    >
      <div className="more-language">
        <label>{t("Language")}</label>

        <ReactFlagsSelect
          countries={COUNTRIES}
          customLabels={LANG}
          placeholder="Select Language"
          selected={lang}
          onSelect={handleLangOnChange}
        />
      </div>

      {/* <div className="more-language">
        <label>{t("Country")}</label>
        <ReactFlagsSelect
          countries={COUNTRIES}
          placeholder="Select Country"
          selected="US"
          onSelect={handleCountryChange}
        />
      </div> */}
      {/* <Button as="a" variant="seeAll">
        Delete Account
      </Button> */}
    </TeamMembersRow>
  );
}
