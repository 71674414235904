import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MessageModalType } from "../../types/global.type";
import iconInfo from "../../assets/images/iconInfo.svg";
import iconSuccess from "../../assets/images/iconSuccess.svg";
import iconError from "../../assets/images/iconError.svg";
import { useTranslation } from "react-i18next";

const ICON = {
  success: iconSuccess,
  error: iconError,
  info: iconInfo,
  warning: iconInfo,
};

export default function InfoModal({
  heading,
  body,
  footer,
  show,
  handleClose,
  type = "success",
  callback,
  buttonMain = 'Got it',
  closeButton = false
}: MessageModalType) {
  const { t } = useTranslation();
  const onOk = () => {
    callback && callback();
    handleClose();
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      // backdrop="static"
      keyboard={false}
      dialogClassName={"model-common"}
    >
      <Modal.Header closeButton={closeButton}>
        <div className="_icon-img">
          <img src={ICON[type]} alt="info" />
        </div>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      {body}
      {/* <Modal.Body>
        <p>
          The following statements refer to your personality, that is, feelings,
          attitudes and behaviors in your life. Please try to describe yourself
          as you see yourself in general.
        </p>
      </Modal.Body> */}
      <Modal.Footer>
        {footer}
        <Button variant="primary" onClick={onOk}>
          {t(buttonMain)}
        </Button>
        {/* <Button variant="download2" type="submit">
          <div className="icon-download">
            <img src={iconDownload} alt="Download" />
          </div>
          Download
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}
