import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-final-form";

import AddEditMember from "../FormElements/AddEditMember";
import { team } from "../../api";
import { AxiosError } from "axios";
import { useState } from "react";
import { useMessageModal } from "../../hooks/useMessage";
import { Member } from "../../api/models/User";
import { useTranslation } from "react-i18next";

export default function UploadPhotoTeamMember({
  detail,
  show,
  handleClose,
  updateListItem,
}: {
  detail: any;
  show: boolean;
  handleClose: VoidFunction;
  updateListItem: (member: Member) => void;
}) {
  const [file, setFile] = useState<File | null>();
  const { showMessage } = useMessageModal();
  const { t } = useTranslation();
  const editable = true;
  const updateImage = (file: File | null) => {
    file ? setFile(file) : setFile(null);
  };
  const onSubmit = (values: any) => {
    const formData = new FormData();
    formData.append("name", detail.name);
    formData.append("surname", detail.surname);
    if (file) {
      formData.append("image", file);
    }
    team
      .editMember(formData, detail?._id as string)
      .then((res) => {
        updateListItem(res.data);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: "Error",
          body: <p>{error.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };
  return (
    <Modal
      show={!!show}
      onHide={handleClose}
      // backdrop="static"
      dialogClassName={"team-memeber-model"}
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{t("uploadPhoto")}</Modal.Title>
      </Modal.Header>
      <p>{t("photoEdit")}</p>
      <Form
        initialValues={detail}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <AddEditMember
                updateImage={updateImage}
                editForm={editable}
                isEditPhoto={!!detail?.image}
                photo={detail?.image}
                onlyPhoto={true}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                {t('save')}
              </Button>
              <Button variant="cancel" onClick={handleClose}>
              {t('cancel')}
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
}
