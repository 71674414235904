import iconEdit from "../../../assets/images/edit-yellow.svg";
import iconDelete from "../../../assets/images/delete-yellow.svg";
import { BackIcon } from "../..";
import { useTranslation } from "react-i18next";
import { ROLE } from "../../../constants";

export default function UpdateContactHeader({
  showEditDelete,
  editTask,
  deleteTask,
  assignTask,
  role,
  name
}: {
  showEditDelete: boolean;
  editTask?: VoidFunction;
  deleteTask?: VoidFunction;
  assignTask?: VoidFunction;
  role: any;
  name: string
}) {
  const { t } = useTranslation();
  return (
    <div className="heading-common">
      <BackIcon />
      <h1>{name}</h1>
      {
        role === ROLE.LEADER ?
          <>
            <p>{t("suggestiondes")}</p>


            {(showEditDelete ?
            <div className="top-right-btn">
              <div className="btn-top edit-btn">
                <img src={iconEdit} alt="edit" onClick={editTask} />
              </div>
              <div className="btn-top delete-btn">
                <img src={iconDelete} alt="Delete" onClick={deleteTask} />
              </div>
            </div>
            : <div className="add-btn absolute" onClick={assignTask}>{t("Assign")}</div>
            )}
          </> :
          null}
    </div>
  );
}
