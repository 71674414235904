import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import back from "../../../assets/images/iconBack.svg";
import iconDown from "../../../assets/images/arrow-down.svg";
import iconUp from "../../../assets/images/arrow-up.svg";

export default function PaymentHistory() {
  return (
    <>
      <Row>
        <section className="main-container shrink-width">
          <div className="heading-common">
            <div className="back-icon">
              <img src={back} alt="back" />
            </div>
            <h1>
              Payment <span> History</span>
            </h1>
            <p>See your payments</p>
            <div className="add-btn absolute">download report</div>
          </div>
          <div className="payment-history-wrapper scrollbar">
            <div className="table-history ">
              <table>
                <thead>
                  <tr>
                    <th>
                      Date
                      <span className="arrow">
                        <img src={iconUp} alt="arrow" className="toparrow" />
                        <img src={iconDown} alt="arrow" className="downarrow" />
                      </span>
                    </th>
                    <th>Card number</th>
                    <th>Plan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>20. 08. 2022 12:37 </td>
                    <td>xxxx-xxxx-xxxx-x089</td>
                    <td>Pro</td>
                  </tr>
                  <tr>
                    <td>20. 08. 2022 12:37 </td>
                    <td>xxxx-xxxx-xxxx-x089</td>
                    <td>Pro</td>
                  </tr>
                  <tr>
                    <td>20. 08. 2022 12:37 </td>
                    <td>xxxx-xxxx-xxxx-x089</td>
                    <td>Pro</td>
                  </tr>
                  <tr>
                    <td>20. 08. 2022 12:37 </td>
                    <td>xxxx-xxxx-xxxx-x089</td>
                    <td>Pro</td>
                  </tr>
                  <tr>
                    <td>20. 08. 2022 12:37 </td>
                    <td>xxxx-xxxx-xxxx-x089</td>
                    <td>Pro</td>
                  </tr>
                  <tr>
                    <td>20. 08. 2022 12:37 </td>
                    <td>xxxx-xxxx-xxxx-x089</td>
                    <td>Pro</td>
                  </tr>
                  <tr>
                    <td>20. 08. 2022 12:37 </td>
                    <td>xxxx-xxxx-xxxx-x089</td>
                    <td>Pro</td>
                  </tr>
                  <tr>
                    <td>20. 08. 2022 12:37 </td>
                    <td>xxxx-xxxx-xxxx-x089</td>
                    <td>Pro</td>
                  </tr>
                  <tr>
                    <td>20. 08. 2022 12:37 </td>
                    <td>xxxx-xxxx-xxxx-x089</td>
                    <td>Pro</td>
                  </tr>
                  <tr>
                    <td>20. 08. 2022 12:37 </td>
                    <td>xxxx-xxxx-xxxx-x089</td>
                    <td>Pro</td>
                  </tr>
                  <tr>
                    <td>20. 08. 2022 12:37 </td>
                    <td>xxxx-xxxx-xxxx-x089</td>
                    <td>Pro</td>
                  </tr>
                  <tr>
                    <td>20. 08. 2022 12:37 </td>
                    <td>xxxx-xxxx-xxxx-x089</td>
                    <td>Pro</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </Row>
    </>
  );
}
