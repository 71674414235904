import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Form } from "react-final-form";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  composeValidators,
  maxlength,
  required,
  validEmail,
  validPassword,
  confirmPasswordMatchValidation,
} from "../../validations";

import { AuthLayout, FormControl, PasswordField } from "../../components";
import IconEmailBlue from "../../assets/images/iconEmailBlue.svg";
import IconUserBlue from "../../assets/images/iconUserBlue.svg";
import { VALIDATIONS, MESSAGES } from "../../constants";
import { authetication } from "../../api";
import { useMessageModal } from "../../hooks/useMessage";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { encryptPassword } from "../../utils";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

function SignUpForm() {
  const { showMessage } = useMessageModal();
  const navigate = useNavigate();
  const { saveToken } = useAuth();
  const { token } = useParams();
  const { t } = useTranslation();

  const [initData, setInitData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    displayName: ""
  });

  const onSubmit = (values: any) => {
    const data = {
      ...values,
      password: encryptPassword(values.password),
      name: token ? initData.displayName : values.name
    };
    delete data.confirmPassword;
    delete data.displayName;
    authetication
      .doSignup(data, token)
      .then((res) => {
        if (token) {
          saveToken(res);
          navigate(ROUTE_NAVIGATION_PATH.CHOOSE_SKILLS, { replace: true });
        } else {
          showMessage({
            heading: t("Please check your email"),
            body: <p>{MESSAGES.VERIFICATION_INFO}</p>,
            type: "success",
            callback: () => {
              navigate(ROUTE_NAVIGATION_PATH.SIGN_IN, { replace: true });
            },
          });
        }
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{t(error?.response?.data?.errorMessage as string) }</p>,
          type: "error",
        });
      });
  };

  useEffect(() => {
    if (token) {
      authetication
        .getInvitedUserData(token)
        .then((res: any) => {
          setInitData({
            email: res.data.email,
            name: res.data.name + ' ' +res.data.surname,
            password: "",
            confirmPassword: "",
            displayName: res.data.name
          });
        })
        .catch(
          (error: AxiosError<{ errorMessage: string; status: number }>) => {
            showMessage({
              heading: t("error"),
              body: <p>{t(error?.response?.data?.errorMessage as string)}</p>,
              type: "error",
            });
          }
        );
    }
  }, [token]);

  return (
    <>
      <h2>
        {t('createyour')} <span>{t('account')}</span>
      </h2>
      <Card.Text>{t('accountCreationMsg')}</Card.Text>
      <Form
        initialValues={initData}
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <FormControl
              label="Name"
              name="name"
              type="Name"
              placeholder={t('name')}
              icon={IconUserBlue}
              validate={composeValidators(
                required,
                maxlength(VALIDATIONS.MAX_NAME)
              )}
              disabled={!!token}
            />
            <FormControl
              label="Email"
              name="email"
              type="email"
              placeholder={t('email') as string}
              validate={composeValidators(required, validEmail)}
              icon={IconEmailBlue}
              disabled={!!token}
            />
            <PasswordField
              validations={composeValidators(required, validPassword)}
            />
            {/* <span className="info">
              Password should consist at least 6 symbols
            </span> */}
            <PasswordField
              validations={composeValidators(
                required,
                confirmPasswordMatchValidation(values.password)
              )}
              label="confirmPassword"
              name="confirmPassword"
            />
            <Button variant="primary" type="submit">
              {t('signUp')}
            </Button>
          </form>
        )}
      />
    </>
  );
}
export default function SignUp() {
  const navigate = useNavigate();
  return (
    <AuthLayout
      leftContent={
        <>
          <h1>{t('signUpTitle')}</h1>
          <p>{t('signUpSubtitle')}</p>

          <p>{t('signUpdesc')}</p>
          <div className="already-account">
            <p>{t('signUpSignInMsg')}</p>
            <Button
              variant="blue-light"
              onClick={() => navigate(ROUTE_NAVIGATION_PATH.SIGN_IN)}
            >
              {t('Sign In')}
            </Button>
          </div>
        </>
      }
      rightContent={<SignUpForm />}
    />
  );
}
