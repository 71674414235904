import { Info } from "../../../components";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import memberSkillImg from "../../../assets/images/createMember.svg";
import { useMessageModal } from "../../../hooks/useMessage";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function FutureSkillsInfo() {
  const { t } = useTranslation();
  const { showMessage } = useMessageModal();
  useEffect(()=> {
    if (window.screen.width < 768) {
      showMessage({
        heading: "Error",
        body:  <p>{t("Mobile Screen Info")}</p>,
        type: "error",
        callback: () => {
          localStorage.clear();
          window.location.href = '/';
        },
      });
    }
  })

  return (
    <Info
      title={t("We")}
      spantitle="care"
      nextTitle="for your future"
      subTitle="futureSkillSubTitle"
      image={memberSkillImg}
      description1="futureSkillDescription1"
      description2="futureSkillDescription2"
      description3="futureSkillDescription3"
      buttonText="LetsGo"
      nextUrl={ROUTE_NAVIGATION_PATH.CHOOSE_SKILLS}
    />
  );
}
