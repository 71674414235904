import { api } from "../api";

export const ENDPOINTS = {
  GET_QUESTIONNAIRE: "v1/questionnaire/list",
  POST_QUESTIONNAIRE: "v1/questionnaire/save-answers",
  GET_FUTURE_SKILLS_OPTIONS: "v1/future-skills/list",
  POST_FUTURE_SKILLS: "v1/future-skills/save-answers",
  GET_MEMBER_ANS_FUTURE_SKILL: 'v1/future-skills/user-answers',
  GET_MEMBER_ANS_CURRENT_SKILL: 'v1/questionnaire/user-answers'
};

async function getQuestionnaire(data: any) {
  return api
    .get(ENDPOINTS.GET_QUESTIONNAIRE, { params: data })
    .then((res) => res.data);
}

async function submitQuestionnaire(data: any) {
  return api.post(ENDPOINTS.POST_QUESTIONNAIRE, data).then((res) => res.data);
}

async function submitFutureSkills(data: any) {
  return api.post(ENDPOINTS.POST_FUTURE_SKILLS, data).then((res) => res.data);
}

async function getMemberAnsForFutureSkill() {
  return api.get(ENDPOINTS.GET_MEMBER_ANS_FUTURE_SKILL).then(res => res.data);
}

async function getMemberAnsForCurrentSkill() {
  return api.get(ENDPOINTS.GET_MEMBER_ANS_CURRENT_SKILL).then(res => res.data);
}

async function getFutureSkills() {
  return api
    .get(ENDPOINTS.GET_FUTURE_SKILLS_OPTIONS)
    .then((res) => res.data);
}

export {
  getQuestionnaire,
  submitQuestionnaire,
  submitFutureSkills,
  getFutureSkills,
  getMemberAnsForFutureSkill,
  getMemberAnsForCurrentSkill
};
