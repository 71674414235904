import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Info({
  title,
  spantitle,
  nextTitle,
  subTitle,
  description1,
  description2,
  description3,
  description4,
  description5,
  buttonText,
  image,
  nextUrl,
}: {
  title?: React.ReactNode | string;
  spantitle?: string;
  nextTitle?: string;
  subTitle?: string;
  description1?: string;
  description2?: string;
  description3?: string;
  description4?: string;
  description5?: string;
  buttonText?: string;
  image?: string;
  nextUrl: string;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <section className="main-container shrink-width">
          <div className="heading-common">
            <h1>
              {title} <span>{spantitle ? t(spantitle) : ""} </span>{" "}
              {nextTitle ? t(nextTitle) : ""}
            </h1>
            <p>{subTitle ? t(subTitle) : ""}</p>
          </div>
          <div className="questionnaire-wrapper">
            <Col md={10} lg={5} className="questionnaire-grid">
              <div className="_image">
                <img src={image} alt="SkillInfo" />
              </div>
            </Col>
            <Col md={10} lg={5} className="questionnaire-grid">
              <div className="_content">
                {description1 ? <p>{t(description1)}</p> : null}
                {description2 ? <p>{t(description2)}</p> : null}
                {description3 ? <p>{t(description3)}</p> : null}
                {description4 ? <p>{t(description4)}</p> : null}
                {description5 ? <p>{t(description5)}</p> : null}
              </div>
              {buttonText ? (
                <div className="btn-wrapper ">
                  <Link to={nextUrl}>
                    <Button variant="primary">{t(buttonText)}</Button>
                  </Link>
                </div>
              ) : null}
              {/* <div className="manage-team-skill-info">
                <div className="skill-info-text">
                  <p>
                    You haven’t created your team yet. Let’s add your team
                    members easily
                  </p>
                  <div className="btn-wrapper ">
                    <Link to={nextUrl}>
                      <Button variant="primary">Add a team member</Button>
                    </Link>
                  </div>
                </div>
              </div> */}
            </Col>
          </div>
          <div className="top-right-bg"></div>
          <div className="bottom-left-bg"></div>
        </section>
      </Row>
    </>
  );
}
