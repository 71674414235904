import { useTranslation } from "react-i18next";

export default function NoData() {
  const {t} = useTranslation();
  return (
    <div className="no-data-found">
      <p>{t("NoResultFound")}</p>
    </div>
  );
}
