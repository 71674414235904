import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import UpdateContactHeader from "../../../components/Tasks/UpdateContact/UpdateContactHeader";
import { SuggestedMemberList } from "../../../components";

import { task } from "../../../api";
import { useMessageModal } from "../../../hooks/useMessage";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import SuggestedMemberDimGraph from "../../../components/Tasks/UpdateContact/Suggestions/SuggestedMemberDimensionGraph";
import { useAuth } from "../../../hooks/useAuth";
import { t } from "i18next";
import { useTranslation } from "react-i18next";


export default function ContactPage() {
  const params = useParams();
  const { showMessage } = useMessageModal();
  const paramTaskId = params.taskId;
  const [suggestions, setSuggestions] = useState([]);
  const [allTaskDetail , setAllTaskDetail] = useState<any>(null);
  const [memberId, setMemberId] = useState(null);
  const [keyId, setkeyId] = useState(0);
  const { user } = useAuth();
  const {t} = useTranslation();


  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    task
      .getTaskDetail(paramTaskId)
      .then((res: any) => {
        setLoading(false);
        const suggestions = res.data.suggestions;
        const member = res.data.suggestions[0].teamMemberId._id;
        setSuggestions(suggestions);
        setAllTaskDetail(res.data);
        setMemberId(member);
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        setLoading(false);
        showMessage({
          heading: t("Error"),
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  }, []);

  const setAssisgnMemberId = (member: any, key: any) => {
    setMemberId(member);
    setkeyId(key);
  };

  const assignSuggestion = () => {
    task
      .acceptSuggestion(paramTaskId, memberId)
      .then((res) => {
        showMessage({
          heading: "Accepted!",
          body: <p>{res.message}</p>,
          type: "success",
          callback: () => {
            navigate(ROUTE_NAVIGATION_PATH.TEAM_TASK);
          },
        });
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("Error"),
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };

  return (
    <>
      <Row>
        <section className="main-container shrink-width">
          <UpdateContactHeader
            showEditDelete={false}
            assignTask={() => assignSuggestion()}
            role={user.role}
            name={allTaskDetail?.name}
          />
          {loading ? (
            <div className="spinner-wrap">
              <div className="">
                <Spinner />
              </div>
            </div>
          ) : (
            <div className="my-profile-wrapper">
              <SuggestedMemberList
                setAssisgnMemberId={setAssisgnMemberId}
                suggestions={suggestions}
                activeSuggestion={keyId}
              />
              <SuggestedMemberDimGraph data={allTaskDetail}/>
            </div>
          )}
        </section>
      </Row>
    </>
  );
}
