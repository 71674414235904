import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import back from "../../../assets/images/iconBack.svg";
import { BackIcon } from "../../../components";

export default function ChoosePlan() {
  return (
    <>
      <Row>
        <section className="main-container shrink-width">
          <div className="heading-common">
            <BackIcon />
            <h1>
              Ready to <span>get started</span>?
            </h1>
            <p>Choose a plan tailored to your needs</p>
          </div>
          <div className="choose-plan-wrapper grey-bg">
            <div className="grid-plan">
              <div className="intro">
                <h2>Intro</h2>
                <div className="price">
                  <span>$</span>123 <span className="cm">/m</span>
                </div>
              </div>
              <p>Perfect to get started</p>
              <ul>
                <li>Upload Video up to 720p Resolution</li>
                <li>Attachment & Post Scheduling</li>
                <li>Set your rates</li>
                <li>Exclusive Deals</li>
                <li>Advanced Statistics</li>
              </ul>
              <Button variant="primary" type="submit">
                Choose
              </Button>
            </div>
            <div className="grid-plan">
              <div className="save">Save $80</div>
              <div className="intro">
                <h2>Base</h2>
                <div className="price">
                  <span>$</span>123 <span className="cm">/m</span>
                </div>
              </div>
              <p>Perfect to get started</p>
              <ul>
                <li>Upload Video up to 720p Resolution</li>
                <li>Attachment & Post Scheduling</li>
                <li>Set your rates</li>
                <li>Exclusive Deals</li>
                <li>Advanced Statistics</li>
              </ul>
              <Button variant="primary" type="submit">
                Choose
              </Button>
            </div>
            <div className="grid-plan">
              <div className="save">Save $80</div>

              <div className="intro">
                <h2>Pro</h2>
                <div className="price">
                  <span>$</span>123 <span className="cm">/m</span>
                </div>
              </div>
              <p>Perfect to get started</p>
              <ul>
                <li>Upload Video up to 720p Resolution</li>
                <li>Attachment & Post Scheduling</li>
                <li>Set your rates</li>
                <li>Exclusive Deals</li>
                <li>Advanced Statistics</li>
              </ul>
              <Button variant="primary" type="submit">
                Choose
              </Button>
            </div>
          </div>
        </section>
      </Row>
    </>
  );
}
