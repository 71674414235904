import { Field, FieldProps } from "react-final-form";
import { useTranslation } from "react-i18next";

interface FormControlProps extends FieldProps<any, any, any, any> {
  label?: string;
  icon?: string;
}

export function FormControl({
  label,
  placeholder,
  type,
  icon,
  disabled,
  ...rest
}: FormControlProps) {
  const { t } = useTranslation();
  return (
    <Field
      {...rest}
      render={({ input, meta }) =>
        type === "file" ? (
          <div className="file-input">
            <span>{t(label as string) }</span>
            <input {...input} className="form-control" type={type} />
          </div>
        ) : (
          <div className="text-start form-field">
            <label className="form-label">{t(label as string)}</label>
            <div className="input-group2">
              {icon ? (
                <div className="input-group-prepend">
                  <span id="basic-addon1">
                    <img src={icon} alt="icon" />
                  </span>
                </div>
              ) : null}
              {type === "textarea" ? (
                <textarea
                  {...input}
                  className={`form-control ${icon ? "" : "spacing-equal"}`}
                  placeholder={t(placeholder as string)}
                  rows="8"
                />
              ) : (
                <input
                  {...input}
                  disabled={disabled}
                  className={`form-control ${icon ? "" : "spacing-equal"}`}
                  placeholder={t(placeholder)}
                  type={type}
                />
              )}
            </div>
            {meta.touched && meta.error &&  (
              <span className="error">{typeof meta.error === 'string' ? t(meta.error): meta.error}</span>
            )}
          </div>
        )
      }
    />
  );
}
