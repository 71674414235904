import Button from "react-bootstrap/Button";
import { MONTHS } from "../../constants";
import { notifications } from "../../api";
import { AxiosError } from "axios";
import { useMessageModal } from "../../hooks/useMessage";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";

export default function NTitle({ 
    detail,
    state
}: { 
    detail: any;
    state :number;
}) {
    const { showMessage } = useMessageModal();
    const { updateUserData, user } = useAuth();
    const date = new Date(detail.createdAt);
    const currentDate = new Date();
    const [key, setKey] = useState(-1);
    const { t } = useTranslation()

    const compareDate = (createdDate:any, currentDate:any) => {
        //var currentDateVal = MONTHS[currentDate.getMonth()]+', '+date.getDate();
        var dateVal = MONTHS[createdDate.getMonth()]+', ' +createdDate.getDate();
        return dateVal;
    }

    function handleRead(detail:any, key:number) {
        setKey(key)
        notifications.readNotification(detail?._id)
        .then((res) => {
            updateUserData({totalNotificationsCount: user.totalNotificationsCount - 1})
        })
        .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
            showMessage({
              heading: t("Error"),
              body: <p>{error.response?.data?.errorMessage}</p>,
              type: "error",
            });
        });
    }

    return (
        <div className="nlist-main">
            <h4>{compareDate(date, currentDate)}</h4>
            <div className={`masseges ${detail?.isRead === true || key == state ? "grey" : "yellowlight"}`}>
                <div className={`left-content ${detail.isRead ==true ? "leftfull-width" : ""}`}>
                    <p>{detail?.title}</p>
                    <div className="sizes-info-notification">
                        <div className="text">{detail?.body}</div>
                    </div>
                </div>
                <div className="btn-accepted">
                {
                    (detail.isRead || key == state) ? 
                        null
                    : 
                        <Button variant="accept" onClick={() => handleRead(detail, state)}>
                            {t('Got it')}
                        </Button>
                }
                </div>
            </div>
        </div>
    );
}