import React from "react";

export default function TeamMembersRow({
  className,
  children,
  heading,
  leftButton,
}: {
  className?: string;
  heading: string;
  children: React.ReactNode;
  leftButton: React.ReactNode;
}) {
  return (
    <div className={`active-user-content-wrapper ${className}`}>
      {" "}
      <div className="heading">
        <h3>{heading}</h3>
        {leftButton}
      </div>
      <div className="active-user-content">{children}</div>
    </div>
  );
}
