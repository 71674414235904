import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Form } from "react-final-form";
import { Link, useNavigate } from "react-router-dom";
import { composeValidators, required, validEmail } from "../../validations";
import { AuthLayout, FormControl, PasswordField } from "../../components";
import IconEmailBlue from "../../assets/images/iconEmailBlue.svg";
import logo from "../../assets/images/logo.svg";

import { useAuth } from "../../hooks/useAuth";
import { AuthType } from "../../api/models/User";
import { encryptPassword } from "../../utils";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { ROLE } from "../../constants";
import { useTranslation } from "react-i18next";

function SignInForm() {
  const { t } = useTranslation();
  const { signin } = useAuth();
  const navigate = useNavigate();
  const onSubmit = (values: AuthType) => {
    const data = { ...values };
    data.password = encryptPassword(data.password);
    signin(data, (res) => {
      if (res.user.role === ROLE.MEMBER) {
        if(!res.user?.isQuestionnaireFilled) {
          navigate(ROUTE_NAVIGATION_PATH.SKILL_INFO, { replace: true });
        } else if(!res.user?.isFutureSkillFilled){
          navigate(ROUTE_NAVIGATION_PATH.FUTURE_SKILL_INFO, { replace: true });
        } else {
          navigate(ROUTE_NAVIGATION_PATH.MEMBER_DASHBOARD, { replace: true });
        }
      } else if(res.user.role === ROLE.LEADER) {
        var date = res.user.invoiceId != null ? new Date(res.user.invoiceId.planEndDate) : null;
        if(res.user.invoiceId == null || (date && date < new Date())) {
          navigate(ROUTE_NAVIGATION_PATH.SUBSCRIPTION);
        } else {
          res.user?.teamLeaderCount > 0 || res.user.taskCount > 0
          ? navigate(ROUTE_NAVIGATION_PATH.LEADER_DASHBOARD, { replace: true })
          : navigate(ROUTE_NAVIGATION_PATH.STEPPER, { replace: true });
        }
      } else {
        navigate(ROUTE_NAVIGATION_PATH.MANAGE_LEADER, { replace: true })
      }
    });
  };
  return (
    <>
      <h2>
        {t('signinto')} <span>Prop</span>
       
      </h2>
      <Card.Text>{t('signSubText')}</Card.Text>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <FormControl
              label="Email"
              name="email"
              type="email"
              placeholder="Email"
              validate={composeValidators(required, validEmail)}
              icon={IconEmailBlue}
            />
            <PasswordField
              validations={required}
            />
            <Button variant="primary" type="submit">
              {t('signSubmit')}
            </Button>
            <Link to={ROUTE_NAVIGATION_PATH.FORGOT_PASSWORD}>
              <Button variant="link">{t('isForgetPassword')}</Button>
            </Link>
          </form>
        )}
      />
    </>
  );
}
export default function SignIn() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <AuthLayout
      leftContent={
        <>
          <h1>{t('welcomeBack')}</h1>
          <p>{t('signInInfo')}</p>
          <div className="already-account">
            <p>{t('dontHaveAccount')}</p>
            <Button
              variant="blue-light"
              onClick={() => navigate(ROUTE_NAVIGATION_PATH.SIGN_UP)}
            >
              {t('signUp')}
            </Button>
          </div>
        </>
      }
      rightContent={<SignInForm />}
    />
  );
}
