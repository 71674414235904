import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useParams } from "react-router-dom";
import UpdateContactHeader from "../../../components/Tasks/UpdateContact/UpdateContactHeader";
import SuggestedMemberDimGraph from "../../../components/Tasks/UpdateContact/Suggestions/SuggestedMemberDimensionGraph";
import { useEffect, useState } from "react";
import {
  ConfirmationModal,
  EditTask,
  LeftConatctMenu,
  TaskFeedBack,
} from "../../../components";
import { task } from "../../../api";
import { AxiosError } from "axios";
import { useMessageModal } from "../../../hooks/useMessage";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { Spinner } from "react-bootstrap";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

export default function ContactPageEdit() {
  const params = useParams();
  const [detailsToShow, setDetailsToShow] = useState<any>(null);
  const [details, setUpdatedDetails] = useState<any>(null);
  const [taskDetail, setTaskDetail] = useState<any>(null);
  const [taskToDelete, setTaskToDelete] = useState("");
  const [taskToDone, setTaskToDone] = useState("");
  const [feedBack, setFeedBack] = useState<any>(null);
  const { showMessage } = useMessageModal();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const status = params.status;
  const { user } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    const taskId = params.taskId;
    setLoading(true);
    task
      .getTaskDetail(taskId)
      .then((res: any) => {
        setTaskDetail(res.data);
        const data = {
          _id: res.data._id,
          name: res.data.name,
          description: res.data.description,
          scope: res.data.scope,
        };

        setDetailsToShow(data);
        setLoading(false);
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        setLoading(false);
        showMessage({
          heading: t("Error"),
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  }, []);

  const updateListItem = (updatedData: any) => {
    updatedData._id = detailsToShow?._id as string;
    setDetailsToShow(updatedData);
    setUpdatedDetails(null);
  };

  const deleteTask = (isOk: boolean) => {
    if (isOk) {
      task
        .deleteTask(taskToDelete)
        .then((res: any) => {
          setTaskToDelete("");
          navigate(ROUTE_NAVIGATION_PATH.TEAM_TASK);
        })
        .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
          setTaskToDelete("");
          showMessage({
            heading: t("Error"),
            body: <p>{err.response?.data?.errorMessage}</p>,
            type: "error",
          });
        });
    }
  };

  const markDoneTask = (isOk: boolean) => {
    if (isOk) {
      task
        .markDoneTask(taskToDone)
        .then((res: any) => {
          setTaskToDone("");
          setFeedBack(taskDetail?._id);
        })
        .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
          setTaskToDone("");
          showMessage({
            heading: t("Error"),
            body: <p>{err.response?.data?.errorMessage}</p>,
            type: "error",
          });
        });
    }
  };

  return (
    <>
      <Row>
        <section className="main-container shrink-width">
          <UpdateContactHeader
            showEditDelete={true}
            editTask={() => setUpdatedDetails(detailsToShow)}
            deleteTask={() => setTaskToDelete(taskDetail?._id)}
            role={user.role}
            name={taskDetail?.name}
          />
          {loading ? (
            <div className="spinner-wrap">
              <div className="">
                <Spinner />
              </div>
            </div>
          ) : (
            <div className="my-profile-wrapper">
              {taskDetail ? (
                <LeftConatctMenu
                  taskDetail={taskDetail}
                  status={status}
                  doneTask={() => setTaskToDone(taskDetail?._id)}
                />
              ) : (
                <Col md={12} lg={4} className="contact-left-panel">
                  <div className="spinner-wrap">
                    <div className="">
                      <Spinner />
                    </div>
                  </div>
                </Col>
              )}
              {status == "5" ? (
                <Col md={12} lg={8} className="contact-content-wrapper">
                  <div className="dashboard-no-data h-100">
                    <p>{t("NoResultFound")}</p>
                  </div>
                </Col>
              ) : (
                <SuggestedMemberDimGraph
                  isAssigned={true}
                  isDescription={false}
                  data={taskDetail}
                  status={status}
                />
              )}
            </div>
          )}
        </section>
      </Row>
      <EditTask
        detail={details}
        handleClose={() => setUpdatedDetails(null)}
        updateListItem={updateListItem}
      />
      <ConfirmationModal
        heading={t("deleteTaskHeading")}
        body={<p>{t("deleteTaskDes")}</p>}
        show={!!taskToDelete}
        handleClose={() => setTaskToDelete("")}
        type="warning"
        callback={deleteTask}
        buttonMain="Remove"
        buttonSecondary="Cancel"
      />
      <ConfirmationModal
        heading={<>{t("taskmarkdoneheading")}</>}
        body={<p>{t("taskmarkdonebody")}</p>}
        show={!!taskToDone}
        handleClose={() => setTaskToDone("")}
        type="info"
        callback={markDoneTask}
        buttonMain="Confirm"
        buttonSecondary="Cancel"
        hideIcon={true}
      />
      <TaskFeedBack
        heading={taskDetail?.name}
        show={!!feedBack}
        detail={taskDetail?._id}
        handleClose={() => setFeedBack("")}
      />
    </>
  );
}
