import { useTranslation } from "react-i18next";
import vectorInfo from "../../assets/images/Vector.svg";
import { useMessageModal } from "../../hooks/useMessage";

function TaskCapInfoDes({ heading }: { heading?: string }) {
  const { t } = useTranslation();
  return (
    <>
      <p>{heading ? t(heading) : t("taskScopedes")}</p>
      <ul>
        <li>
          <span className="sizes">L</span>{" "}
          <span>{t("Requires 25-80 hours")}</span>
        </li>
        <li className="active">
          <span className="sizes">M</span>{" "}
          <span>{t("Requires 6-24 hours")}</span>
        </li>
        <li>
          <span className="sizes">S</span>{" "}
          <span>{t("Requires 1-5 hours")}</span>
        </li>
      </ul>
    </>
  );
}

export default function TaskCapInfo({ heading }:{ heading?: string }) {
  const { showMessage } = useMessageModal();
  const { t } = useTranslation();

  const handleShowScopeInfo = () => {
    showMessage({
      heading: t("Task scope"),
      body: <TaskCapInfoDes heading={heading} />,
      type: "info",
    });
  };
  return (
    <img
      src={vectorInfo}
      alt="Info"
      className="_img info-icon-image"
      onClick={handleShowScopeInfo}
    />
  );
}
