import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  AnsOptions,
  AnsRecord,
  Question,
  Questions,
  RatingScale,
} from "../../api/models/Questionnaire";
import {
  getMemberAnsForCurrentSkill,
  getQuestionnaire,
  submitQuestionnaire,
} from "../../api/questionnaire";
import { QList } from "../../components";
import { useAuth } from "../../hooks/useAuth";
import { useMessageModal } from "../../hooks/useMessage";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";

const Q_TYPE = {
  TASK: "task",
  SKILL: "skill",
};

export default function Questionnaire() {
  const { taskId } = useParams();
  const { taskName } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedAns, setSelectedAns] = useState({});
  const [likertScaleQuestions, setLikertScaleQuestions] = useState<Questions>(
    []
  );
  const [selectionScaleQuestions, setSelectionScaleQuestions] =
    useState<Questions>([]);
  const [ansOptions, setAnsOptions] = useState<AnsOptions>({} as AnsOptions);
  const { showMessage } = useMessageModal();
  const { user } = useAuth();
  const showError = useCallback(
    (errorMessage: string | undefined) => {
      showMessage({
        heading: t("Error"),
        body: <p>{errorMessage}</p>,
        type: "error",
      });
    },
    [showMessage]
  );

  useEffect(() => {
    !taskId &&
      getMemberAnsForCurrentSkill().then((res) => {
        const data: Record<string, any> = {};
        res.data.questionnairesAns.forEach(
          (item: {
            questionId: {
              _id: string,
              groupID: string
            };
            ansOption: string;
            ansOptionKey: string;
            taskGroupId: string;
          }) => {
            data[item.questionId._id] = {
              option: item.ansOption,
              ansOptionKey: item.ansOptionKey,
              taskGroupId: item.questionId.groupID,
            };
          }
        );
        setSelectedAns(data);
      });
    getQuestionnaire({ type: taskId ? Q_TYPE.TASK : Q_TYPE.SKILL })
      .then((res) => {
        const groupByRatingScale: Record<RatingScale, Questions> =
          res.data.questionnaires.reduce(
            (group: Record<RatingScale, Questions>, question: Question) => {
              const { ratingScale } = question;
              group[ratingScale] = group[ratingScale] ?? [];
              group[ratingScale].push(question);
              return group;
            },
            {}
          );

        setLikertScaleQuestions(groupByRatingScale.likertScale || []);
        setSelectionScaleQuestions(groupByRatingScale.selection || []);
        setAnsOptions(res.data.ansOptions);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showError(error.response?.data?.errorMessage);
      });
  }, [showError]);

  function submitQuestions(ans: AnsRecord) {
    const data = {
      taskId,
      ans,
      type: taskId ? Q_TYPE.TASK : Q_TYPE.SKILL,
    };
    submitQuestionnaire(data)
      .then((res) => {
        showMessage({
          heading: t("Thank you for your participation."),
          body: <p>{t("qSubmitmsgTask")}</p>,
          type: "success",
          closeButton: false,
          callback: () => {
            user.isSuggestion
              ? navigate(
                  taskId
                    ? ROUTE_NAVIGATION_PATH.TEAM_TASK
                    : ROUTE_NAVIGATION_PATH.FUTURE_SKILL_INFO
                )
              : navigate(
                  taskId
                    ? ROUTE_NAVIGATION_PATH.TASK_LIST
                    : ROUTE_NAVIGATION_PATH.FUTURE_SKILL_INFO
                );
          },
        });
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showError(error.response?.data?.errorMessage);
      });
  }
  return (
    <QList
      subHeading={taskId ? `${t("Task")}: ${taskName}` : t("What skills do you have?")}
      progressbarHeading=""
      likertScaleQuestions={likertScaleQuestions}
      selectionQuestons={selectionScaleQuestions}
      ansOptions={ansOptions}
      onSubmitQ={submitQuestions}
      selectedAns={selectedAns}
    />
  );
}
