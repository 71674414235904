import Button from "react-bootstrap/Button";
import profileIcon from "../../../assets/images/iconprofile.png";
import badges from "../../../assets/images/Badge.svg";
import { Member } from "../../../api/models/User";
import { useState } from "react";
import { team } from "../../../api";
import Spinner from "react-bootstrap/Spinner";
import { AxiosError } from "axios";
import { useMessageModal } from "../../../hooks/useMessage";
import { useTranslation } from "react-i18next";

export default function NonActiveTeamMemberTile({ user }: { user: Member }) {
  const [loader, setLoader] = useState(false);
  const { showMessage } = useMessageModal();
  const { t } = useTranslation();

  const inviteMember = () => {
    setLoader(true);
    team
      .inviteMember(user._id)
      .then((res) => {
        setLoader(false);
        showMessage({
          heading: "Success",
          body: <p>Invitation sent successfully.</p>,
          type: "success",
        });
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        setLoader(false);
        showMessage({
          heading: "Error",
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };
  return (
    <div className="active-list-grid">
      <div className="_profile-img">
        <img src={badges} alt="Badges" className="badges" />
        <img
          src={user?.image ?  user?.image : profileIcon}
          onError={(e: any) => {
            e.target.src = profileIcon;
          }}
          alt="ProfileImg"
        />
      </div>
      <div className="name">
        {user.name} {user.surname}
      </div>
      <div className="designation">{user.position}</div>
      <Button variant="secondary" onClick={inviteMember} disabled={loader}>
        {loader ? <Spinner /> : null} {t("Send an invitation")}
      </Button>
    </div>
  );
}
