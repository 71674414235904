import React, { ChangeEvent, useState } from "react";
import {
  composeValidators,
  required,
  validEmail,
  maxlength,
} from "../../validations";
import { FormControl } from "../FormElements/FormControl";
import { VALIDATIONS, MESSAGES, FILE_SIZE, MAX_FILE_SIZE } from "../../constants";
import IconEmailBlue from "../../assets/images/iconEmailBlue.svg";
import IconUserBlue from "../../assets/images/iconUserBlue.svg";
import IconPostionBlue from "../../assets/images/iconPositionBlue.svg";
import IconRemove from "../../assets/images/iconRemoveWhite.svg";
import profileImg from "../../assets/images/icon-profile.png";
import { useTranslation } from "react-i18next";

export default function AddEditMember(
{
  updateImage,
  isEditPhoto = false,
  editForm,
  photo = '',
  onlyPhoto = false,
  isSurnameNotEditable = false
}: {
  updateImage: (file: File | null) => void;
  isEditPhoto?: boolean,
  editForm?: boolean,
  photo?: string,
  onlyPhoto?: boolean,
  isSurnameNotEditable?: boolean
}) {
  console.log(isSurnameNotEditable);
  console.log(onlyPhoto);
  const imageValue = editForm ? photo : '';
  const [errorMsg, setErrorMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [image, setImage] = useState(imageValue);
  const { t } = useTranslation();
  const onImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setImage('');
      updateImage(null);
      setErrorMsg('');
      setIsSuccess(true);

      const selectedFile = event.target.files[0].size
      const fileSizeKiloBytes = selectedFile / FILE_SIZE;
      if(fileSizeKiloBytes > MAX_FILE_SIZE){
        setErrorMsg(MESSAGES.FILE_SIZE_ERROR);
        setIsSuccess(false);
        return;
      }

      updateImage(event.target.files[0]);
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  console.log(isSurnameNotEditable);

  const removeImage = () => {
    setImage('');
    updateImage(null)
  };
  return (
    <>
      <div className={`upload-photo ${onlyPhoto ? "justify-center" :""}`}>
        <div className="cover-photo">
          <div className="coverimg">
            <div className="hover-action">
              <img
                src={IconRemove}
                alt="remove"
                onClick={removeImage}
              />
            </div>
            {image ? <img src={image} onError={(e: any) => { e.target.src = profileImg}} alt="ProfileImg" /> : null}
          </div>
        </div>
        {!isSuccess ? <div className="max-img-size-error">{ errorMsg }</div> : null }
        <div className="file-input">
          <span>{isEditPhoto ? t('Change photo (Max 5MB)'): t('add a photo (Max 5MB)')}</span>
          <input
            className="form-control"
            type="file"
            onChange={onImageChange}
          />
        </div>
      </div>
      {!onlyPhoto ?
        <>
          <FormControl
            label="Name*"
            name="name"
            type="text"
            validate={composeValidators(required, maxlength(VALIDATIONS.MAX_NAME))}
            placeholder="Name"
            icon={IconUserBlue}
          />
          {!isSurnameNotEditable ?  
              <FormControl
                label="Surname*"
                name="surname"
                type="text"
                validate={composeValidators(required, maxlength(VALIDATIONS.MAX_NAME))}
                placeholder="Surname"
                icon={IconUserBlue}
              />
              :
              null
          }
          <FormControl
            label="Email*"
            name="email"
            type="email"
            disabled = {editForm ? true: false}
            validate={composeValidators(required, validEmail)}
            placeholder="Email"
            icon={IconEmailBlue}
          />
          <FormControl
            label="Position (optinal)"
            name="position"
            type="text"
            placeholder="Job title"
            icon={IconPostionBlue}
          />
        </> 
        :
        null
      }
    </>
  );
}
