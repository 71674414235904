import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import Accordion from "react-bootstrap/Accordion";
import faqImg from "../../assets/images/faq-img.png";
import { useLoadItems } from "../../hooks/useLoadItems";
import { support } from "../../api";
import {
  NoData
} from "../../components";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Member } from "../../api/models/User";
import { useMessageModal } from "../../hooks/useMessage";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";


export default function Faq() {
  const { t } = useTranslation()
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const { loading, items, hasNextPage, error } =
    useLoadItems({
      loadItems: support.getFaq,
      page,
      search,
  });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <>
      <Row>
        <section className="main-container shrink-width" ref={rootRef}>
          <div className="heading-common">
            <h1>
              {t('Frequently asked')} <span>{t('questions')}</span>
            </h1>
            <p>{t('faqSubTitle')}</p>
          </div>
          <div className="faq-wrapper">
            <Col md={12} lg={7} xl={8} className="faq-content">
             {!items.length && !loading ? <NoData /> : null}

              {items.length ? 
                <Accordion defaultActiveKey={
                  items.slice(0, 1).map((data) => {
                    return data._id;
                  })
                }>
                    {items.map((detail) => (
                        <Accordion.Item eventKey={detail._id}>
                        <Accordion.Header>
                          <div className="heading">{detail.question}</div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                          {detail.answer}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              : null }
              {hasNextPage && (
                <div ref={sentryRef} className="spinner-wrap">
                  <div className="">
                    <Spinner />
                  </div>
                </div>
              )}
            </Col>
            <Col md={12} lg={5} xl={4} className="faq-content-info">
              <div className="contact-detail">
                <h3>{t('Got a question?')}</h3>
                <p>
                  {t('Get in touch with us!')}
                </p>
                <div className="_image">
                  <img src={faqImg} alt="faq" />
                </div>
                <Link to={ROUTE_NAVIGATION_PATH.CONTACT_US}>
                  <Button variant="primary">{t('Submit')}</Button>
                </Link>
              </div>
            </Col>
          </div>
        </section>
      </Row>
    </>
  );
}
