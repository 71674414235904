import Button from "react-bootstrap/Button";
import { FormEvent, useState } from "react";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import { AxiosError } from "axios";

import iconUpload from "../../assets/images/iconUpload.svg";
import iconDownload from "../../assets/images/download-icon.svg";
import iconFile from "../../assets/images/iconFile.svg";
import { api } from "../../api/api";

import { useMessageModal } from "../../hooks/useMessage";
import { BASE_URL } from "../../constants";
import { useTranslation } from "react-i18next";

const ACCEPT_TYPE = [
  ".csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];
const ACCEPT_FILE_SIZE = 5000000; // 5 Mb 5;

const DownLoadButtonForError = ({
  sampleFileUrl,
}: {
  sampleFileUrl: string;
}) => (
  <a className="btn btn-download2" href={BASE_URL + sampleFileUrl} download>
    <div className="icon-download">
      <img src={iconDownload} alt="Download" />
    </div>
    Download
  </a>
);
export default function UploadFile({
  onComplete,
  sampleFileUrl,
  uploadFileApiUrl,
}: {
  onComplete: VoidFunction;
  sampleFileUrl: string;
  uploadFileApiUrl: string;
}) {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [progress, setProgress] = useState<number>(0);
  const { showMessage } = useMessageModal();
  const { t } = useTranslation();

  const onFileUpload = (e: any) => {
    const file = e.target.files[0];
    setSelectedFile(undefined);
    if (!ACCEPT_TYPE.includes(file.type)) {
      showMessage({
        heading: t("FileTypeError"),
        body: <p>{t("fileTypeErrorExt")}</p>,
        type: "error",
        footer: <DownLoadButtonForError sampleFileUrl={sampleFileUrl} />,
      });
      e.target.value = "";
    } else if (file.size > ACCEPT_FILE_SIZE) {
      showMessage({
        heading: t("FileSizeError"),
        body: <p>{t("fileSizeErrorExt")}</p>,
        type: "error",
      });
      e.target.value = "";
    } else {
      setSelectedFile(e.target.files[0]);
      e.target.value = "";
    }
  };

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); //prevent the form from submitting
    let formData = new FormData();
    setProgress(0);
    formData.append("file", selectedFile as Blob);
    api
      .post(uploadFileApiUrl, formData, {
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(
            Math.round((100 * data.loaded) / (data?.total as number))
          );
        },
      })
      .then((res) => {
        onComplete();
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        setProgress(0);
        const errorMsg = error.response?.data?.errorMessage;
        showMessage({
          heading: t("The file can't be added"),
          body: <p>{`${errorMsg ? errorMsg : ""}`}</p>,
          type: "error",
          footer: <DownLoadButtonForError sampleFileUrl={sampleFileUrl} />,
        });
      });
  };
  const clearFile = () => {
    setSelectedFile(undefined);
  };
  return (
    <>
      <div className="download-files">
        <div className="desc">
          {t("You can only upload file")}
          <br />
          {t("using our Excel template")}
        </div>
        <div className="download-btn">
          <a
            type="button"
            className="btn btn-download"
            href={BASE_URL + sampleFileUrl}
            download
          >
            <div className="icon-download">
              <img src={iconDownload} alt="Download" />
            </div>{" "}
            {t("download")}
          </a>
        </div>
      </div>
      <form onSubmit={submitHandler} className="file-input-browse">
        <div className="file-drag">
          <div className="drag-content">
            <div className="_icon-drag">
              <img src={iconUpload} alt="Upload" />
            </div>
            <p>{t("drag&Drophere")}</p>
            <p>{t("or")}</p>
            <span>{t("browse")}</span>
          </div>
          <Form.Control
            type="file"
            onChange={onFileUpload}
            name="file"
            accept={ACCEPT_TYPE.join()}
          />
        </div>
        <div className="info-size">{t("OnlyxmlWithmaxSizeOf5Mb")}</div>
        <div className="progress-content-bar">
          {selectedFile ? (
            <>
              <div className="_image">
                <img src={iconFile} alt="File" />
              </div>
              <div className="bar-content">
                <p className="d-flex justify-content-between">
                  <span>{selectedFile?.name} </span>
                  {progress === 0 ? (
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={clearFile}
                    ></button>
                  ) : null}
                </p>
                {progress > 0 ? (
                  <ProgressBar now={progress} label={`${progress}%`} />
                ) : null}
              </div>
            </>
          ) : null}
        </div>
        <Button variant="primary" type="submit" disabled={!selectedFile}>
          {t("upload")}
        </Button>
      </form>
    </>
  );
}
