import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Form } from "react-final-form";
import { AxiosError } from "axios";
import { 
  composeValidators,
  required,
  validEmail,
} from "../../validations";
import { FormControl } from "../../components";
import IconEmailBlue from "../../assets/images/iconEmailBlue.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import back from "../../assets/images/iconBack.svg";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { useMessageModal } from "../../hooks/useMessage";
import { authetication } from "../../api";
import { MESSAGES } from "../../constants";
import { useTranslation } from "react-i18next";


function ForgotForm() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { showMessage } = useMessageModal();

  function handleClick() {
    navigate(ROUTE_NAVIGATION_PATH.SIGN_IN);
  }

  const onSubmit = (values: any) => {
    authetication.doForgot(values).then(res => {
      showMessage({
        heading: `${t('Password')} ${t('Forgot')}`,
        body: <p>{t('FORGOT_EMAIL_SUCCESS')}</p>,
        type: "success",
      });
    }).catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
      showMessage({
        heading: "Error",
        body: <p>{error.response?.data?.errorMessage}</p>,
        type: "error",
      });
    })
  };
  return (
    <>
      <div className="_back" onClick={handleClick}>
        <img src={back} alt="back" />
      </div>
      <h2>{t('Forgot')} <span>{t('Password')}</span></h2>
      <Card.Text>{t('forgetPwdMsg')}</Card.Text>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <FormControl
              label="Email"
              name="email"
              type="email"
              placeholder="Email"
              validate={composeValidators(required, validEmail)}
              icon={IconEmailBlue}
            />

            <Button variant="primary" type="submit">
              {t("send")}
            </Button>
            <p className="pt-4 size16">{t('forgetPwdEmailMsg')}</p>
          </form>
        )}
      />
    </>
  );
}
export default function ForgetPassword() {
  return (
    <Row>
      <Col
        className="d-flex justify-content-center align-items-center p-0 login-signup-page-card h-100vh"
      >
        <div className="login-form">
          <div className="w-100">
            <ForgotForm />
          </div>
        </div>
      </Col>
    </Row>
  );
}
