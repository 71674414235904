import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import iconDimension from "../../../assets/images/iconDimension.svg";
import { BackIcon, EditTeamMember, SpiderGraph } from "../../../components";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { myprofile } from "../../../api";
import { Member } from "../../../api/models/User";
import { useMessageModal } from "../../../hooks/useMessage";
import { MEMBER_GPAPH_COLOR } from "../../../constants";
import profileIcon from "../../../assets/images/iconprofile.png";
import { Dimensions, SpiderGraphData } from "../../../api/models/Dimensions";
import { getMemberDimensions } from "../../../api/member";
import { useTranslation } from "react-i18next";

export default function MyProfile() {
  const { showMessage } = useMessageModal();
  const { user } = useAuth();
  const [memberDetail, setDetailsToShow] = useState<Member | null>(null);
  const [updateDetails, setUpdatedDetails] = useState<Member | null>(null);
  const [futureSkills, setFutureSkills] = useState<any[]>([]);

  const [dimensions, setDimensions] = useState<Dimensions>([]);
  const [graphData, setGraphData] = useState<SpiderGraphData>({
    labels: [],
    datasets: [],
  } as SpiderGraphData);
  const [characteristics, setCharacteristics] = useState<any[]>([]);
  const { t } = useTranslation();

  const { userId } = useParams();
  const memberId = userId ? userId : user?.id;
  function formatGraphdata(dimensions: Dimensions) {
    const labels: string[] = [],
      data: number[] = [],
      answers: any[] = [];
    dimensions.forEach((dimension) => {
      labels.push(dimension.taskDimensionName);
      data.push(dimension.average);
      answers.push(dimension.answers)
    });
    setCharacteristics(answers);
    setGraphData({
      labels,
      datasets: [
        {
          data,
          ...MEMBER_GPAPH_COLOR,
        },
      ],
    });
  }
  useEffect(() => {
    if (memberId) {
      myprofile
        .getMemberProfile(memberId as string)
        .then((res) => {
          setFutureSkills(res.data.futureSkill);
          setDetailsToShow(res.data.userInfo);
        })
        .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
          showMessage({
            heading: t("Error"),
            body: <p>{err.response?.data?.errorMessage}</p>,
            type: "error",
          });
        });
      getMemberDimensions(memberId as string).then((res) => {
        setDimensions(res.data);
        formatGraphdata(res.data);
      });
    }
  }, [memberId]);

  const updateListItem = (updatedData: Member) => {
    updatedData._id = memberDetail?._id as string;
    setDetailsToShow(updatedData);
    setUpdatedDetails(null);
  };

  return (
    <>
      <Row>
        <section className="main-container shrink-width">
          <div className="heading-common">
            {userId ? <BackIcon /> : null}
            <h1>
              {userId
                ? `${memberDetail?.name} ${memberDetail?.surname}'s`
                : t("My")}
              <span> {t("Profile")}</span>
            </h1>
            {userId ? null : <p>{t("profileDesMember")}</p>}
            <div
              className="add-btn absolute"
              onClick={() => setUpdatedDetails(memberDetail)}
            >
              {t("Edit")}
            </div>
            {/* <div className="logout" onClick={handleClick}>
              <p className="header">Logout</p>
            </div> */}
          </div>
          <div className="my-profile-wrapper">
            <Col md={12} lg={4} className="left-panel">
              <div className="member-profile">
                <div className="_image">
                  <img
                    src={
                      memberDetail?.image
                        ? memberDetail?.image
                        : profileIcon
                    }
                    onError={(e: any) => {
                      e.target.src = profileIcon;
                    }}
                    alt="ProfileImg"
                  />
                </div>
                <div className="profile-info">
                  <div className="name">
                    {memberDetail?.name} {memberDetail?.surname}
                  </div>
                  <div className="designation">{memberDetail?.position}</div>
                </div>
                {/* <Button as="a" variant="edit2">
                  Edit
                </Button> */}
              </div>
              <div className="structure-info">
                <ul>
                  {dimensions?.map((skill) => (
                    <li key={skill.taskDimensionName}>
                      <div className="info-heading">
                        {skill.taskDimensionName}
                      </div>
                      <div className="percentage-info">
                        {skill.average.toFixed(1)}%
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              {userId ? null : (
                <Link to={ROUTE_NAVIGATION_PATH.MEMBER_QUESTIONNAIRE}>
                  <Button variant="secondary" type="submit">
                    <img src={iconDimension} alt="Dimension" />
                    {t("Go to skill questionnaire")}
                  </Button>
                </Link>
              )}
              <div className="skill-improve-info">
                <h3>{t("Skills to improve")}</h3>
                <ul>
                  {futureSkills?.map((skill) => (
                    <li key={skill.id}>{skill.name}</li>
                  ))}
                </ul>
              </div>
              {userId ? null : (
                <Link to={ROUTE_NAVIGATION_PATH.CHOOSE_SKILLS}>
                  <Button variant="secondary" type="submit">
                    <img src={iconDimension} alt="Dimension" />
                    {t("Go to aim questionnaire")}
                  </Button>
                </Link>
              )}
            </Col>
            <Col md={12} lg={8}>
              <SpiderGraph data={graphData} customClass="member-profile" characteristics={characteristics}/>
            </Col>
          </div>
        </section>
      </Row>
      <EditTeamMember
        detail={updateDetails}
        handleClose={() => setUpdatedDetails(null)}
        updateListItem={updateListItem}
      />
    </>
  );
}
