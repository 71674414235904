import { AxiosError } from "axios";
import { api } from "../api";

export const ENDPOINTS = {
  TASKS: "v1/tasks/",
  POST_CREATE_TASK: "add",
  GET_TASKS: "list",
  PUT_TASKS: "/update",
  REMOVE_TASKS: "/remove",
  DETAIL_TASKS: "/details",
  DONE_TASKS: "/done",
  GET_SAMPLE_FILE: 'v1/tasks/download-tasks-sample',
  UPLOAD_BULK_UPLOAD: 'v1/tasks/upload-file-tasks',
  TEAM_TASK: 'all-tasks-list',
  NOT_ACTIVE_TASK:'/not-started-tasks-list',
  ACCEPT_SUGGESTION: '/member/accept',
  ACCEPT_ALL_SUGGESTIONS: '/accept-all-tasks',
  REVIEW: '/review',
  TASK_COUNT: 'counts',
  TASK_CAPACITY: 'capacity/member',
  DIM_TASKS: "/dimensions",
  MEMBER_ACTIVE_TASKS: 'member/active-count'
};

async function createTask(data: any) {
  return api.post(ENDPOINTS.TASKS+ENDPOINTS.POST_CREATE_TASK, data).then((res) => res.data);
}

async function editTask(data: any, id: string) {
  return api
    .patch(ENDPOINTS.TASKS + id + ENDPOINTS.PUT_TASKS, data)
    .then((res) => res.data);
}

async function deleteTask(id: string) {
  return api
    .delete(ENDPOINTS.TASKS + id + ENDPOINTS.REMOVE_TASKS)
    .then((res) => res.data);
}

async function markDoneTask(id: string) {
  return api
    .patch(ENDPOINTS.TASKS + id + ENDPOINTS.DONE_TASKS)
    .then((res) => res.data);
}

async function getTasks(data: Record<string, any>) {
  return api
    .get(ENDPOINTS.TASKS + ENDPOINTS.GET_TASKS, { params: data })
    .then((res) => {
      const rData = res.data;
      return {
        data: rData.data.tasks,
        hasNextPage: rData.data.totalTasksCount > data.page * data.count,
      };
    })
    .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
      return Promise.reject(error)
    });
}

async function getTaskDetail(taskId:any) {
  return api
    .get(ENDPOINTS.TASKS + taskId + ENDPOINTS.DETAIL_TASKS)
    .then((res) => res.data);
}

async function getTaskDimensions(taskId:any) {
  return api
    .get(ENDPOINTS.TASKS + taskId + ENDPOINTS.DIM_TASKS)
    .then((res) => res.data);
}

async function teamTask(data: Record<string, any>) {
  return api
    .get(ENDPOINTS.TASKS + ENDPOINTS.TEAM_TASK, { params: data })
    .then((res) => {
      const rData = res.data;
      return {
        data: rData.data.tasks,
        hasNextPage: rData.data.totalTasksCount > data.page * data.count,
      };
    })
    .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
      return Promise.reject(error)
    });
}

async function notActiveTask(data: Record<string, any>) {
  return api
    .get(ENDPOINTS.TASKS + ENDPOINTS.NOT_ACTIVE_TASK, { params: data })
    .then((res) => {
      const rData = res.data;
      return {
        data: rData.data.tasks,
        hasNextPage: rData.data.totalTasksCount > data.page * data.count,
      };
    })
    .catch((error: AxiosError<{ errorMessage:string; status: number }>) => {
      return Promise.reject(error)
    });
}

async function acceptSuggestion(taskId:any, memberId:any) {
  return api
    .patch(ENDPOINTS.TASKS + taskId + '/member/' + memberId + '/assign')
    .then((res) => res.data);
}

async function accpetAllSuggestions() {
  return api
    .patch(ENDPOINTS.TASKS + ENDPOINTS.ACCEPT_ALL_SUGGESTIONS)
    .then((res) => res.data);
}

async function reviewTask(data: any, taskId:string) {
  return api.post(ENDPOINTS.TASKS+ taskId + ENDPOINTS.REVIEW, data)
    .then((res) => res.data);
}

async function getLeadertaskCount() {
  return api.get(ENDPOINTS.TASKS + ENDPOINTS.TASK_COUNT)
    .then((res) => res.data);
}

async function getMembertaskCapacity(data: Record<string, any>) {
  return api.get(ENDPOINTS.TASKS + ENDPOINTS.TASK_CAPACITY, { params: data })
    .then((res) => res.data);
}

async function memberTaskList(data: Record<string, any>) {
  return api
    .get(ENDPOINTS.TASKS + ENDPOINTS.GET_TASKS + '/members', { params: data })
    .then((res) => {
      const rData = res.data;
      return {
        data: rData.data.tasks,
        hasNextPage: rData.data.totalTasksCount > data.page * data.count,
      };
    })
    .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
      return Promise.reject(error)
    });
}

async function getMemberActiveTask(data: Record<string, any>) {
  return api.get(ENDPOINTS.TASKS + ENDPOINTS.MEMBER_ACTIVE_TASKS, { params: data })
    .then((res) => res.data);
}

export { 
  createTask,
  getTasks,
  editTask,
  deleteTask,
  markDoneTask,
  getTaskDetail,
  teamTask,
  notActiveTask,
  acceptSuggestion,
  accpetAllSuggestions,
  reviewTask,
  getLeadertaskCount,
  getMembertaskCapacity,
  memberTaskList,
  getTaskDimensions,
  getMemberActiveTask
};
