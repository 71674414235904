import Nav from "react-bootstrap/Nav";
import profileImg from "../../assets/images/icon-profile.png";
import profileDp from "../../assets/images/iconMember.svg";
import profileDpWhite from "../../assets/images/iconMember-white.png";
import logo from "../../assets/images/logo.svg";
import logoIcon from "../../assets/images/logo-icon.png";

import settingIcon from "../../assets/images/icons8-tune-50.png";
import downarrow from "../../assets/images/white-arrow-down.png";


import logoutPro from "../../assets/images/icons8-logout-52.png";
import settingIconWhite from "../../assets/images/icons8-tune-50-white.png";

import logoutProWhite from "../../assets/images/icons8-logout-52-white.png";
import { useAuth } from "../../hooks/useAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useEffect, useState } from "react";
import { ADMIN_MENUS, LEADER_MENUS, MEMBER_MENUS } from "../../constants/menu";
import { PROFILE_VIEW, ROLE } from "../../constants";
import { useTranslation } from "react-i18next";

function Menu({
  icon,
  title,
  url,
  profileImg,
  callback,
  activeOn
}: {
  icon: string;
  title: string;
  url: string;
  callback: (e: any) => void;
  profileImg: boolean;
  activeOn: string[]
}) {
  const { user } = useAuth();

  const location = useLocation();
  let redirectionUrl;

  if (url === ROUTE_NAVIGATION_PATH.TASK_LIST && user.isSuggestion) {
    redirectionUrl = ROUTE_NAVIGATION_PATH.TEAM_TASK;
  } else if (
    url === ROUTE_NAVIGATION_PATH.MEMBER_LIST &&
    user.teamLeaderCount === 0
  ) {
    redirectionUrl = ROUTE_NAVIGATION_PATH.TEAM_PRE_CREATION;
  } else if (url === ROUTE_NAVIGATION_PATH.TASK_LIST && user.taskCount === 0) {
    redirectionUrl = ROUTE_NAVIGATION_PATH.PRE_CREATION;
  } else if (url === ROUTE_NAVIGATION_PATH.MEMBER_DASHBOARD) {
    if(!user.isQuestionnaireFilled) {
      redirectionUrl = ROUTE_NAVIGATION_PATH.SKILL_INFO;
    } else if(!user.isFutureSkillFilled) {
      redirectionUrl = ROUTE_NAVIGATION_PATH.FUTURE_SKILL_INFO;
    } else {
      redirectionUrl = url;
    }
  } else {
    redirectionUrl = url;
  }

  return (
    <Link
      to={redirectionUrl}
      className={`nav-link ${
        activeOn.includes(location.pathname.split('/').slice(0, 3).join("/")) ? "active" : ""
      }`}
      onClick={callback}
    >
      {profileImg ? (
        <span className="icon-nav">
          <div className="_image">
            <img src={icon} alt={title} />
          </div>
        </span>
      ) : (
        <span className="icon-nav">
          <img src={icon} alt={title} />
        </span>
      )}

      <div className="_nav-hover">{title}</div>
    </Link>
  );
}
export default function SideMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [expendMenu, setExpendMenu] = useState(false);
  const { signout, user, adminSignout } = useAuth();
  const [profileOpen, setProfileOpen] = useState(false);
  const profileUrl =
    user.role === ROLE.LEADER
      ? ROUTE_NAVIGATION_PATH.LEADER_MY_PROFILE
      : ROUTE_NAVIGATION_PATH.MEMBER_MY_PROFILE;

  function handleClick(type: number) {
    if (type === PROFILE_VIEW.SIGNOUT) {
      signout();
    } else if (type === PROFILE_VIEW.PROFILE) {
      navigate(profileUrl);
    } else {
      //setting
    }
  }

  function toggleMenuExpend() {
    setExpendMenu(!expendMenu);
    !expendMenu
      ? document
          .getElementsByClassName("main-container")[0]
          ?.classList.add("shrink-width")
      : document
          .getElementsByClassName("main-container")[0]
          ?.classList.remove("shrink-width");
  }

  useEffect(() => {
    toggleMenuExpend();
  }, []);

  // function closeMenuExpand() {
  //   if (expendMenu) {
  //     setExpendMenu(false);
  //     document
  //       .getElementsByClassName("main-container")[0]
  //       .classList.remove("shrink-width");
  //   }
  // }
  const toggleProfileOpen = () => {
    setProfileOpen(!profileOpen);
  }

  return (
    <aside className="navbar">
      <div
        className={`expend-menu ${expendMenu ? "active" : ""}`}
        onClick={toggleMenuExpend}
      ></div>
      <div className={`nav-content ${expendMenu ? "_expend-width" : ""}`}>
        <Nav
          defaultActiveKey={ROUTE_NAVIGATION_PATH.LEADER_DASHBOARD}
          className=""
        >
          <div className="logo">
            <img className="logofull" src={logo} alt="Logo" />
            <img className="logoicon" src={logoIcon} alt="Logo" />
          </div>
          {(user.role === ROLE.LEADER
            ? LEADER_MENUS
            : user.role === ROLE.ADMIN
            ? ADMIN_MENUS
            : MEMBER_MENUS
          ).map((menu) => (
            <Menu
              key={menu.title}
              url={menu.url}
              title={t(menu.title)}
              icon={menu.icon}
              profileImg={false}
              callback={(e) => {}}
              activeOn={menu.activeOn}
            />
          ))}
          <div className="profile-info" >
          {profileOpen ?
            <div className={`profile-dropdown ${user.role === ROLE.ADMIN ? 'admin': ''}`}>
              <div className="arrow-up"></div>
              {user.role !== ROLE.ADMIN ? (
                <ul>
                  <li onClick={() => navigate(profileUrl)}>
                    <span className="icon-dp">
                      <img src={profileDp} alt="Profile" className="_blue" />
                      <img
                        src={profileDpWhite}
                        alt="Profile"
                        className="_hover"
                      />
                    </span>
                    <p>{t("Profile")}</p>
                  </li>
                  <li
                    onClick={() =>
                      navigate(
                        user.role === ROLE.LEADER
                          ? ROUTE_NAVIGATION_PATH.LEADER_SETTING
                          : ROUTE_NAVIGATION_PATH.MEMBER_SETTING
                      )
                    }
                  >
                    <span className="icon-dp">
                      <img src={settingIcon} alt="Setting" className="_blue" />
                      <img
                        src={settingIconWhite}
                        alt="Setting"
                        className="_hover"
                      />
                    </span>
                    <p>{t("Settings")}</p>
                  </li>
                  <li onClick={() => handleClick(PROFILE_VIEW.SIGNOUT)}>
                    <span className="icon-dp">
                      <img src={logoutPro} alt="Profile" className="_blue" />
                      <img
                        src={logoutProWhite}
                        alt="Profile"
                        className="_hover"
                      />
                    </span>
                    <p>{t("Signout")}</p>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li onClick={() => adminSignout()}>
                    <span className="icon-dp">
                      <img src={logoutPro} alt="Profile" className="_blue" />
                      <img
                        src={logoutProWhite}
                        alt="Profile"
                        className="_hover"
                      />
                    </span>
                    <p>{t("Signout")}</p>
                  </li>
                </ul>
              )}
            </div>: null }
            <div className="profilecontent" onClick={toggleProfileOpen}>
              <div className="icon-nav">
                <div className="_image">
                  <img
                    src={user?.image ? user?.image : profileImg}
                    onError={(e: any) => {
                      e.target.src = profileImg;
                    }}
                    alt="ProfileImg"
                  />
                </div>
              </div>
              <div className="_nav-hover">{user.name ? user?.name : "ADMIN"}</div>
              <div className="arrow">
                <img src={downarrow} alt="Setting" className="_blue" />
              </div>
            </div>
           
          </div>
        </Nav>
      </div>
    </aside>
  );
}