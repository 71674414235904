import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROLE } from "../constants";
import { useAuth } from "../hooks/useAuth";
import { ROUTE_NAVIGATION_PATH } from "../routes/routes";

export default function Main() {
  let auth = useAuth();
  let location = useLocation();
  var date = (auth.user && auth.user.invoiceId != null) ? new Date(auth.user.invoiceId.planEndDate) : null;

  if (location.pathname === "/") {
    if (!auth.user) {
      return (
        <Navigate
          to={ROUTE_NAVIGATION_PATH.SIGN_UP}
          state={{ from: location }}
          replace
        />
      );
    }
    if (auth.user && auth.user.role === ROLE.MEMBER) {
      if (!auth.user.isQuestionnaireFilled) {
        return <Navigate to={ROUTE_NAVIGATION_PATH.SKILL_INFO} replace />;
      } else if (!auth.user.isFutureSkillFilled) {
        return (
          <Navigate to={ROUTE_NAVIGATION_PATH.FUTURE_SKILL_INFO} replace />
        );
      } else {
        <Navigate to={ROUTE_NAVIGATION_PATH.MEMBER_DASHBOARD} replace />;
      }
    }

    if(auth.user && auth.user.role == ROLE.LEADER) {
      if(auth.user.invoiceId == null || (date && date < new Date())) {
        <Navigate to={ROUTE_NAVIGATION_PATH.SUBSCRIPTION} replace />
      } else {
        return auth.user?.teamLeaderCount > 0 ? (
          <Navigate to={ROUTE_NAVIGATION_PATH.LEADER_DASHBOARD} replace />
        ) : (
          <Navigate to={ROUTE_NAVIGATION_PATH.STEPPER} replace />
        );
      }

    }

    if(auth.user && auth.user.role == ROLE.ADMIN) {
      <Navigate to={ROUTE_NAVIGATION_PATH.MANAGE_LEADER} replace />
    }
  }
  return <Outlet />;
}
