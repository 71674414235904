import { AxiosError } from "axios";
import { api } from "../api";

export const ENDPOINTS = {
    GET_FAQ: "v1/faqs/list",
    POST_CONTACT_US: 'v1/contact-us/query'
};

async function getFaq() {
    return api.get(ENDPOINTS.GET_FAQ).then((res) => res.data);
}

async function contactUs(data: any) {
    return api.post(ENDPOINTS.POST_CONTACT_US, data).then((res) => res.data);
}

export { getFaq, contactUs };
