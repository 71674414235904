import { Info } from "../../../components";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import memberSkillImg from "../../../assets/images/createMember.svg";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useMessageModal } from "../../../hooks/useMessage";

export default function SkillInfo() {
  const { t } = useTranslation();
  const { showMessage } = useMessageModal();

  useEffect(()=> {
    if (window.screen.width < 768) {
      showMessage({
        heading: "Error",
        body:  <p>{t("Mobile Screen Info")}</p>,
        type: "error",
        callback: () => {
          localStorage.clear();
          window.location.href = '/';
        },
      });
    }
  })
  return (
    <Info
      title={t("What")}
      spantitle="skills"
      nextTitle="do you have?"
      subTitle="questionnairePersonality"
      image={memberSkillImg}
      description1="skillInfoDescription1"
      description2="skillInfoDescription2"
      buttonText="LetsGo"
      nextUrl={ROUTE_NAVIGATION_PATH.MEMBER_QUESTIONNAIRE}
    />
  );
}
