import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "react-bootstrap/Button";
import { AxiosError } from "axios";

import { team } from "../../api";

import { useMessageModal } from "../../hooks/useMessage";

import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";

import AddEditMember from "../FormElements/AddEditMember";
import { useTranslation } from "react-i18next";

export default function AddTeamMember() {

  const [file, setFile] = useState<File | null>();
  const { showMessage } = useMessageModal();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const updateImage =(file: File | null) => {
    file ? setFile(file) : setFile(null);
  }

  const onSubmit = (values: any) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("surname", values.surname);
    if (file) {
      formData.append("image", file);
    }
    formData.append("email", values.email);
    formData.append("position", values.position ? values.position: '');
    team
      .createTeam(formData)
      .then((res) => {
        navigate(ROUTE_NAVIGATION_PATH.MEMBER_LIST);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: "Error",
          body: <p>{error.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <AddEditMember updateImage={updateImage} />
          <Button variant="primary" type="submit">
            Create a member
          </Button>
        </form>
      )}
    />
  );
}