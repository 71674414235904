import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import notification from "../../../assets/images/notification.svg";
import profileIcon from "../../../assets/images/iconprofile.png";
import badges from "../../../assets/images/Badge.svg";
import { useAuth } from "../../../hooks/useAuth";
import {
  NList,
  MemeberSkillMetric,
  TaskCapInfo,
  BackIcon,
} from "../../../components";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { Link, useParams } from "react-router-dom";
import {
  TASK_STATUS,
  TASK_TYPE,
  TASK_TYPE_LIST,
  TASK_TYPE_LIST_CAP,
} from "../../../constants";
import { AxiosError } from "axios";
import { myprofile, task } from "../../../api";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { Member } from "../../../api/models/User";
import { useMessageModal } from "../../../hooks/useMessage";

export function MyActiveTask({ userId, isLeader }: { userId?: string, isLeader:boolean }) {
  const [tasks, setTask] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const data = {
      userId,
    };
    task
      .getMemberActiveTask(data)
      .then((res: { data: any }) => {
        setLoading(false);
        setTask(res.data);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="team-allocation-wrapper">
      <div className="heading">
        <h3>
          {t("Active")} <span>{t("Tasks")}</span>
        </h3>
        {tasks.length !== 0 && !isLeader && !loading ? (
          <Link to={ROUTE_NAVIGATION_PATH.MEMBER_TASK_LIST}>
            <Button variant="seeAll">{t("seeall")}</Button>
          </Link>
        ) : null}
      </div>
      {loading ? (
        <div className="spinner-wrap">
          <div className="">
            <Spinner />
          </div>
        </div>
      ) : (
        <>
          {tasks.length === 0 && !loading ? (
            <div className="dashboard-no-data">
              <p>{t("NoResultFound")}</p>
            </div>
          ) : (
            tasks.map((data: any) => (
              <div className="taskrepeater" key={data._id}>
                <div className="sizegrid-content">
                  <div className="count-with-progress">
                    <div className="barcontainer">
                      <div
                        className="bar"
                        style={{ height: "70%", background: "#4d60a4" }}
                      ></div>
                    </div>
                    <span>{data.count}</span>
                  </div>
                  <p>
                    {TASK_TYPE_LIST[data.taskScope - 1]}) {t(TASK_TYPE_LIST_CAP[data.taskScope - 1])}
                  </p>
                </div>
                <div className="allocation-content-wrapper scrollbar">
                  {data.tasks.map((item: any) => (
                    <Link className="allocation-list-grid" to={`${ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE}/${item._id}/${TASK_STATUS.INPROGRESS}`} key={item._id}>
                      <div className="member-profile">
                        <div className="name">{item.name}</div>
                        <div className="tag-info">
                          {TASK_TYPE_LIST[item.scope - 1]}
                        </div>
                      </div>
                      <div className="assign-status">
                        <Link
                          className="status"
                          to={`${ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE}/${item._id}/${TASK_STATUS.INPROGRESS}`}
                        >
                          <div className="assigned-tag">
                            <img
                              src={
                                item?.assignedTo[0]?.memberId?.image
                                  ? item?.assignedTo[0]?.memberId?.image
                                  : profileIcon
                              }
                              onError={(e: any) => {
                                e.target.src = profileIcon;
                              }}
                              alt="ProfileImg"
                            />
                            {t("Assigned")}
                          </div>
                        </Link>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ))
          )}
        </>
      )}
    </div>
  );
}

export default function MemberDashboard() {
  const { user } = useAuth();
  const { userId } = useParams();
  const [expendMenu, setExpendMenu] = useState(false);
  const [taskCount, settaskCount] = useState<any>();
  const [memberInfo, setMemberInfo] = useState<Member>();
  const { t } = useTranslation();
  const { showMessage } = useMessageModal();
  const memberId = userId ? userId : user.id;

  const capitalizeFirst = (str: any) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  };

  function toggleMenuExpend() {
    setExpendMenu(!expendMenu);
  }
  const getMemberProfile = () => {
    myprofile
      .getMemberProfile(memberId as string)
      .then((res) => {
        setMemberInfo(res.data.userInfo);
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("Error"),
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };

  useEffect(() => {
    if (window.screen.width < 768) {
      showMessage({
        heading: "Error",
        body: <p>{t("Mobile Screen Info")}</p>,
        type: "error",
        callback: () => {
          localStorage.clear();
          window.location.href = '/';
        },
      });
    }
    getMemberProfile();
    const data = {
      userId: memberId,
    };
    task
      .getMembertaskCapacity(data)
      .then((res: { data: any }) => {
        settaskCount(res.data);
      })
      .catch(
        (error: AxiosError<{ errorMessage: string; status: number }>) => { }
      );
  }, []);

  return (
    <>
      <Row>
        {userId ? null : (
          <section className="notification-bar shrink-width">
            <div>
              {" "}
              {t("Welcome")}, {capitalizeFirst(user?.name)}!! 👋
            </div>
            <div className="notification" onClick={toggleMenuExpend}>
              <img src={notification} alt="notification" />
              {user?.totalNotificationsCount &&
                user?.totalNotificationsCount > 0 ? (
                <span className="m-alert"></span>
              ) : null}
            </div>

            {expendMenu ? <NList /> : null}
          </section>
        )}
        <section className="main-container transparent-bg shrink-width">
          <div className={`${userId ? "heading-common _heightadd" : "heading-common"}`}>
            {userId ? <BackIcon /> : null}
          </div>
          <div className="team-task-information-wrapper">
            <Col md={12} xl={4} className="information-left-panel">
              <div className="team-skills-wrapper member">
                <div className="profile-info">
                  <div className="d-flex">
                    <div className="_profile-img">
                      <img src={badges} alt="Badges" className="badges" />
                      <img
                        src={
                          memberInfo?.image
                            ? memberInfo?.image
                            : profileIcon
                        }
                        onError={(e: any) => {
                          e.target.src = profileIcon;
                        }}
                        alt="ProfileImg"
                      />
                    </div>
                    <div className="info-user">
                      <div className="name">
                        {memberInfo?.name} {memberInfo?.surname}
                      </div>
                      <div className="designation">{memberInfo?.position}</div>
                    </div>
                  </div>
                  <Link
                    to={
                      userId
                        ? `${ROUTE_NAVIGATION_PATH.MEMBER_PROFILE}/${userId}`
                        : ROUTE_NAVIGATION_PATH.MEMBER_MY_PROFILE
                    }
                  >
                    <Button variant="edit">{t("See profile")}</Button>
                  </Link>
                </div>
                <MemeberSkillMetric userId={memberId} />
              </div>
            </Col>
            <Col md={12} xl={8} className="information-right-panel">
              <MyActiveTask userId={memberId} isLeader={!!userId}/>
              <div className="task-info-content">
                <div className="sub-task-info-outer">
                  <h2>
                    {t("Remaining")} {t("Capacity")}{" "}
                    <TaskCapInfo heading="taskCapInfo" />
                  </h2>
                  <div className="sub-task-info">
                    <div className="grid">
                      <div className="count-with-progress">
                        <div className="barcontainer">
                          <div
                            className="bar"
                            style={{
                              height:
                                taskCount?.taskCapacity[TASK_TYPE.S] * 10 + "%",
                              background: "#B1B7C0",
                            }}
                          ></div>
                        </div>
                        <span>{taskCount?.taskCapacity[TASK_TYPE.S]}</span>
                      </div>
                      <p>(S) {t("Small")}</p>
                    </div>
                    <div className="grid">
                      <div className="count-with-progress">
                        <div className="barcontainer">
                          <div
                            className="bar"
                            style={{
                              height:
                                taskCount?.taskCapacity[TASK_TYPE.M] * 10 + "%",
                              background: "#B1B7C0",
                            }}
                          ></div>
                        </div>
                        <span>{taskCount?.taskCapacity[TASK_TYPE.M]}</span>
                      </div>
                      <p>(M) {t("Medium")}</p>
                    </div>
                    <div className="grid">
                      <div className="count-with-progress">
                        <div className="barcontainer">
                          <div
                            className="bar"
                            style={{
                              height:
                                taskCount?.taskCapacity[TASK_TYPE.L] * 10 + "%",
                              background: "#B1B7C0",
                            }}
                          ></div>
                        </div>
                        <span>{taskCount?.taskCapacity[TASK_TYPE.L]}</span>
                      </div>
                      <p>(L) {t("Large")}</p>
                    </div>
                  </div>
                </div>
                <div className="total-task">
                  <h2>{t("Completed")}</h2>
                  <div className="total-counttext">
                    {taskCount?.totalDoneTasks}
                    <p>{t("Tasks")}</p>
                  </div>
                </div>
              </div>

            </Col>
          </div>
        </section>
      </Row>
    </>
  );
}
