import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import SpiderGraph from "../../../SpiderGraph";
import { useEffect, useState } from "react";
import {
  SpiderGraphData,
  SpiderGraphDataSet,
} from "../../../../api/models/Dimensions";
import {
  MEMBER_GPAPH_COLOR,
  MEMBERS_COLORS
} from "../../../../constants";
import NoData from "../../../NoData";
import { useTranslation } from "react-i18next";

export default function SuggestedMemberDimGraph({
  data,
  isDescription = true,
  isAssigned = false,
  status,
}: {
  data: any;
  isDescription?: boolean;
  isAssigned?: boolean;
  status?: string;
}) {
  const [graphData, setGraphData] = useState<SpiderGraphData>();
  const [taskData, setTaskdata] = useState<SpiderGraphDataSet>();
  const [membersData, setMembersData] = useState<SpiderGraphDataSet[]>([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const { t } = useTranslation();
  const [characteristics, setCharacteristics] = useState<any[]>([]);

  function formatSkillData(member: any, color: any) {
    const dataValues: number[] = [];
    const skillSets: any[] = [];
    member?.skilldimension.forEach((dimension: any) => {
      dataValues.push(dimension.personalityValue);
      if (isAssigned) {
        skillSets.push(dimension.answers);
      }
    });
    setCharacteristics(skillSets);
    return {
      label: isAssigned
        ? t(`Personality questionnaire results`)
        : `${member.name} ${member.surname}`,
      data: dataValues,
      backgroundColor: color.backgroundColor,
      borderColor: color.borderColor,
      borderWidth: 1,
      id: member?._id,
    };
  }
  function formatData() {
    const labels: string[] = [];
    const taskDataValues: number[] = [];
    const membersDataSets: SpiderGraphDataSet[] = [];
    data.taskdimension.forEach((d: any) => {
      labels.push(d.taskDimensionName);
      taskDataValues.push(d.personalityValue);
    });
    if (isAssigned) {
      let suggestionData = data.suggestions;
      const member = status === "4" ? data.pendingSuggestions[0] : suggestionData.find(
        (s: any) => s.teamMemberId._id === data.assignedTo[0]?.memberId._id
      );
      membersDataSets.push(
        formatSkillData(member?.teamMemberId, MEMBER_GPAPH_COLOR)
      );
    } else {
      data.suggestions.forEach((suggestion: any, index: number) => {
        const member = suggestion.teamMemberId;
        membersDataSets.push(formatSkillData(member, MEMBERS_COLORS[index]));
      });
    }
    const taskDataSet = {
      label: isAssigned ? t("Task questionnaire") : t("showOnlyTask"),
      data: taskDataValues,
      backgroundColor: "rgba(162, 210, 237, 0.3)",
      borderColor: "rgba(162, 210, 237, 1)",
      borderWidth: 1,
      id: "taskID",
    };
    setTaskdata(taskDataSet);
    setMembersData(membersDataSets);
    setGraphData({
      labels,
      datasets: [{ ...taskDataSet }, ...membersDataSets],
    });
  }

  function filterData(name: string) {
    if (name !== activeFilter) {
      setActiveFilter(name);
      setGraphData((prev: any) => {
        if (name === "all") {
          prev.datasets = [
            { ...(taskData as SpiderGraphDataSet) },
            ...membersData,
          ];
        } else if (name === "task") {
          prev.datasets = [{ ...(taskData as SpiderGraphDataSet) }];
        } else {
          const mData = membersData.find((m) => m.id === name);
          prev.datasets = [{ ...(mData as SpiderGraphDataSet) }];
        }
        return prev as SpiderGraphData;
      });
    }
  }
  useEffect(() => {
    data?._id && formatData();
  }, [data]);
  return (
    <Col md={12} lg={8} className="contact-content-wrapper">
      {isDescription && data?.description ? (
        <div className="contact-description">
          <h3>{t("Description")}</h3>
          <p>{data?.description}</p>
        </div>
      ) : null}
      {graphData?.labels.length ? (
        <>
          <SpiderGraph data={graphData} customClass={isAssigned ? "assigned" : "suggestion"} characteristics={isAssigned ? characteristics : undefined} />
          <div className={`fitler-wrapper ${isAssigned ? 'bottom-130' : ''}`}>
            <h3>Filters</h3>
            <div className="d-flex filter-btn">
            <Button
              variant={`filter-btn ${activeFilter === "all" ? "active" : ""}`}
              onClick={() => filterData("all")}
            >
              {t("showAll")}
            </Button>
            <Button
              variant={`filter-btn ${activeFilter === "task" ? "active" : ""}`}
              onClick={() => filterData("task")}
            >
              <span className="icon-color clr-task"></span>{" "}
              {isAssigned ? t("Task questionnaire") : t("showOnlyTask")}
            </Button>
            {membersData.map((member: SpiderGraphDataSet) => (
              <Button
                variant={`filter-btn ${activeFilter === member.id ? "active" : ""
                  }`}
                onClick={() => filterData(member.id as string)}
                key={member.id}
              >
                <span
                  className="icon-color"
                  style={{
                    background: member.backgroundColor,
                    borderColor: member.borderColor,
                  }}
                ></span>{" "}
                {member.label}
              </Button>
            ))}
            </div>
          </div>
        </>
      ) : (
        <NoData />
      )}
    </Col>
  );
}
