import { Field, FieldProps } from "react-final-form";

interface FormControlProps extends FieldProps<any, any, any, any> {
  label?: string;
  icon?: string;
}

export function RadioControl({
  label,
  icon,
  disabled,
  ...rest
}: FormControlProps) {
  return (
    <Field
      {...rest}
      type="radio"
      render={({ input, meta, children }) => (
        <>
          <label>
            <input type="radio" {...input} />
            <span>
              <p>{children}</p>
            </span>
          </label>

          {meta.touched && meta.error && (
            <span className="error">{meta.error}</span>
          )}
        </>
      )}
    >
      {label}
    </Field>
  );
}
