import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MessageModalType } from "../../types/global.type";
import iconInfo from "../../assets/images/iconInfo.svg";
import iconSuccess from "../../assets/images/iconSuccess.svg";
import iconError from "../../assets/images/iconError.svg";
import iconRemove from "../../assets/images/iconRemoveRed.svg";
import { useTranslation } from "react-i18next";

const ICON = {
  success: iconSuccess,
  error: iconError,
  info: iconInfo,
  warning: iconRemove,
};

export default function ConfirmationModal({
  heading,
  body,
  show,
  handleClose,
  type = "success",
  buttonMain = "Yes",
  buttonSecondary = "No",
  hideIcon = false,
  callback,
}: MessageModalType) {
  const { t } = useTranslation();
  const onOk = (isOk: boolean) => {
    callback && callback(isOk);
    !isOk && handleClose();
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      // backdrop="static"
      keyboard={false}
      dialogClassName={"model-common"}
    >
      <Modal.Header>
        { !hideIcon ?
          <div className="_icon-img">
            <img src={ICON[type]} alt="info" />
          </div>
          :
          null
        }
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onOk(true)} type="submit">
          {t(buttonMain)}
        </Button>
        <Button variant="link" onClick={() => onOk(false)}>
          {t(buttonSecondary)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
