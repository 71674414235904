export default function IconEdit() {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5466 1.9515C20.2748 0.68023 18.1511 0.68023 16.8798 1.9515L15.3224 3.50893L2.28007 16.5518L0.920205 21.4773L0.501953 23.0017L2.02637 22.5779L6.94634 21.2241L19.9892 8.18125L21.5466 6.62381C22.8173 5.35255 22.8173 3.22827 21.5466 1.9515Z"
        fill="#4D60A4"
      />
      <path
        d="M2.02582 22.5792L0.501953 23.0029L0.919655 21.4785L2.02582 22.5792Z"
        fill="#4D60A4"
      />
      <path
        d="M21.5465 1.9515C22.8178 3.22827 22.8178 5.35255 21.5465 6.62381L19.9891 8.18125L15.3223 3.50893L16.8797 1.9515C18.151 0.68023 20.2747 0.68023 21.5465 1.9515Z"
        fill="#4D60A4"
      />
      <path
        d="M2.27979 16.5508L6.9466 21.2231L2.02609 22.5769L0.919922 21.4762L2.27979 16.5508Z"
        fill="#4D60A4"
      />
    </svg>
  );
}
