import {
  TASK_TYPE_LIST,
  NOT_STARTED_STATUS,
} from "../../../constants";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { Link } from "react-router-dom";
import { IconDelete, IconEdit } from "../..";
import profileIcon from "../../../assets/images/iconprofile.png";
import { useTranslation } from "react-i18next";

export default function NonActiveTaskTile({
  task,
  editTask,
  deleteTask,
  editMyTask,
}: {
  task: any;
  editTask?: VoidFunction;
  deleteTask?: VoidFunction;
  editMyTask?: VoidFunction;
}) {
  const { t } = useTranslation();
  const  truncateText = (text: String) => {
    return text && text?.length > 35 ? text.slice(0, 35) + '...' : text;
  } 
  return (
    <div className={`${
      editTask
        ? "list-grid tasks-grid"
        : "task-list-grid tasks-grid not-started-task"
    }`}>
    
      <div className="member-profile">
        <div className="name">{truncateText(task.name)}</div>
        <div className="tag-info">{TASK_TYPE_LIST[task.scope - 1]}</div>
      </div>

      <div className="assign-status">
        {task.questionnaireStatus ? (
          <div className="assigned-tag">
            <div className="_imgnew">
              <img
                src={
                  task?.pendingSuggestions[0]?.teamMemberId?.image
                    ? task?.pendingSuggestions[0]?.teamMemberId?.image
                    : profileIcon
                }
                onError={(e: any) => {
                  e.target.src = profileIcon;
                }}
                alt="ProfileImg"
              />
            </div>
            {t("Assigned")} {t("pending")}
          </div>
        ) : (
          <div className="not-assigned-tag">{t("Not Assigned")}</div>
        )}
      </div>

      <div className="status-with-action">
        {task.questionnaireStatus ? (
          <Link
            className="status"
            to={`${ROUTE_NAVIGATION_PATH.UPDATE_CONTACT_PAGE}/${task._id}/${NOT_STARTED_STATUS.ASSIGNEDPENDING}`}
          >
            <div>{t("Re-assign")}</div>
          </Link>
        ) : (
          <Link
            className="status"
            to={`${ROUTE_NAVIGATION_PATH.TASK_QUESTIONNAIRE}/${task?._id}/${task?.name}`}
          >
            <div>{t("Plan This Task")}</div>
          </Link>
        )}

        
          <div className="action-btn">
            {editTask ? 
              <div className="edit" onClick={editTask}>
              <IconEdit />
            </div>
            :
            <div className="edit" onClick={editMyTask}>
              <IconEdit />
            </div>
            }
            
            <div className="delete" onClick={deleteTask}>
              <IconDelete />
            </div>
          </div>
        
      </div>
    
    </div>
  );
}
