import { Member } from "../../../api/models/User";
import profileImg from "../../../assets/images/icon-profile.png";
import IconEdit from "../../Icon/IconEdit";
import IconDelete from "../../Icon/IconDelete";
import { USER_STATUS, USER_STATUS_LIST } from "../../../constants";
import Form from "react-bootstrap/Form";
import badges from "../../../assets/images/Badge.svg";
import { useState } from "react";
import { team } from "../../../api";
import { AxiosError } from "axios";
import { useMessageModal } from "../../../hooks/useMessage";
import { useTranslation } from "react-i18next";

export default function ManageTeamTile({
  user,
  inviteMember,
  editMember,
  deleteMember,
  updateListItem,
}: {
  user: Member;
  inviteMember: VoidFunction;
  editMember: VoidFunction;
  deleteMember: VoidFunction;
  updateListItem: (user: Member) => void;
}) {
  const { t } = useTranslation();
  const { showMessage } = useMessageModal();
  const switchValue =
    user.status === USER_STATUS.ACTIVE || user.status === USER_STATUS.INVITEE
      ? true
      : false;
  const [checked, setChecked] = useState(switchValue);

  const handleChange = (event: any) => {
    let status =
      event.target.checked === true ? USER_STATUS.ACTIVE : USER_STATUS.SUSPENDED;
    setChecked(event.target.checked);
    team
      .updateUserStatus(user._id, status)
      .then((res) => {
        updateListItem({ ...user, status });
        showMessage({
          heading: "Status",
          body: <p>{res.message}</p>,
          type: "success",
        });
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        setChecked(!event.target.checked);
        showMessage({
          heading: "Error",
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };

  const sendReminder = () => {
    team
      .sendReminderEmail(user._id)
      .then((res) => {
        showMessage({
          heading: t("SENT QUESTIONNAIRE REMINDER"),
          body: <p>{res.message}</p>,
          type: "success",
        });
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("Error"),
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };

  return (
    <tbody>
      <tr>
        <td>
          <div className="profile-info">
            <div className="_profile-img">
              <img
                src={user?.image ? user?.image : profileImg}
                onError={(e: any) => {
                  e.target.src = profileImg;
                }}
                alt="ProfileImg"
              />
              <img src={badges} alt="profile" className="badges" />
            </div>
            {user.name}
          </div>
        </td>
        <td>{user.surname}</td>
        <td>{user.email}</td>
        {user.isQuestionnaireFilled ? (
          <td className="filled"> {t('Completed')}</td>
        ) : (
          <td className="reminder" onClick={sendReminder}>
            {" "}
            {t("Send a reminder")}{" "}
          </td>
        )}
        <td>
          {t(USER_STATUS_LIST[user.status])}
        </td>
        <td>
          <Form>
            <Form.Check
              type="switch"
              checked={checked}
              onChange={handleChange}
              id="custom-switch"
              disabled={user.status === USER_STATUS.INVITEE}
            />
          </Form>
        </td>
        <td className="action-btn">
          {
            user.status === USER_STATUS.INVITEE ? 
            <span className="btn-invition" onClick={inviteMember}>
              {t("Send an invitation")}
            </span> : 
            <span className="btn-invition-deactive">
              {t("Send an invitation")}
            </span>
          }
          <span className="edit" onClick={editMember}>
            <IconEdit />
          </span>
          <span className="delete" onClick={deleteMember}>
            <IconDelete />
          </span>
        </td>
      </tr>
    </tbody>
  );
}
