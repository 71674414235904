import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Navigate } from "react-router-dom";
import { ROLE } from "../constants";
import { useAuth } from "../hooks/useAuth";
import { ROUTE_NAVIGATION_PATH } from "../routes/routes";
import logo from "../assets/images/logo.svg";


export default function AuthLayout({
  leftContent,
  rightContent,
}: {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}) {
  const auth = useAuth();
  var date = (auth.user && auth.user.invoiceId != null) ? new Date(auth.user.invoiceId.planEndDate) : null;
  if (auth.user) {
    return auth.user.role === ROLE.MEMBER ? 
    (
      !auth.user.isQuestionnaireFilled ? (
        <Navigate to={ROUTE_NAVIGATION_PATH.SKILL_INFO} replace />
      ) 
      : !auth.user.isFutureSkillFilled ? (
        <Navigate to={ROUTE_NAVIGATION_PATH.FUTURE_SKILL_INFO} replace />
      ) 
      : (
        <Navigate to={ROUTE_NAVIGATION_PATH.MEMBER_DASHBOARD} replace />
      )
    ) : 
    auth.user && auth.user.role == ROLE.LEADER ? 
    (
      auth.user.invoiceId == null || (date && date < new Date()) ? (
        <Navigate to={ROUTE_NAVIGATION_PATH.SUBSCRIPTION} replace />
      ) 
      : auth.user.teamLeaderCount > 0 ? (
        <Navigate to={ROUTE_NAVIGATION_PATH.LEADER_DASHBOARD} replace />
      ) 
      : (
        <Navigate to={ROUTE_NAVIGATION_PATH.STEPPER} replace />
      )
    ) :
    <Navigate to={ROUTE_NAVIGATION_PATH.MANAGE_LEADER} replace />;
  }
  return (
    <Row>
      <Col sm={4} className="p-0 left-column">
        <div className="bg-secondary vh-100">
          <div className="logo">
            <img src={logo} alt="Profile" />
          </div>
          <div className="welcome-container">
            <div className="welcome-text">{leftContent}</div>
          </div>
        </div>
      </Col>
      <Col
        sm={8}
        className="d-flex justify-content-center align-items-center p-0 login-signup-page-card"
      >
        <div className="login-form">
          <div className="w-100">{rightContent}</div>
        </div>
      </Col>
    </Row>
  );
}
